import { Action } from "redux";
import { Dispatch } from "@reduxjs/toolkit";
import quoteDataaccess from "src/dataAccess/quotes/quoteDataAccess";

export enum quoteActionType {

  LOAD_QUOTE = "[Quote] Loading Quote",
  GET_QUOTE_LIST = "[Quote] Get Quote list",
  GET_QUOTE_LIST_SUCCESS = "[Quote] Get Quote list success",
  GET_SALESORDER_LIST_SUCCESS = "[SalesOrder] Get Sales Order list success",
  GET_SALESORDER_BASICINFO = "[SalesOrder] Get Sales Order Basic Info",
  GET_QUOTE_BASICINFO = "[Quote] Get Quote Basic Info",
  GET_SALESORDER_PRODUCTLISTS = "[SalesOrder] Get Sales Order ProductLists",
  UPDATE_SALESORDER_TOTAL_AMOUNT = "[SalesOrder] Update Sales Order Total Amount",
  GET_SALESORDER_PAYMENTS = "[SalesOrder] Get Sales Order Payments",
  GET_COMPANY_Name = "GET_COMPANY_Name",
  UPDATE_COMPANY_ID = "UPDATE_COMPANY_ID",
  UPDATE_SALESORDER_ACTIVEID = "[SalesOrder] Update Sales Order Active Id",
  UPDATE_SALESORDER_PRODUCTQTY = "[SalesOrder] Update Sales Order ProductQty",
  UPDATE_SALESORDER_PRODUCTTOTAL = "[SalesOrder] Update Sales Order ProductTotal",
  UPDATE_SALESORDER_PRODUCTTOTALWEIGHT = "[SalesOrder] Update Sales Order Product Total Weight",
  UPDATE_SALESORDER_TOTALTAXRATE = "[SalesOrder] Update Sales Order Total Tax Rate",
  UPDATE_SMARTT_CARRIER = "[SalesOrder] Update Smartt Carrier",
  UPDATE_SMARTT_CARRIER_SERVICE = "[SalesOrder] Update Smartt Carrier",
  UPDATE_SMARTT_CARRIER_OPTIONS = "[SalesOrder] Update Smartt Carrier options",
  UPDATE_ALL_CARRIERS = "[SalesOrder] Update All Carriers",
  RESET_STATE = "[SalesOrder] Reset State",
  RESET_SALESORDER_PRODUCTLISTS = "[SalesOrder] Reset Sales order product lists",
  UPDATE_ORDER_CONFIRMATION_EMAIL_STATUS = "[SalesOrder] Order confirmation email sent status",
  UPDATE_ORDER_CONFIRMATION_EMAIL_DATE = "[SalesOrder] Order confirmation email sent date",
  UPDATE_ORDER_SENT_TO_3PL_STATUS = "[SalesOrder] Order sent to 3pl status",
  UPDATE_ORDER_SENT_TO_3PL_DATE = "[SalesOrder] Order sent to 3pl date",
  Update_triggerSubmitCount = "[SalesOrder] Update_triggerSubmitCount",
}

interface QuoteAction extends Action {
  payload: any;
}

export const actions = {
  getQuoteList:
    (pageNo = 1, pageSize = 25, sortData = "", filters = []) =>
      (dispatch: Dispatch, getState) => {
        dispatch({ type: quoteActionType.LOAD_QUOTE, payload: true });
        quoteDataaccess
          .quoteList({ pageNo, pageSize, sortData, filters })
          .then((res) => {
            dispatch({
              type: quoteActionType.GET_QUOTE_LIST_SUCCESS,
              payload: res,
            });
            dispatch({ type: quoteActionType.LOAD_QUOTE, payload: false });
          });
      },

  getSalesOrderList:
    (pageNo = 1, pageSize = 25, sortData = "", filters = []) =>
      (dispatch: Dispatch, getState) => {
        dispatch({ type: quoteActionType.LOAD_QUOTE, payload: true });
        quoteDataaccess
          .salesOrderList({ pageNo, pageSize, sortData, filters })
          .then((res) => {
            console.log(res?.data);
            dispatch({
              type: quoteActionType.GET_SALESORDER_LIST_SUCCESS,
              payload: res,
            });
            dispatch({ type: quoteActionType.LOAD_QUOTE, payload: false });
          });
      },

  getSalesOrderBasicInfo:
    (salesOrderId, companyId) => (dispatch: Dispatch, getState) => {
      dispatch({ type: quoteActionType.LOAD_QUOTE, payload: true });
      quoteDataaccess.getSalesOrderInfo(salesOrderId, companyId).then((res) => {
        dispatch({
          type: quoteActionType.GET_SALESORDER_BASICINFO,
          payload: res?.data
        });
        dispatch({ type: quoteActionType.LOAD_QUOTE, payload: false });
      });
    },

  updateCompanyNameState: (res) => (dispatch: Dispatch, getState) => {
    dispatch({
      type: quoteActionType.GET_COMPANY_Name,
      payload: res,
    });
  },
  updateCompanyId: (res) => (dispatch: Dispatch, getState) => {
    dispatch({
      type: quoteActionType.UPDATE_COMPANY_ID,
      payload: res,
    });
  }
};

export type ActionType = QuoteAction;
