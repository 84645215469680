import { Action } from "redux";
import { Dispatch } from "@reduxjs/toolkit";
import quoteDataaccess from "src/dataAccess/quotes/quoteDataAccess";
import purchaseOrderDataAccess from "src/dataAccess/purchaseOrders/purchaseOrderDataAccess";

export enum purchaseOrderActionType {

    LOAD_PURCHASEORDER = "[PurchaseOrder] Loading Purchase Order",

    GET_PURCHASEORDER_LIST_SUCCESS = "[PurchaseOrder] Get Purchase Order list success",
    GET_PURCHASEORDER_BASICINFO = "[PurchaseOrder] Get Purchase Order Basic Info",
    GET_PURCHASEORDER_PRODUCTLISTS = "[PurchaseOrder] Get Purchase Order ProductLists",
    UPDATE_PURCHASEORDER_TOTAL_AMOUNT = "[PurchaseOrder] Update Purchase Order Total Amount",

    GET_COMPANY_Name = "GET_COMPANY_Name",
    UPDATE_COMPANY_ID = "UPDATE_COMPANY_ID",
    UPDATE_PURCHASEORDER_ACTIVEID = "[PurchaseOrder] Update Purchase Order Active Id",
    UPDATE_PURCHASEORDER_PRODUCTQTY = "[PurchaseOrder] Update Purchase Order ProductQty",
    UPDATE_PURCHASEORDER_PRODUCTTOTAL = "[PurchaseOrder] Update Purchase Order ProductTotal",
    UPDATE_PURCHASEORDER_PRODUCTTOTALWEIGHT = "[PurchaseOrder] Update Purchase Order Product Total Weight",
    UPDATE_PURCHASEORDER_TOTALTAXRATE = "[PurchaseOrder] Update Purchase Order Total Tax Rate",
    MARK_AS_RECEIVED = "[PurchaseOrder] Mark Purchase order as received",

    RESET_STATE = "[PurchaseOrder] Reset State",
    RESET_SALESORDER_PRODUCTLISTS = "[PurchaseOrder] Reset Purchase order product lists",
    UPDATE_ORDER_CONFIRMATION_EMAIL_STATUS = "[PurchaseOrder] Order confirmation email sent status",
    UPDATE_ORDER_CONFIRMATION_EMAIL_DATE = "[PurchaseOrder] Order confirmation email sent date",
    Update_triggerSubmitCount = "[PurchaseOrder] Update_triggerSubmitCount",
}

interface PurchaseOrderAction extends Action {
    payload: any;
}

export const actions = {
    getPurchaseOrderList:
        (pageNo = 1, pageSize = 25, sortData = "", filters = []) =>
            (dispatch: Dispatch, getState) => {
                dispatch({ type: purchaseOrderActionType.LOAD_PURCHASEORDER, payload: true });
                purchaseOrderDataAccess
                    .purchaseOrderList({ pageNo, pageSize, sortData, filters })
                    .then((res) => {
                        dispatch({
                            type: purchaseOrderActionType.GET_PURCHASEORDER_LIST_SUCCESS,
                            payload: res,
                        });
                        dispatch({ type: purchaseOrderActionType.LOAD_PURCHASEORDER, payload: false });
                    });
            },


    getPurchaseOrderBasicInfo:
        (purchaseOrderId, companyId) => (dispatch: Dispatch, getState) => {
            dispatch({ type: purchaseOrderActionType.LOAD_PURCHASEORDER, payload: true });
            purchaseOrderDataAccess.getPurchaseOrderInfo(purchaseOrderId, companyId).then((res) => {
                dispatch({
                    type: purchaseOrderActionType.GET_PURCHASEORDER_BASICINFO,
                    payload: res?.data
                });
                dispatch({ type: purchaseOrderActionType.LOAD_PURCHASEORDER, payload: false });
            });
        },

    updateCompanyNameState: (res) => (dispatch: Dispatch, getState) => {
        dispatch({
            type: purchaseOrderActionType.GET_COMPANY_Name,
            payload: res,
        });
    },
    updateCompanyId: (res) => (dispatch: Dispatch, getState) => {
        dispatch({
            type: purchaseOrderActionType.UPDATE_COMPANY_ID,
            payload: res,
        });
    }
};

export type ActionType = PurchaseOrderAction;
