import {
  CheckCircleFilled,
  CloseCircleFilled,
  EditOutlined,
  DeleteOutlined,
  CopyOutlined,
  HolderOutlined,
  HistoryOutlined,
  RotateLeftOutlined,
  DownloadOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { Popconfirm, Tooltip } from "antd";
import { ReactNode } from "react";
import { SortableHandle } from "react-sortable-hoc";
import { KeyValue } from "src/models/typeMappings/keyvalue.type";
import Modal from "./ModalHandlers/CompliantModal";
import { LoaderModal } from "./ModalHandlers/LoaderModal";
import moment from "moment";

export interface ActionProps {
  onEdit?: () => void;
  //   history: any;
  onDelete?: () => void;
  hideEdit?: boolean;
}

let actionTypeIcon: KeyValue<ReactNode> = {
  edit: <EditOutlined />,
  delete: <DeleteOutlined />,
  copy: <CopyOutlined />,
  history: <HistoryOutlined />,
  convertProduct: <RotateLeftOutlined />,
  download: <DownloadOutlined />,
  save: <SaveOutlined />,
};

export interface ActionConfigType {
  onAction?: () => void;
  icon?: ReactNode;
  type?:
  | "edit"
  | "delete"
  | "copy"
  | "history"
  | "convertProduct"
  | "download"
  | "save";
  renderAction?: (content: ReactNode) => ReactNode;
  key?: any;
  title?: string;
}

export const ActionGenerator = (props: ActionConfigType[]) => {
  return (
    <div
      key={"ant-action-holder"}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        fontSize: "15px"
      }}
    >
      {props.map((action, index) => {
        let actionIcon = action.type
          ? action.icon
            ? action.icon
            : actionTypeIcon[action.type]
          : action.icon;
        return (
          <div key={"container-" + index} className="action-generator-item">
            {action.renderAction && (
              <div key={"render-" + index}>
                {action.renderAction(
                  actionIcon && (
                    <div className="action cursor-pointer">{actionIcon}</div>
                  )
                )}
              </div>
            )}
            {!action.renderAction && (
              <Tooltip title={action?.title ?? action.type}>
                <span
                  key={"auto-" + index}
                  className="cursor-pointer"
                  onClick={action.onAction}
                >
                  <div className="action">{actionIcon}</div>
                </span>
              </Tooltip>
            )}
          </div>
        );
      })}
    </div>
  );
};

export function trueFalseValueFormatter(item) {
  if (item === true) {
    return (
      <CheckCircleFilled
        title="Yes"
        style={{ color: "#002f71", fontSize: "14px" }}
      />
    );
  } else {
    return <CloseCircleFilled title="No" style={{ fontSize: "14px" }} />;
  }
}

export function EditDeleteAction(props: ActionProps) {
  return (
    <div className="row-list">
      {!props.hideEdit && (
        <span className="cursor-pointer" onClick={props.onEdit}>
          <EditOutlined className="action" />
        </span>
      )}
      <Popconfirm title="Sure to delete?" onConfirm={props.onDelete}>
        <span
          className="cursor-pointer"
          style={{ marginLeft: "8px" }}
        // onClick={props.onDelete}
        >
          <DeleteOutlined className="action" />
        </span>
      </Popconfirm>
    </div>
  );
}
export const configuredCell = (
  record,
  rowColorPropsName: string,
  context: any
) => {
  return {
    props: {
      style: {
        background: record[rowColorPropsName]
          ? record[rowColorPropsName]
          : "#fff",
      },
    },
    children: context,
  };
};

export const formatDate = (dateString: string) => {
  if (!dateString) {
    return "";
  }
  let date = new Date(dateString);
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getUTCFullYear();
  let dateValue =
    ("0" + day).slice(-2) + "/" + ("0" + month).slice(-2) + "/" + year;
  return dateValue;
};

export const formatDateTime = (dateString: string) => {
  let date = formatDate(dateString);
  if (date === "") {
    return "";
  }
  let dateObj = new Date(dateString);
  let hours = dateObj.getHours();
  let minutes = dateObj.getMinutes();
  let seconds = dateObj.getSeconds();

  let time =
    ("0" + hours).slice(-2) +
    ":" +
    ("0" + minutes).slice(-2) +
    ":" +
    ("0" + seconds).slice(-2);
  return date + " " + time;
};

export const formatDateWithMoment = (
  dateString: string,
  format: any = "DD/MM/yyyy"
) => {
  if (dateString === "" || dateString === undefined) {
    return "";
  }
  return moment(dateString).format(format);
};

const DragHandle = SortableHandle(() => (
  <HolderOutlined style={{ cursor: "grab", color: "#242424" }} />
));

export const DragSelector = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <DragHandle key="handlerForDrag" />
      {props.children}
    </div>
  );
};

export const colorAndRenderCell = (
  record,
  rowColorPropsName: string,
  context: any
) => {
  return {
    props: {
      style: {
        background: record[rowColorPropsName]
          ? record[rowColorPropsName]
          : "#fff",
      },
    },
    children: context,
  };
};

export const colorConfigOnCell = (
  record,
  rowColorPropsName: string,
  defaultColor: string = "#ffffff"
) => {
  return {
    style: {
      background: record[rowColorPropsName]
        ? record[rowColorPropsName]
        : defaultColor,
    },
  };
};

export const loaderModal = (text: string, width: string = "200px") => {
  return Modal.openModal({
    component: <LoaderModal />,
    data: text,
    maskClosable: false,
    width: width,
  });
};

export const previewImage = (data) => {
  var win = window.open();
  win.document.write(
    '<iframe src="' +
    data?.thumbUrl +
    '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
  );
};
