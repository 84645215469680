import commonDataAccess from "../commonDataAccess";

class CustomerItemTypeDataAccess {
  getCustomerItemTypeNameById = (id: any) => {
    return commonDataAccess.getData(`customerItemType/getNameById/${id}`);
  };
  getCustomerType = () => {
    return commonDataAccess.getData("customerItemType/get");
  };
  getCustomerTypeDropdown = () => {
    return commonDataAccess.getData("customerItemType/get/dropdown");
  };
  saveCustomerType = (model: any) => {
    return commonDataAccess.postData("customerItemType/save", model);
  };
  deleteCustomerType = (customerTypeId: any) => {
    return commonDataAccess.deleteData("customerItemType/delete",{
      id: customerTypeId
    });
  };
}

export default new CustomerItemTypeDataAccess();
