// import { IListState } from "src/@redux/listRedux";
import { useEffect, useMemo, useRef, useState } from "react";
import { actions as listActions } from "src/@redux/listRedux";
import { useAppDispatch } from "src/@redux/hook.type";
import { LeftOutlined, RightOutlined, SyncOutlined } from "@ant-design/icons";
import "./filterAntd.less";
import debounce from "lodash/debounce";
import { ValueLabel } from "src/models/common.models";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import DatePicker from "antd/lib/date-picker";
import Select from "antd/lib/select";
import Space from "antd/lib/space";
import Tooltip from "antd/lib/tooltip";
import Button from "antd/lib/button";
import { filterOptions } from "src/common/constants";
import { CustomSelect } from "../SelectFields";

const { RangePicker } = DatePicker;

export interface FilterOptionProps {
  id?: string;
  /**
   * name of the property
   */
  propertyName: string;
  /**
   * placeholder text for the field
   */
  placeholder?: string;
  /**
   * type of the input eg. text/date
   */
  inputType: "text" | "date" | "date-range" | "select";
  /**
   * options will be provided from filterOptions declared in constants file
   */
  typeOptions?: Array<any>;
  selectOptions?: ValueLabel[];
  width?: string;
  value?: any;
  hidden?: boolean;
  mode?: "multiple" | "tags";
}

export interface FilterProps {
  filterOptions?: FilterOptionProps[];
  onFilterChange?: (pageNo, pageSize, filters, sortData) => void;
  listState?: any;
  onResetFilter?: () => void;
}

//let timer;
const Filter = (props: FilterProps) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [expand, setExpand] = useState(false);
  const noOfDefaultFilers = 5;
  useEffect(() => {
    ///props.listState = { ...props.listState, filters: initializeFilter };
    dispatch(listActions.initializeFilter(props.filterOptions));
    return () => {
      dispatch(listActions.initializeFilter([]));
    };
  }, []);

  const fetchRef = useRef(0);

  const debounceSearch = useMemo(() => {
    let loadSearchData = (filters) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      if (fetchId !== fetchRef.current) {
        return;
      }
      props.onFilterChange(
        1,
        props.listState.pageSize,
        filters,
        props.listState.sort
      );
    };
    return debounce(loadSearchData, 600);
  }, []);

  if (props.listState.isLoading) return <div>Loadinng</div>;
  const getFields = () => {
    let count = expand
      ? props.listState.filters.length
      : props.listState.filters.length >= noOfDefaultFilers
      ? noOfDefaultFilers
      : props.listState.filters.length;
    if (count === 0) {
      count = expand
        ? props.filterOptions?.length
        : props.filterOptions?.length >= noOfDefaultFilers
        ? noOfDefaultFilers
        : props.filterOptions?.length;
    }
    const children = [];
    for (let index = 0; index < count; index++) {
      let item = props.filterOptions[index];
      children.push(
        // <Col span={4} key={index}>
        <div key={index}>
          {props.filterOptions[index] &&
            props.filterOptions[index].inputType === "text" && (
              <>
                <Form.Item name={item.propertyName}>
                  <Input
                    allowClear
                    type="text"
                    placeholder={item.placeholder}
                    style={{
                      width: props.filterOptions[index]?.width ?? "auto",
                    }}
                    value={item.value}
                    onChange={(event) => {
                      let filterState = [...props.listState.filters];
                      let filters = filterState.map((item) => ({ ...item }));
                      let updateIndex = filters.findIndex(
                        (a) =>
                          a.propertyName ===
                          props.filterOptions[index]?.propertyName
                      );

                      filters[updateIndex].value = event.target.value;
                      dispatch(listActions.updateFilter(filters));
                      dispatch(listActions.updatePageNo(1));
                      debounceSearch(filters);
                    }}
                  />
                </Form.Item>
              </>
            )}
          {props.filterOptions[index] &&
            props.filterOptions[index].inputType === "date" && (
              <Form.Item name={item.propertyName}>
                <DatePicker
                  placeholder={item.placeholder}
                  allowClear
                  value={item.value}
                  onChange={(value) => {
                    let filterState = [...props.listState.filters];
                    let filters = filterState.map((item) => ({ ...item }));
                    let updateIndex = filters.findIndex(
                      (a) =>
                        a.propertyName ===
                        props.filterOptions[index]?.propertyName
                    );
                    filters[updateIndex].value = value
                      ? value.toISOString()
                      : "";
                    dispatch(listActions.updateFilter(filters));
                    props.onFilterChange(
                      1,
                      props.listState.pageSize,
                      filters,
                      props.listState.sort
                    );
                  }}
                />
              </Form.Item>
            )}

          {props.filterOptions[index] &&
            props.filterOptions[index].inputType === "date-range" && (
              <Form.Item name={item.propertyName}>
                <RangePicker
                  value={item.value}
                  placeholder={[
                    props.filterOptions[index]?.placeholder + " start",
                    props.filterOptions[index]?.placeholder + " end",
                  ]}
                  style={{
                    width: props.filterOptions[index]?.width ?? "auto",
                  }}
                  onChange={(value) => {
                    let filterState = [...props.listState.filters];
                    let filters = filterState.map((item) => ({ ...item }));
                    let filterId = props.filterOptions[index]?.id;

                    if (value === null) {
                      let clearedFilter = filters.filter((item) => {
                        if (item?.id === filterId) {
                          item.value = [];
                          return item;
                        }
                        return item;
                      });
                      dispatch(listActions.updateFilter(clearedFilter));
                      props.onFilterChange(
                        1,
                        props.listState.pageSize,
                        clearedFilter,
                        props.listState.sort
                      );
                    } else {
                      let [startDate, endDate] = value;

                      let filterContents = filters.filter((item) => {
                        if (item?.id === filterId) {
                          item.type = [
                            filterOptions.greaterThanEqual.value,
                            filterOptions.lessThanEqual.value,
                          ];
                          item.value = [
                            startDate ? startDate.toISOString() : "",
                            endDate ? endDate.toISOString() : "",
                          ];
                        }
                        return item;
                      });

                      dispatch(listActions.updateFilter(filterContents));
                      props.onFilterChange(
                        1,
                        props.listState.pageSize,
                        filterContents,
                        props.listState.sort
                      );
                    }
                  }}
                />
              </Form.Item>
            )}

          {props.filterOptions[index] &&
            props.filterOptions[index].inputType === "select" && (
              <Form.Item name={item.propertyName}>
                <CustomSelect
                  placeholder={item.placeholder}
                  style={{
                    minWidth: "100px",
                    width: props.filterOptions[index]?.width,
                  }}
                  optionFilterProp="children"
                  mode={props.filterOptions[index].mode}
                  onChange={(value) => {
                    let filterState = [...props.listState.filters];
                    let filters = filterState.map((item) => ({ ...item }));
                    filters[index].value = value;
                    dispatch(listActions.updateFilter(filters));
                    props.onFilterChange(
                      1,
                      props.listState.pageSize,
                      filters,
                      props.listState.sort
                    );
                  }}
                  //onSearch={onSearch}
                  // filterOption={(input, option) =>
                  //   option.children
                  //     .toLowerCase()
                  //     .indexOf(input.toLowerCase()) >= 0
                  // }
                  options={props.filterOptions[index]?.selectOptions}
                />
              </Form.Item>
            )}
        </div>
      );
    }
    return children;
  };
  return (
    <>
      <Form
        form={form}
        name="frmListFilter"
        id="frmListFilter"
        className="listFilters ant-advanced-search-form table-filter-style"
      >
        <Space
          align="start"
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
          key="filter_space"
        >
          <Tooltip title="Reload" key={"reset_filter"}>
            <Button
              key={"reset_filter_button"}
              name={"reset_filter_button"}
              shape="circle"
              icon={<SyncOutlined />}
              onClick={() => {
                form.resetFields();
                let filters = props.listState.filters?.map((itm) => {
                  let cpy = { ...itm };
                  cpy.value = "";
                  return cpy;
                });

                dispatch(listActions.updateFilter(filters));
                dispatch(listActions.updatePageNo(1));
                props.onFilterChange(
                  1,
                  props.listState.pageSize,
                  filters,
                  props.listState.sort
                );
              }}
            />
          </Tooltip>

          {getFields()}

          {props.listState.filters.length > noOfDefaultFilers && (
            <Button
              type={expand ? "default" : "primary"}
              style={{ fontSize: 12 }}
              onClick={() => {
                setExpand(!expand);
              }}
            >
              {expand ? <LeftOutlined /> : <RightOutlined />}
              {expand ? "Collapse" : "Expand"}
            </Button>
          )}
        </Space>
      </Form>
    </>
  );
};
export default Filter;
