import { IFilter } from "src/@redux/listRedux";
import commonDataAccess from "../commonDataAccess";

class DocumentHistoryDataAccess {
  getList = (params: {
    entityId: number;
    entityType: string;
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      entityId: params.entityId,
      entityType: params.entityType,
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };
    return commonDataAccess.getData("document/get/history", parameters);
  };

  getHistoryDocument = (nameGuid: string) => {
    return commonDataAccess.downloadFile("document/get/historyDoc", {
      fileNameGuid: nameGuid,
    });
  };
}

export default new DocumentHistoryDataAccess();
