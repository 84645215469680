import { createReducer } from "@reduxjs/toolkit";
import { getIncidentListSuccess, loadingList, saveIncidentListSuccess } from "./incidentActions";

export interface IIncidentState {
  incidentList: Array<any>;
  dataCount: number;
  isLoading: boolean;
}

const initialState: IIncidentState = {
  incidentList: [],
  dataCount: 0,
  isLoading: false,
};

export const incidentReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getIncidentListSuccess, (state, action) => {
      state = {
        ...state,
        incidentList: action.payload?.data,
        dataCount: action.payload?.dataCount,
      };
      return state;
    })

    .addCase(saveIncidentListSuccess, (state, action) => {
      state = {
        ...state,
        incidentList: action.payload,
      };
      return state;
    })

    .addCase(loadingList, (state, action) => {
      state = {
        ...state,
        isLoading: action.payload,
      };
      return state;
    });
});
