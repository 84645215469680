import { IFilter } from "src/@redux/listRedux";
import commonDataAccess from "../commonDataAccess";

class CompanyDataAccess {

  companyList = async (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    console.log(params.filters);
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };
    return await commonDataAccess.getData(
      "company/getAll",
      parameters
    );
  };

  getCompanyBasicInfo = (companyId) => {
    return commonDataAccess.getData("company/get/basicinfo", { id: companyId });
  };
  saveCompanyBasicInfo = (model: any) => {
    return commonDataAccess.postData("company/save/basicInfo", model);
  };
  getPricingTiersForDropdown = () => {
    return commonDataAccess.getData("company/get/priceTierList");
  };
  deleteCompany = async (params: { ids: string }) => {
    var result = await commonDataAccess.deleteData("company/delete/" + params);
    return result;
  };
  getCompanyAccounts = (companyId) => {
    return commonDataAccess.getData("company/get/accounts", { id: companyId });
  };
  saveCompanyAccounts = (model: any) => {
    return commonDataAccess.postData("company/save/accounts", model);
  };
  getCompanyDeliveryAddress = (companyId: bigint | any, type: string | any) => {
    return commonDataAccess.getData("company/get/addressByType", { id: companyId, addressType: type });
  };
  saveCompanyDeliveryAddress = (model: any) => {
    return commonDataAccess.postData("company/save/addressByType", model);
  };
  getCompanyBilingAddress = (companyId: bigint | any, type: string | any) => {
    return commonDataAccess.getData("company/get/addressByType", { id: companyId, addressType: type });
  };
  saveCompanyBilingAddress = (model: any) => {
    return commonDataAccess.postData("company/save/addressByType", model);
  };
  getCompanyNamesForDropdown = (type: string | any) => {
    return commonDataAccess.getData("company/get/companyNameForDropdown", { type: type });
  };
}
export default new CompanyDataAccess();
