import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import tabDataaccess from "src/dataAccess/procedures/tab.dataaccess";
import { IListType } from "../store";

export const getTabListSuccess = createAction<any>(
  "[Tab] Get Tab List Success"
);

export const loadingList = createAction<boolean>("[Tab] Loading list");
export const LoadingData = createAction<boolean>("[Tab] Loading data");
export const getTabListAsync = createAsyncThunk<any, IListType>(
  "[Tab] Get Tab List",
  async (parameter, thunkAPI) => {
    thunkAPI.dispatch(loadingList(true));

    const result = await tabDataaccess.getList(parameter).then((res) => {
      thunkAPI.dispatch(getTabListSuccess(res));
      thunkAPI.dispatch(loadingList(false));
      return res;
    });
    return result;
  }
);
