import { notification } from "antd";
import { lazy, Suspense } from "react";
import { Switch } from "react-router";
import { Route } from "react-router-dom";
import auth from "src/hoc/auth";

const Main = lazy(() => import("./Main"));
const PublicRouter = lazy(() => import("src/Routing/public-router"));

// We shouldn't do any change here
export const MainRouter = () => {
  notification.config({
    placement: "top",
    maxCount: 3,
    top: 12,
  });
  return (
    <Suspense fallback={"Loading ..."}>
      <Switch>
        <Route
          path={["/login"]}
          component={auth(PublicRouter, [], true, true)}
        />
        <Route path={["/"]} component={auth(Main, [])}></Route>
      </Switch>
    </Suspense>
  );
};
