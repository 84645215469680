import commonDataAccess from "../commonDataAccess";

class ProcedureStepDataAccess {
  getAuthorizedUsers(stepId: any) {
    return commonDataAccess.getData("procedureStep/get/authorizedusers", {
      stepId: stepId,
    });
  }
  copystep(
    procedureIds: any,
    ids: any,
    sequenceposition: any,
    noOfStepToCreate: any
  ) {
    return commonDataAccess.getData("procedureStep/copy", {
      procedureIds: procedureIds,
      stepIds: ids,
      sequenceposition: sequenceposition,
      noOfStepToCreate: noOfStepToCreate,
    });
  }
  getProcedureStepDropdown = (
    procedureId: any,
    stepTypes: string = null,
    stepGroupId: number = 0
  ) => {
    return commonDataAccess.getData(
      "procedureStep/get/stepDropdownByProcedure",
      {
        procedureId: procedureId,
        stepTypes: stepTypes,
        stepGroupId: stepGroupId,
      }
    );
  };
  getProcedureStepList = (procedureId: number) => {
    return commonDataAccess.getData("procedureStep/get/steplistbyprocedure", {
      procedureId: procedureId,
    });
  };
  steplistbync = (ncId: number, typeProcedure: number) => {
    return commonDataAccess.getData("procedureStep/get/steplistbync", {
      ncId: ncId,
      typeProcedure: typeProcedure,
    });
  };
  saveStepBasicInfo = (payload: any) => {
    return commonDataAccess.postData("ProcedureStep/save/basicinfo", payload);
  };
  saveStepOtherInfo = (payload: any) => {
    return commonDataAccess.postData("ProcedureStep/save/otherInfo", payload);
  };
  saveProcedureStepSequence = (
    procedureId: number,
    stepId: number,
    prevPos: number,
    currentPos: number
  ) => {
    return commonDataAccess.getData("procedureStep/update/sequenceposition", {
      procedureId: procedureId,
      stepId: stepId,
      previousSeqNo: prevPos,
      currentSequenceNo: currentPos,
    });
  };
  getProcedureStepBasicInfoByStepId = (stepId: number) => {
    return commonDataAccess.getData("procedureStep/get/basic", {
      stepId: stepId,
    });
  };
  stepDetailMaxGoToTabLength = (stepType: string) => {
    return commonDataAccess.getData("procedureStep/get/maxgototablength", {
      steptype: stepType,
    });
  };
  stepDetailMaxGotToStepLength = (stepType: string, listId: number) => {
    return commonDataAccess.getData("procedureStep/get/maxgotosteplength", {
      steptype: stepType,
      listId: listId,
    });
  };
  getStepOtherInfoByStepId = (stepId: number) => {
    return commonDataAccess.getData("procedureStep/get/other", {
      stepId: stepId,
    });
  };
  getStepDropdownWithFilter = (
    procedureId: number,
    exceptStepId: number = 0,
    stepType: string = null,
    containingSteps: string = null
  ) => {
    return commonDataAccess.getData(
      "procedureStep/get/stepDropdownWithFilter",
      {
        procedureId: procedureId,
        exceptStepId: exceptStepId,
        stepType: stepType,
        containingSteps: containingSteps,
      }
    );
  };

  deleteStep = (stepIds: string, stepIdList: string = null) => {
    return commonDataAccess.deleteData("procedureStep/delete/procedureStep", {
      stepIds: stepIds,
    });
  };
  getStepDocumentInfo = (stepId: number) => {
    return commonDataAccess.getData("procedureStep/get/documentinfo", {
      stepId: stepId,
    });
  };
  saveStepDocuments = (payload: any) => {
    return commonDataAccess.postData("procedureStep/save/documents", payload);
  };
}

export default new ProcedureStepDataAccess();
