import { IFilter } from "src/@redux/listRedux";
import commonDataAccess from "../commonDataAccess";

class TrainingPackDataAccess {
  getTrainingPackList = (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };
    return commonDataAccess.getData("trainingPack/getAll", parameters);
  };
  get = (id) => {
    return commonDataAccess.getData("trainingPack/get", { id: id });
  };
  save = (payload) => {
    return commonDataAccess.postData("trainingPack/save", payload);
  };
  getallData = () => {
    return commonDataAccess.getData("trainingPack/getallData");
  };
  saveUserTrainingPack = (userId: number, selectedPackIds: string) => {
    return commonDataAccess.postData(
      "trainingPack/save/userTrainingPack",
      null,
      { userId: userId, selectedPackIds: selectedPackIds }
    );
  };
  delete = (ids) => {
    return commonDataAccess.deleteData("trainingPack/delete", { ids: ids });
  };
  downloadTrainingPackRecord = (
    payload: any,
    downloadProgress: (progress: any) => void
  ) => {
    return commonDataAccess.downloadPost({
      url: "trainingPack/download/trainingPackReport",
      payload: payload,
      onDowloadProgress: downloadProgress,
    });
  };
  getLinkedEmployees = (trainingPackId: any) => {
    return commonDataAccess.getData(`trainingPack/get/linkedemployees/${trainingPackId}`);
  };
}

export default new TrainingPackDataAccess();
