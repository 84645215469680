import { createReducer } from "@reduxjs/toolkit";
import {
  getTrainingMatrixDataSuccess,
  loadMoreData,
  loadTrainingMatrix,
  updatePageNumber,
} from "./training-matrix.action";

interface matrixHead {
  id: number;
  name: string;
}

interface TrainingMatrix {
  matrixList: any[];
  matrixEmployees: matrixHead[];
  matrixTrainings: matrixHead[];
  totalCount: number;
  pageNumber: number;
  loadingMatrix: boolean;
  loadMoreData: boolean;
}

const initialState: Partial<TrainingMatrix> = {
  matrixList: [],
  matrixEmployees: [],
  matrixTrainings: [],
  totalCount: -1,
};

export const trainingMatrixReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loadTrainingMatrix, (state, action) => {
      state = {
        ...state,
        loadingMatrix: action.payload,
      };
      return state;
    })
    .addCase(updatePageNumber, (state, action) => {
      state = {
        ...state,
        pageNumber: action?.payload,
      };
      return state;
    })
    .addCase(getTrainingMatrixDataSuccess, (state, action) => {
      state = {
        ...state,
        matrixEmployees: action?.payload?.employees,
        matrixTrainings:
          state?.matrixTrainings?.length >= action?.payload?.totalCount
            ? state?.matrixTrainings
            : (state?.matrixTrainings ?? []).concat(action?.payload?.trainings),
        matrixList:
          state?.matrixTrainings?.length >= action?.payload?.totalCount
            ? state?.matrixList
            : (state?.matrixList ?? []).concat(action?.payload?.matrix),
        totalCount: action?.payload?.totalCount,
      };

      return state;
    })
    .addCase(loadMoreData, (state, action) => {
      state = {
        ...state,
        loadMoreData: action?.payload,
      };
      return state;
    });
});
