import { createReducer } from "@reduxjs/toolkit";
import { ComplaintSettings } from "src/models/complaint.settings";
import {
  ChangeComplaintCount,
  DeleteComplaintSuccess,
  GetComplaintList,
  GetComplaintsCount,
  GetComplaintSettings,
  GetComplaintSettingSuccess,
  SaveAndEditContinue,
  SetComplaintGeneralSuccess,
  UpdateComplaintGeneral,
} from "./complaint.action";

export interface ComplaintCount {
  overdue: number;
  resolved: number;
  outstanding: number;
}

export interface IComplaintState {
  list: any[];
  dataCount: number;
  isLoadingList: boolean;
  complaintSettings: ComplaintSettings;
  complaintGeneral: any;
  complaintsCount: Partial<ComplaintCount>;
  IsSaveAndEditContinue: boolean;
}

const initialState: Partial<IComplaintState> = {
  list: [],
  dataCount: 0,
};

export const complaintReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(GetComplaintList.pending, (state, action) => {
      state = {
        ...state,
        isLoadingList: true,
      };
      return state;
    })

    .addCase(GetComplaintList.fulfilled, (state, action) => {
      state = {
        ...state,
        list: action.payload?.data,
        dataCount: action.payload?.dataCount,
        isLoadingList: false,
      };
      return state;
    })
    .addCase(GetComplaintSettings.fulfilled, (state, action) => {
      state = {
        ...state,
        complaintSettings: action.payload,
      };
      return state;
    })
    .addCase(SaveAndEditContinue, (state, action) => {
      state = {
        ...state,
        IsSaveAndEditContinue: action.payload,
      };
      return state;
    })
    .addCase(GetComplaintSettingSuccess, (state, action) => {
      state = {
        ...state,
        complaintSettings: action.payload,
      };
      return state;
    })
    .addCase(SetComplaintGeneralSuccess, (state, action) => {
      state = {
        ...state,
        complaintGeneral: action.payload,
      };
      return state;
    })
    .addCase(DeleteComplaintSuccess, (state, action) => {
      let filtered = state.list.filter((item) => {
        return !action.payload?.includes(item.id.toString());
      });
      let deleteCount = action.payload?.length;
      let deleteType = countDeleteRows(action.payload, state.list);

      state = {
        ...state,
        list: filtered,
        dataCount: state.dataCount - deleteCount,
      };

      if ((state.complaintsCount ?? "") !== "") {
        state = {
          ...state,
          complaintsCount: {
            overdue: state.complaintsCount?.overdue - deleteType.overdue,
            outstanding:
              state.complaintsCount?.outstanding -
              deleteType.outstanding -
              deleteType.overdue,
            resolved: state.complaintsCount?.resolved - deleteType.resolved,
          },
        };
      }
      return state;
    })
    .addCase(UpdateComplaintGeneral, (state, action) => {
      let data = {
        ...state.complaintGeneral,
        ...action.payload,
      };

      state = {
        ...state,
        complaintGeneral: data,
      };
      return state;
    })
    .addCase(GetComplaintsCount.fulfilled, (state, action) => {
      state = {
        ...state,
        complaintsCount: {
          overdue: action?.payload?.overdueCount,
          outstanding: action?.payload?.outstandingCount,
          resolved: action?.payload?.resolvedCount,
        },
      };
      return state;
    })
    .addCase(ChangeComplaintCount, (state, action) => {
      state = {
        ...state,
        complaintsCount: {
          ...state.complaintsCount,
          ...(action.payload as any),
        },
      };
      return state;
    });
});

function countDeleteRows(ids: string[], state: any[]): any {
  let deletedRows = state.filter((item) => ids.includes(item?.id?.toString()));

  let deleteCount: any = deletedRows.reduce(
    (total, currentValue) => {
      return {
        outstanding:
          total.outstanding +
          ((currentValue.status as string).toLowerCase() === "outstanding"
            ? 1
            : 0),
        resolved:
          total.resolved +
          ((currentValue.status as string).toLowerCase() === "resolved"
            ? 1
            : 0),
        overdue:
          total.overdue +
          ((currentValue.status as string).toLowerCase() === "overdue" ? 1 : 0),
      };
    },
    {
      outstanding: 0,
      resolved: 0,
      overdue: 0,
    }
  );

  return deleteCount;
}
