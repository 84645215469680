import { ActionType, quickbookActionType } from "./quickbookAction";

export interface IQuickbookState {
    companies: any[];
    invoices: any[];
    chartOfAccounts: any[];
    taxRates: any[];
    errors: any[];
    companyBasicInfo: any;
    quickbookInvoiceRowSelections: string | any;
    filters: any[];
    dataCount: number;
    customerDataCount: number;
    invoiceDataCount: number;
    taxRateDataCount: number;
    chartOfAccountDataCount: number;
    errorDataCount: number;
    isLoading: boolean;
    currentTabName: any;
    isConnected: boolean;
}

const initialState: IQuickbookState = {
    companyBasicInfo: {},
    invoices: [],
    companies: [],
    chartOfAccounts: [],
    taxRates: [],
    errors: [],
    quickbookInvoiceRowSelections: "",
    filters: [],
    dataCount: 0,
    customerDataCount: 0,
    invoiceDataCount: 0,
    taxRateDataCount: 0,
    chartOfAccountDataCount: 0,
    errorDataCount: 0,
    isLoading: false,
    currentTabName: "",
    isConnected: false
};

export const QuickbookReducer = (
    state: IQuickbookState = initialState,
    action: ActionType
) => {
    switch (action.type) {
        case quickbookActionType.GET_COMPANY_LIST_SUCCESS:
            state = {
                ...state,
                companies: action.payload?.data,
                customerDataCount: action.payload?.dataCount,
            };
            return state;
        case quickbookActionType.GET_INVOICE_LIST_SUCCESS:
            state = {
                ...state,
                invoices: action.payload?.data,
                invoiceDataCount: action.payload?.dataCount,
            };
            return state;
        case quickbookActionType.GET_CHART_OF_ACCOUNT_LIST_SUCCESS:
            state = {
                ...state,
                chartOfAccounts: action.payload?.data,
                chartOfAccountDataCount: action.payload?.dataCount,
            };
            return state;
        case quickbookActionType.GET_TAX_RATE_LIST_SUCCESS:
            state = {
                ...state,
                taxRates: action.payload?.data,
                taxRateDataCount: action.payload?.dataCount,
            };
            return state;

        case quickbookActionType.LOAD_COMPANY:
            state = {
                ...state,
                isLoading: action.payload,
            };
            return state
        case quickbookActionType.GET_ERROR_LIST_SUCCESS:
            state = {
                ...state,
                errors: action.payload?.data,
                errorDataCount: action.payload?.dataCount,
            };
            return state;

        case quickbookActionType.LOAD_ERROR:
            state = {
                ...state,
                isLoading: action.payload,
            };
            return state
        case quickbookActionType.LOAD_INVOICE:
            state = {
                ...state,
                isLoading: action.payload,
            };
            return state
        case quickbookActionType.LOAD_CHART_OF_ACCOUNT:
            state = {
                ...state,
                isLoading: action.payload,
            };
            return state
        case quickbookActionType.LOAD_TAX_RATE:
            state = {
                ...state,
                isLoading: action.payload,
            };
            return state
        case quickbookActionType.UPDATE_TAB_NAME:
            state = {
                ...state,
                currentTabName: action.payload,
            };
            return state;
        case quickbookActionType.UPDATE_QUICKBOOK_CONNECTION:
            state = {
                ...state,
                isConnected: action.payload,
            };
            return state;
        case quickbookActionType.UPDATE_QUICKBOOK_INVOICES_ROW_SELECTIONS:
            state = {
                ...state,
                quickbookInvoiceRowSelections: action.payload,
            };
            return state;
        default:
            return state;
    }
};
