import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { IListType } from "../store";
import rackDataAccess from "src/dataAccess/lcoations/rackDataAccess";

export const getRackListSuccess = createAction<any>(
  "[Rack] Get Rack List Success"
);
export const loadingList = createAction<boolean>("[Rack] Loading list");

export const getRackListAsync = createAsyncThunk<any, IListType>(
  "[Rack] Get Rack List",
  async (parameter, thunkAPI) => {
    thunkAPI.dispatch(loadingList(true));

    const result = await rackDataAccess.rackList(parameter).then((res) => {
      thunkAPI.dispatch(getRackListSuccess(res));
      thunkAPI.dispatch(loadingList(false));
      return res;
    });
    return result;
  }
);

export const updateRackListSuccess = createAction<any>(
  "[Rack] Update Rack PutAway Success"
);

export const updateRackListAsync = createAsyncThunk<
  any,
  { id: number; isPutAway: boolean; rackList: Array<any> }
>("[Rack] Update Rack PutAway", async (parameter, thunkAPI) => {
  const { id, isPutAway, rackList } = parameter;
  thunkAPI.dispatch(loadingList(true));
  const result = await rackDataAccess
    .updatePutAway({ id, isPutAway })
    .then((res) => {
      let copyRackList = [...rackList];
      var updatedRackList = (copyRackList ?? []).map((item, index) => {
        let copyItem = { ...item };
        if (copyItem.id === res.data.id) {
          copyItem.isPutAway = res.data.isPutAway;
        }
        return copyItem;
      });
      thunkAPI.dispatch(updateRackListSuccess(updatedRackList));
      thunkAPI.dispatch(loadingList(false));
      return res;
    });
  return result;
});
export const updateRecipeLocationAsync = createAsyncThunk<
  any,
  { id: number; isRecipeLocation: boolean; rackList: Array<any> }
>("[Rack] Update Recipe Location", async (parameter, thunkAPI) => {
  const { id, isRecipeLocation, rackList } = parameter;
  thunkAPI.dispatch(loadingList(true));
  const result = await rackDataAccess
    .updateRecipeLocation({ id, isRecipeLocation })
    .then((res) => {
      let copyRackList = [...rackList];
      var updatedRackList = (copyRackList ?? []).map((item, index) => {
        let copyItem = { ...item };
        if (copyItem.id === res.data.id) {
          copyItem.isRecipeLocation = res.data.isRecipeLocation;
        }
        return copyItem;
      });
      thunkAPI.dispatch(updateRackListSuccess(updatedRackList));
      thunkAPI.dispatch(loadingList(false));
      return res;
    });
  return result;
});

export const saveRackListSuccess = createAction<any>(
  "[Rack] Save Rack Success"
);

export const saveRackListAsync = createAsyncThunk<
  any,
  { rackId: any; data: any; rackList: Array<any> }
>("[Rack] Save Rack", async (parameter, thunkAPI) => {
  const { rackId, data, rackList } = parameter;
  thunkAPI.dispatch(loadingList(true));
  var updatedRackList;
  if (rackId == "edit") {
    let copyRackList = [...rackList];
    updatedRackList = (copyRackList ?? []).map((item, index) => {
      let copyItem = { ...item };

      if (copyItem.id == data.id) {
        // copyItem.name = data.name;
        // copyItem.rackLocationBarcode = data.rackLocationBarcode;
        // copyItem.isPutAway = data.isPutAway;
        copyItem = data;
      }
      return copyItem;
    });
  } else {
    let copyRackList = [...rackList];
    updatedRackList = [...copyRackList, data];
  }
  thunkAPI.dispatch(saveRackListSuccess(updatedRackList));
  thunkAPI.dispatch(loadingList(false));
  return data;
});
