import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import locationDataAccess from "src/dataAccess/lcoations/locationDataAccess";

export const loadingData = createAction<boolean>("[Settings] Loading data");

export enum locationActionTypes {
  GET_LOCATION_DROPDOWN = "[Location] Get location dropdown",
}

export const getLocationDropDownSuccess = createAction<any[]>(
  "[Location] Get location dropdown success"
);

export const getLocationDropdownAsync = createAsyncThunk(
  "[Location] Get location dropdown",
  async (parameter, thunkAPI) => {
    const result = await locationDataAccess
      .getLocationDropdown()
      .then((res) => {
        thunkAPI.dispatch(getLocationDropDownSuccess(res?.data));
        return res;
      });
    return result;
  }
);

export const getLocationBasicInfoSuccess= createAction<any[]>(
  "[Location] Get location Basic Information success"
);
export const saveLocationBasicInfoSuccess= createAction<any[]>(
  "[Location] Save location Basic Information success"
);

export const getLocationBasicInfo = createAsyncThunk<any, number>(
  "[Location] Get location Basic Information",
  async (locationId, thunkAPI) => {
    thunkAPI.dispatch(loadingData(true));
    const result = await locationDataAccess
      .getLocationBasicInfo(locationId)
      .then((res) => {
        thunkAPI.dispatch(getLocationBasicInfoSuccess(res?.data));
        thunkAPI.dispatch(loadingData(false));
        return res;
      });
    return result;
  }
);

export const getLocationDatesSuccess= createAction<any[]>(
  "[Location] Get location Dates success"
);
export const saveLocationDatesSuccess= createAction<any[]>(
  "[Location] Save location Dates success"
);

export const getLocationDates = createAsyncThunk<any, number>(
  "[Location] Get location Dates",
  async (locationId, thunkAPI) => {
    thunkAPI.dispatch(loadingData(true));
    const result = await locationDataAccess
      .getLocationDates(locationId)
      .then((res) => {
        thunkAPI.dispatch(getLocationDatesSuccess(res?.data));
        thunkAPI.dispatch(loadingData(false));
        return res;
      });
    return result;
  }
);

export const getLocationSupplierSuccess= createAction<any[]>(
  "[Location] Get location Supplier success"
);
export const saveLocationSupplierSuccess= createAction<any[]>(
  "[Location] Save location Supplier success"
);

export const getLocationSupplier = createAsyncThunk<any, number>(
  "[Location] Get location Supplier",
  async (locationId, thunkAPI) => {
    thunkAPI.dispatch(loadingData(true));
    const result = await locationDataAccess
      .getLocationSupplier(locationId)
      .then((res) => {
        thunkAPI.dispatch(getLocationSupplierSuccess(res?.data));
        thunkAPI.dispatch(loadingData(false));
        return res;
      });
    return result;
  }
);

export const getLocationCommentSuccess= createAction<any[]>(
  "[Location] Get location Comment success"
);
export const saveLocationCommentSuccess= createAction<any[]>(
  "[Location] Save location Comment success"
);

export const getLocationComments = createAsyncThunk<any, number>(
  "[Location] Get location Comments",
  async (locationId, thunkAPI) => {
    thunkAPI.dispatch(loadingData(true));
    const result = await locationDataAccess
      .getLocationComments(locationId)
      .then((res) => {
        thunkAPI.dispatch(getLocationCommentSuccess(res?.data));
        thunkAPI.dispatch(loadingData(false));
        return res;
      });
    return result;
  }
);

export const getAssociatedProceduresSuccess= createAction<any[]>(
  "[Location] Get location Associated Procedures success"
);
export const getAssociatedProcedures = createAsyncThunk<any, number>(
  "[Location] Get location Associated Procedures",
  async (locationId, thunkAPI) => {
    thunkAPI.dispatch(loadingData(true));
    const result = await locationDataAccess
      .getAssociatedProcedures(locationId)
      .then((res) => {
        thunkAPI.dispatch(getAssociatedProceduresSuccess(res?.data));
        thunkAPI.dispatch(loadingData(false));
        return res;
      });
    return result;
  }
);

