import "./Quickbook.less";
import { Button, Card, Dropdown, Menu, notification, Space, Tabs, Tooltip } from "antd";
import { CommonPageHeader } from "src/microComponent/PageHeader/CommonPageHeader";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/@redux/hook.type";
import { actions as quickbookAction, quickbookActionType } from "src/@redux/quickbook/quickbookAction";
import { useLocation } from "react-router-dom";
import { ApiOutlined, DownOutlined } from "@ant-design/icons";
import Sales from "./Sales";
import Purchases from "./Admin";
import QuickBookAuth from "./QuickBookAuth";
import quickbookDataAccess from "src/dataAccess/quickbook/quickbookDataAccess";
import { savingData } from "src/@redux/common/commonAction";
import { StatusTag } from "src/microComponent/StatusTag/StatusTag";
import { EmailStatusMap, quickbookConnectionStatusMap } from "src/common/constants";
import { loaderModal } from "src/microComponent/component.service";
import Admin from "./Admin";
import { dispatch } from "src";
import { handleApiResponse } from "src/common/exception";
import Discrepancies from "./Discrepancies";
import Errors from "./Errors";



const { TabPane } = Tabs;

export const handleQuickbookConnection = async () => {
    let ref = loaderModal("Connecting to quickbooks ...");
    await quickbookDataAccess.getQuickbookAuth().then((res) => {
        dispatch(savingData(false));
        if (res?.success) {
            const authUrl = res?.data;
            if (authUrl) {
                window.location.href = authUrl;
            }
        } else {
            notification.error({ message: "Failed to get Quickbook authUrl" });
        }
        ref.destroy();
    }).catch((err) => {
        dispatch(savingData(false));
        notification.error({ message: "An error occurred while getting Quickbook authUrl" });
        ref.destroy();
    });
}

const Quickbook = (props) => {
    const [tabKey, setTabKey] = useState("sales");
    const quickbookState = useAppSelector((state) => state.quickbook);
    const [isConnected, setIsConnected] = useState(false);
    const parentLocation = useRef<any>();

    const isSaving = useAppSelector((state) => state.common.isSaving);
    const tabName = useAppSelector((state) => state.quickbook.currentTabName);
    const listState = useAppSelector((state) => state.listState);
    const dispatch = useAppDispatch();
    const ref = useRef(null);
    const route = useLocation();

    useEffect(() => {
        parentLocation.current = route.pathname.split("/")[1];
        dispatch(quickbookAction.updateTabNameState("sales"));
    }, [route.pathname]);

    const setCurrentTab = (key) => {
        setTabKey(key);
        if (key === "admin" && !quickbookState.isConnected) {
            notification.error({ message: "Connect with Quickbook" });
        }
        dispatch(quickbookAction.updateTabNameState(key));
    };

    const syncQuickbookData = async () => {
        let ref = loaderModal("Syncing data ...");
        await quickbookDataAccess.syncQuickbookData().then((res) => {
            if (res?.success) {
                if (res?.data) {
                    notification.success({ message: "Quickbook has successfully synced" });
                }
            } else {
                notification.error({ message: "Failed to sync quickbook data" });
            }
            ref.destroy();
        }).catch((err) => {
            dispatch(savingData(false));
            notification.error({ message: "An error occurred while syncing quickbook data" });
            ref.destroy();
        });
    }

    const uploadCustomers = async () => {
        let ref = loaderModal("Uploading data ...");
        await quickbookDataAccess.uploadCustomers().then((res) => {
            const data = handleApiResponse(res);
            dispatch(
                quickbookAction.getAllCompanyList(
                    listState.pageNo,
                    listState.pageSize,
                    listState.sort,
                    listState.filters
                )
            );
        }).catch((err) => {
            dispatch(savingData(false));
            notification.error({ message: "An error occurred while uploading customer data" });
        });
        ref.destroy();
    }

    const uploadInvoices = async () => {
        let ref = loaderModal("Uploading data ...");
        await quickbookDataAccess.uploadInvoices(quickbookState.quickbookInvoiceRowSelections).then((res) => {
            const data = handleApiResponse(res);
            dispatch(
                quickbookAction.getAllInvoiceList(
                    listState.pageNo,
                    listState.pageSize,
                    listState.sort,
                    listState.filters
                )
            );
            ref.destroy();
        }).catch((err) => {
            dispatch(savingData(false));
            notification.error({ message: "An error occurred while uploading invoice data" });
        });
        ref.destroy();
    }

    const menu = (
        <Menu>
            <Menu.Item key="uploadCustomers" onClick={uploadCustomers}>Upload Customers</Menu.Item>
            <Menu.Item key="uploadInvoices" onClick={uploadInvoices}>Upload Invoices</Menu.Item>
        </Menu>
    );

    return (
        <>
            <CommonPageHeader
                title={
                    "QuickBooks Dashboard"
                }
                extra={[
                    <Tooltip title="Sync Quickbook Data" key="syncQuickbookData">
                        <Button
                            // icon={<ApiOutlined />}
                            type="primary"
                            disabled
                            onClick={syncQuickbookData}>
                            Sync All
                        </Button>
                        <Dropdown overlay={menu}>
                            <Button>
                                <DownOutlined />
                            </Button>
                        </Dropdown>
                    </Tooltip>,
                    <Tooltip title={quickbookState.isConnected ? "Connected" : "Connect to QuickBooks"} key="connectToQuickbooks">
                        <Button
                            icon={<ApiOutlined />}
                            type="default"
                            onClick={handleQuickbookConnection}
                            className="outlined-button">
                            {quickbookState.isConnected ? "Connected" : "Connect to QuickBooks"}
                            <span className={`connection-indicator ${quickbookState.isConnected ? 'connected' : 'disconnected'}`}></span>
                        </Button>
                    </Tooltip>
                ]}
            />
            <div className="page-body-content">
                <Card style={{ width: "100%" }}>
                    <Tabs
                        defaultActiveKey="sales"
                        type="card"
                        size="small"
                        tabBarGutter={5}
                        onChange={setCurrentTab}
                    >
                        <TabPane
                            tab="Sales"
                            key="sales"
                        >
                            <Sales
                                {...props}
                                formId={"sales"}
                            />
                        </TabPane>
                        <TabPane
                            tab="Admin"
                            key="admin"
                        >
                            <Admin
                                {...props}
                                formId={"admin"} />
                        </TabPane>
                        <TabPane
                            tab="Discrepancies"
                            key="discrepancies"
                        >
                            <Discrepancies
                                {...props}
                                formId={"discrepancies"} />
                        </TabPane>
                        <TabPane
                            tab="Errors"
                            key="errors"
                        >
                            <Errors
                                {...props}
                                formId={"errors"} />
                        </TabPane>
                    </Tabs>
                </Card>
            </div>
        </>
    );
};

export default Quickbook;
