import { Empty } from "antd";
import Select, { DefaultOptionType, SelectProps } from "antd/lib/select";
import Spin from "antd/lib/spin";
import debounce from "lodash/debounce";
import { useEffect, useMemo, useRef, useState } from "react";

// Will be used for searching and fetching capability of large data

export interface AsyncSearchSelectProps extends SelectProps<any> {
  searchInterval?: number;
  fetchOptions?: (
    search: any,
    includes?: number[] | number | string | string[]
  ) => Promise<any>;
  onSearchOptions?: (value) => Promise<any>;
  onSelectionChange?: (value: any) => void;
}

export const AsyncSelect = (props: AsyncSearchSelectProps) => {
  const {
    searchInterval,
    fetchOptions,
    onSearchOptions,
    onSelectionChange,
    // value,
    ...selectProps
  } = props;
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<DefaultOptionType[]>([]);
  const [selectedItem, setSelectedItem] = useState(
    props.mode === "multiple" ? [] : null
  );
  const fetchRef = useRef(0);
  const tempListData = useRef<DefaultOptionType[]>([]);

  useEffect(() => {
    let active = true;
    load();
    return () => {
      active = false;
    };

    async function load() {
      if (!fetchOptions) {
        return;
      }
      setLoading(true);
      const result = await fetchOptions(props.value);
      if (!active) {
        return;
      }
      setOptions(result);
      tempListData.current = result;
      setValueOptions(result);
      setLoading(false);
    }
  }, [props.value]);

  useMemo(() => {
    setValue();
  }, [props?.options, props?.value]);

  const debounceSearchGet = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (props.onSearchOptions) {
        setOptions([]);
        setLoading(true);
        onSearchOptions(value).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return;
          }
          setOptions(newOptions);
          setLoading(false);
        });
      } else {
        if ((value ?? "") !== "") {
          let cpy = [...tempListData.current];
          let item = cpy.filter((a) =>
            a.label.toString().toLowerCase().includes(value.toLowerCase())
          );
          setOptions(item);
        } else {
          setOptions(tempListData.current);
        }
      }
    };

    return debounce(loadOptions, searchInterval);
  }, [onSearchOptions, searchInterval]);

  function setValueOptions(value) {
    if ((value ?? "") === "") {
      return;
    }
    if (props.mode === "multiple") {
      let values: any[] = [];
      values = value?.filter((item) => {
        let refinedItem = Number.isNaN(Number(item?.value))
          ? item?.value
          : Number(item?.value);

        if (props.value?.includes(refinedItem)) {
          return item;
        }
      });
      setSelectedItem(values);
    } else {
      let item: any;
      item = value?.find((item) => item.value === props.value?.toString());
      setSelectedItem(item);
    }
  }

  function setValue() {
    if (props.mode === "multiple") {
      let values: any[] = [];
      let propsValueString = props?.value?.map((item) => item?.toString());
      console.log("Props value: ", propsValueString);
      values = (props?.options ?? options).filter((item) => {
        if (propsValueString?.includes(item?.value?.toString())) {
          return item;
        }
      });
      console.log("Filtered: ", values);
      setSelectedItem(values);
    } else {
      let value: any = options?.find(
        (item) => item?.value?.toString() === props.value?.toString()
      );

      setSelectedItem(value);
    }
  }

  const onChangeValue = (value: any) => {
    setSelectedItem(value);
    if (onSelectionChange) {
      onSelectionChange(value);
    }
    if (!props.onChange) {
      return;
    }
    if (props.mode === "multiple") {
      let rawValues: any[] = value?.map((item) => {
        return Number.isNaN(Number(item?.value))
          ? item?.value
          : Number(item?.value);
      });
      props.onChange(rawValues, null);
    } else {
      let raw = Number.isNaN(Number(value?.value))
        ? value?.value
        : Number(value?.value);
      props.onChange(raw, null);
    }
  };

  // if (selectProps.showSearch) {
  return (
    <Select
      filterOption={false}
      onSearch={props.showSearch ? debounceSearchGet : null}
      notFoundContent={
        loading ? (
          <Spin size="small" />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      loading={loading}
      // onFocus={getOptions}
      getPopupContainer={() =>
        document.getElementById("root-scrollable-element")
      }
      options={options}
      {...selectProps}
      onChange={onChangeValue}
      value={selectedItem}
      labelInValue={true}
    />
  );
  // } else {
  //   return (
  //     <Select
  //       filterOption={false}
  //       showSearch
  //       onSearch={debounceSearchGet}
  //       notFoundContent={loading ? <Spin size="small" /> : <div>No data</div>}
  //       loading={loading}
  //       // onFocus={getOptions}
  //       getPopupContainer={() =>
  //         document.getElementById("root-scrollable-element")
  //       }
  //       {...selectProps}
  //       options={options}
  //       value={selectedItem}
  //       labelInValue={true}
  //       onChange={onChangeValue}
  //     />
  //   );
  // }
};
