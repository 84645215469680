import { notification } from "antd";
import { handleQuickbookConnection } from "src/pages/QuickBook/Edit/Quickbook";
import { setToken } from "./service";
import { dispatch } from "src";
import { quickbookActionType } from "src/@redux/quickbook/quickbookAction";

interface AuthenticatedUser {
    token?: any;
}
interface ApiResponse<T> {
    success: boolean;
    message: string;
    data: T | null;
    errorType?: string;
    errorMessage?: string;
    authenticatedUser?: AuthenticatedUser;
    isQuickbookTokenExpired: boolean;
}

export const handleApiResponse = <T>(response: ApiResponse<T>) => {
    console.log(response);

    if (response?.message?.includes("re-authenticate")) {
        handleQuickbookConnection();
    }

    if (!response?.errorMessage && response?.message) {
        notification.success({
            message: response?.message,
        });
    }
    if (response?.errorMessage && response?.message) {
        notification.error({
            message: response?.message ?? "An unexpected error occured",
        });
    }
    if (response?.errorMessage && !response?.message) {
        notification.error({
            message: response?.errorMessage ?? "An unexpected error occured",
        });
    }

    if (response?.authenticatedUser && response?.authenticatedUser?.token) {
        console.log(response?.authenticatedUser?.token);
        setToken(response?.authenticatedUser?.token);
    }
    dispatch({
        type: quickbookActionType.UPDATE_QUICKBOOK_CONNECTION,
        payload: response?.isQuickbookTokenExpired,
    });

    return response?.data;
};
