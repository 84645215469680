import { Button, Tabs } from "antd";
import { useState } from "react";
import Customers from "./Customers";
import Invoices from "./Invoices";
import CreditNotes from "./CreditNotes";
import TaxRates from "./TaxRates";
import ChartOfAccounts from "./ChartOfAccounts";
import Accounts from "./Accounts";
import Settings from "./Settings";
import { useAppSelector } from "src/@redux/hook.type";

const { TabPane } = Tabs;
const Admin = (props) => {
    const [transactionTabKey, setTransactionTabKey] = useState("taxRates");
    const isSaving = useAppSelector((state) => state.common.isSaving);
    const setTransactionCurrentTab = (key) => {
        setTransactionTabKey(key);
    };
    return (
        <>
            <Tabs
                defaultActiveKey="taxRates"
                type="line"
                onChange={setTransactionCurrentTab}
            >
                <TabPane
                    tab="Tax Rates"
                    key="taxRates"
                >
                    <TaxRates
                        formId={"taxRates"}
                    />
                </TabPane>
                <TabPane tab="Chart Of Accounts" key="chartOfAccounts">
                    <ChartOfAccounts
                        formId={"chartOfAccounts"}
                    />
                </TabPane>
                <TabPane tab="Accounts" key="accounts" >
                    <Accounts
                        formId={"accounts"}
                    />
                </TabPane>
                <TabPane tab="Settings" key="settings" >
                    <Settings
                        formId={"settings"}
                    />
                </TabPane>
            </Tabs>
            {transactionTabKey === "settings" && (
                <div className="action-panel top">
                    <Button
                        type="primary"
                        htmlType="submit"
                        form={transactionTabKey}
                        loading={isSaving}
                        key="quickbook_save_bottom"
                    >
                        Save
                    </Button>
                </div>
            )}
        </>
    );
};

export default Admin;
