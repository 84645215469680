import { ColumnsType } from "antd/lib/table";
import { filterOptions } from "src/common/constants";
import { FilterOptionProps } from "src/microComponent/CompliantTable/filterAntD";

import {
    ActionConfigType,
    ActionGenerator,
    formatDate,
} from "src/microComponent/component.service";

export function getInvoiceFilterOptions() {
    const filterOption: FilterOptionProps[] = [
        {
            propertyName: "Company",
            placeholder: "Company",
            inputType: "text",
            typeOptions: [filterOptions.contains],
        },
        {
            propertyName: "Referrence",
            placeholder: "Referrence No",
            inputType: "text",
            typeOptions: [filterOptions.contains],
        }
    ];
    return filterOption;
}

export function getInvoiceTableColumns(props, path: string) {
    const columns: ColumnsType<any> = [
        {
            title: "Company",
            dataIndex: "company",
            key: "company",
            sorter: true,
        },
        {
            title: "Transaction Type",
            dataIndex: "transactionType",
            key: "transactionType",
            sorter: true,
        },
        {
            title: "Referrence",
            dataIndex: "referrence",
            key: "referrence",
            sorter: true,
            render: (text, record) => (
                <a
                    onClick={() => {
                        props.history.push(`${path}/${record.id}`);
                    }}
                >
                    {text}
                </a>
            )
        },
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            render: (text, record) => {
                if (record?.date) {
                    return formatDate(record?.date);
                }
                else {
                    return text;
                }
            }
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            sorter: true,
        },
        {
            title: "Total",
            dataIndex: "total",
            key: "total",
            sorter: true,
        }
    ];
    return columns;
}
