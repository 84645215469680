import { localStorageKeys } from "../../common/constants";
import { User } from "../../models/user";
import { createReducer } from "@reduxjs/toolkit";
import {
  loginUserSuccess,
  logoutPage,
  logoutUserSuccess,
} from "./accountActions";

export interface IUserState {
  user: User;
  isLoading: boolean;
  isLoggingOut: boolean;
  isLogoutPage?: boolean;
  isTokenExpired?: boolean;
}

const initialState: IUserState = {
  user:
    localStorage.getItem(localStorageKeys.user) !== "undefined" &&
    localStorage.getItem(localStorageKeys.user)
      ? JSON.parse(localStorage.getItem(localStorageKeys.user))
      : {},
  isLoading: false,
  isLoggingOut: false,
  isTokenExpired: false,
};

export const accountReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loginUserSuccess, (state, action) => {
      state = {
        ...state,
        user: action.payload?.data,
      };
      return state;
    })
    .addCase(logoutUserSuccess, (state, actions) => {
      state = {
        ...state,
        user: undefined,
      };
      return state;
    })

    .addCase(logoutPage, (state, action) => {
      state = {
        ...state,
        isLogoutPage: action.payload,
      };
      return state;
    });
});

// export const reducer = (state: any = initialState, action: any): IUserState => {
//   const { type, payload } = action;

//   switch (type) {
//     case accountActionType.logoutStart:
//       return { ...state, isLoggingOut: true };
//     case accountActionType.logout:
//       return { ...state, user: {}, isLoggingOut: false };
//     case accountActionType.login:
//       return { ...state, user: payload };

//     case accountActionType.alterOrg:
//       return {
//         ...state,
//         isLoading: false,
//         user: {
//           ...state.user,
//         },
//       };
//     case accountActionType.loading:
//       return { ...state, isLoading: true };

//     case accountActionType.auth:
//       return { ...state, user: payload };
//     case accountActionType.refreshUser:
//       return { ...state, user: payload };
//     default:
//       return state;
//   }
// };
