import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import exceptionDataaccess from "src/dataAccess/exceptions/exception.dataaccess";
import { ExceptionCountModel } from "src/Layout/props.interface";
import {
  ExceptionConfig,
  ExceptionProcessConfig,
  ExceptionSetting,
} from "src/models/exception-process.model";
import { ExceptionCount, ExceptionCountMap } from "src/models/exception.model";
import { IListType } from "../store";

export interface ExceptionListType extends IListType {
  status?: string;
}

export const getNCList = createAsyncThunk<any, ExceptionListType>(
  "[Exception] Get exception list",
  async (parameter, thunkAPI) => {
    const result = await exceptionDataaccess
      .getNCList({
        pageNo: parameter.pageNo,
        pageSize: parameter.pageSize,
        filters: parameter.filters,
        sortData: parameter.sortData,
        status: parameter.status,
      })
      .then((res) => {
        return res;
      });
    return result;
  }
);
export const getCautionList = createAsyncThunk<any, ExceptionListType>(
  "[Exception] Get caution list",
  async (parameter, thunkAPI) => {
    const result = await exceptionDataaccess
      .getCautionList({
        pageNo: parameter.pageNo,
        pageSize: parameter.pageSize,
        filters: parameter.filters,
        sortData: parameter.sortData,
        status: parameter.status,
      })
      .then((res) => {
        return res;
      });
    return result;
  }
);

export interface NcStatus {
  id: number;
  status: "Outstanding" | "Resolved" | "Pending";
  resolvedByDate?: any;
}

export const UpdateCurrentNCStatus = createAction<NcStatus>(
  "[NC] update current nc status"
);

export const SetExceptionProcessConfig = createAction<ExceptionProcessConfig>(
  "[Exception Process] Set process config"
);

export const UpdateExceptionProcessConfig =
  createAction<ExceptionProcessConfig>(
    "[Exception Process] update process config"
  );

export const SetExceptionSettings = createAction<ExceptionSetting>(
  "[Exceptin] Set Exception settings"
);

export const SetExceptionConfig = createAction<ExceptionConfig>(
  "[Exception] Set Exception Config"
);

export const GetExceptionCautionCountAsync = createAsyncThunk<any>(
  "[Exception] Get Exception count",
  async () => {
    const result: ExceptionCountModel = await exceptionDataaccess
      .getExceptionCount()
      .then((res) => res?.data);

    let payload: ExceptionCountMap = {
      exception: {
        outstanding: result?.oustandingExceptionCount,
        overdue: result?.overdueExceptionCount,
        pending: result?.pendingExceptionCount,
        resolved: result?.resolvedExceptionCount,
      },
      caution: {
        outstanding: result?.outstandingCautionCount,
        pending: result?.pendingCautionCount,
        resolved: result?.resolvedCautionCount,
        all: result?.allCautionCount,
      },
    };

    return payload;
  }
);

export const SetExceptionName = createAction<string>(
  "[Exception] Set exception name"
);

export const SetNCExceptionCount = createAction<Partial<ExceptionCount>>(
  "[Exception] set exception count"
);
export const SetCautionCount = createAction<Partial<ExceptionCount>>(
  "[Caution] set exception count"
);
