import { IFilter } from "src/@redux/listRedux";
import commonDataAccess from "../commonDataAccess";

class NonConformaceDataAccess {
  getAssignUsers(ncId: any) {
    return commonDataAccess.getData("correctiveAction/get/assignUsers", {
      ncId: ncId,
    });
  }
  saveAssignUsers(payload: any[], ncId: number) {
    return commonDataAccess.postData(
      "correctiveAction/save/assignUsers",
      payload,
      {
        ncId: ncId,
      }
    );
  }
  getUserToSendEmail(ncId: any) {
    return commonDataAccess.getData("correctiveAction/get/usertosendEmail", {
      ncId: ncId,
    });
  }
  saveUserToSendEmail(ncId: any, userIds: any) {
    console.log(ncId);
    return commonDataAccess.postData(
      "correctiveAction/save/usertosendEmail",
      null,
      {
        ncId: ncId,
        userIds: userIds,
      }
    );
  }
  getNonConformanceDropdown = (
    isCorrectiveAction: boolean,
    includeTabletUserSelect: boolean = false
  ) => {
    return commonDataAccess.getData("correctiveAction/get/dropdownList", {
      isCorrectiveAction: isCorrectiveAction,
      includeTabletUserSelect: includeTabletUserSelect,
    });
  };
  getSubNCDropdownByNC = (ncid: any) => {
    return commonDataAccess.getData(
      `correctiveAction/get/subNcdropdownbyNC/${ncid}`
    );
  };
  getNCList = (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };

    return commonDataAccess.getData("correctiveaction/getall", parameters);
  };
  getBasicInfo = (id: number) => {
    return commonDataAccess.getData("correctiveAction/get/basicinfo", {
      id: id,
    });
  };
  getSubNcs = (id: number) => {
    return commonDataAccess.getData("correctiveAction/get/subncs", {
      id: id,
    });
  };
  saveBasicInfo = (payload: any) => {
    return commonDataAccess.postData(
      "correctiveAction/save/basicinfo",
      payload
    );
  };
  saveSubNCs = (payload: any, ncId: any) => {
    return commonDataAccess.postData("correctiveAction/save/subncs", payload, {
      ncId: ncId,
    });
  };
  deleteSubNc = (id: number) => {
    return commonDataAccess.postData("correctiveAction/delete/subnc", null, {
      id: id,
    });
  };
  getNcItems = (id: number) => {
    return commonDataAccess.getData("correctiveAction/get/ncitems", {
      id: id,
    });
  };
  saveNcItems = (payload: any, ncId: any) => {
    return commonDataAccess.postData("correctiveAction/save/ncitems", payload, {
      ncId: ncId,
    });
  };
  deleteNcItem = (id: number) => {
    return commonDataAccess.postData("correctiveAction/delete/ncitem", null, {
      id: id,
    });
  };
  deleteNcById = (ids: string) => {
    console.log(ids);
    return commonDataAccess.deleteData("correctiveAction/delete/nc", {
      ids: ids,
    });
  };

  saveNcItemSequence = (
    ncId: number,
    nCItemId: number,
    prevPos: number,
    currentPos: number
  ) => {
    return commonDataAccess.postData(
      "correctiveAction/update/NCItemSequenceposition",
      null,
      {
        ncId: ncId,
        nCItemId: nCItemId,
        previousSeqNo: prevPos,
        currentSequenceNo: currentPos,
      }
    );
  };

  getSiteItems = (id: number) => {
    return commonDataAccess.getData("correctiveAction/get/siteitems", {
      id: id,
    });
  };

  saveSiteItems = (payload: any, ncId: any) => {
    return commonDataAccess.postData(
      "correctiveAction/save/siteitems",
      payload,
      {
        ncId: ncId,
      }
    );
  };
}

export default new NonConformaceDataAccess();
