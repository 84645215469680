import { createReducer } from "@reduxjs/toolkit";
import { getAssociatedProceduresSuccess, getLocationBasicInfoSuccess, getLocationCommentSuccess, getLocationDatesSuccess, getLocationDropDownSuccess, getLocationSupplierSuccess, loadingData, saveLocationBasicInfoSuccess, saveLocationCommentSuccess, saveLocationDatesSuccess, saveLocationSupplierSuccess } from "./locationAction";

export interface ILocationState {
  locationDropdown?: any[];
  isLoading?: boolean;
  locationBasicInfo?: any;
  locationDates?: any;
  locationSupplier?: any;
  comments?: any[];
  associatedProcedures?: any[];
}

const initialState: ILocationState = {};

export const locationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loadingData, (state, action) => {
      state = {
        ...state,
        isLoading: action.payload,
      };
      return state;
    })
    .addCase(getLocationDropDownSuccess, (state, action) => {
      state = {
        ...state,
        locationDropdown: action.payload,
      };
      return state;
    })
    .addCase(getLocationBasicInfoSuccess, (state, action) => {
      state = {
        ...state,
        locationBasicInfo: action.payload,
      };
      return state;
    })
    .addCase(saveLocationBasicInfoSuccess, (state, action) => {
      state = {
        ...state,
        locationBasicInfo: action.payload,
      };
      return state;
    })
    .addCase(getLocationDatesSuccess, (state, action) => {
      state = {
        ...state,
        locationDates: action.payload,
      };
      return state;
    })
    .addCase(saveLocationDatesSuccess, (state, action) => {
      state = {
        ...state,
        locationDates: action.payload,
      };
      return state;
    })
    .addCase(getLocationSupplierSuccess, (state, action) => {
      state = {
        ...state,
        locationSupplier: action.payload,
      };
      return state;
    })
    .addCase(saveLocationSupplierSuccess, (state, action) => {
      state = {
        ...state,
        locationSupplier: action.payload,
      };
      return state;
    })
    .addCase(getLocationCommentSuccess, (state, action) => {
      state = {
        ...state,
        comments: action.payload,
      };
      return state;
    })
    .addCase(saveLocationCommentSuccess, (state, action) => {
      state = {
        ...state,
        comments: action.payload,
      };
      return state;
    })
    .addCase(getAssociatedProceduresSuccess, (state, action) => {
      state = {
        ...state,
        associatedProcedures: action.payload,
      };
      return state;
    })
});
