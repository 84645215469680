import { Action } from "redux";
import { Dispatch } from "@reduxjs/toolkit";
import quickbookDataAccess from "src/dataAccess/quickbook/quickbookDataAccess";
import { handleApiResponse } from "src/common/exception";
import { notification } from "antd";

export enum quickbookActionType {
	LOAD_COMPANY = "[Quickbook] Loading company",
	LOAD_INVOICE = "[Quickbook] Loading invoice",
	LOAD_CHART_OF_ACCOUNT = "[Quickbook] Loading chart of accounts",
	LOAD_TAX_RATE = "[Quickbook] Loading tax rate",
	LOAD_ERROR = "[Quickbook] Loading error",
	UPDATE_TAB_NAME = "Update Tab Name",
	GET_COMPANY_LIST_SUCCESS = "[Quickbook] Get Company list success",
	GET_INVOICE_LIST_SUCCESS = "[Quickbook] Get Invoice list success",
	GET_CHART_OF_ACCOUNT_LIST_SUCCESS = "[Quickbook] Get chart of account list success",
	GET_TAX_RATE_LIST_SUCCESS = "[Quickbook] Get tax rate list success",
	GET_ERROR_LIST_SUCCESS = "[Quickbook] Get Error list success",
	UPDATE_QUICKBOOK_CONNECTION = "[Quickbook] Update Quickbook connection",
	UPDATE_QUICKBOOK_INVOICES_ROW_SELECTIONS = "[Quickbook] Update Quickbook Invoices Row Selection"
}

interface QuickbookAction extends Action {
	payload: any;
}

export const actions = {
	getAllCompanyList:
		(pageNo = 1, pageSize = 10, sortData = "", filters = []) =>
			(dispatch: Dispatch, getState) => {
				dispatch({ type: quickbookActionType.LOAD_COMPANY, payload: true });
				quickbookDataAccess
					.companyList({ pageNo, pageSize, sortData, filters })
					.then((res) => {
						console.log(res);
						dispatch({
							type: quickbookActionType.GET_COMPANY_LIST_SUCCESS,
							payload: res,
						});
						dispatch({ type: quickbookActionType.LOAD_COMPANY, payload: false });
					});
			},
	getAllInvoiceList:
		(pageNo = 1, pageSize = 10, sortData = "", filters = []) =>
			(dispatch: Dispatch, getState) => {
				dispatch({ type: quickbookActionType.LOAD_INVOICE, payload: true });
				quickbookDataAccess
					.invoiceList({ pageNo, pageSize, sortData, filters })
					.then((res) => {
						console.log(res);
						dispatch({
							type: quickbookActionType.GET_INVOICE_LIST_SUCCESS,
							payload: res,
						});
						dispatch({ type: quickbookActionType.LOAD_INVOICE, payload: false });
					});
			},
	getAllChartOfAccountList:
		(pageNo = 1, pageSize = 10, sortData = "", filters = []) =>
			(dispatch: Dispatch, getState) => {
				dispatch({ type: quickbookActionType.LOAD_CHART_OF_ACCOUNT, payload: true });
				quickbookDataAccess
					.chartOfAccountList({ pageNo, pageSize, sortData, filters })
					.then((res) => {
						console.log(res);
						const data = handleApiResponse(res);
						if (data) {
							dispatch({
								type: quickbookActionType.GET_CHART_OF_ACCOUNT_LIST_SUCCESS,
								payload: res,
							});
							dispatch({ type: quickbookActionType.LOAD_CHART_OF_ACCOUNT, payload: false });
						}
					});
			},

	getAllTaxRatesList:
		(pageNo = 1, pageSize = 10, sortData = "", filters = []) =>
			(dispatch: Dispatch, getState) => {
				dispatch({ type: quickbookActionType.LOAD_TAX_RATE, payload: true });

				quickbookDataAccess
					.taxRateList({ pageNo, pageSize, sortData, filters })
					.then((res) => {
						const data = handleApiResponse(res);
						console.log(data);
						if (data) {
							dispatch({
								type: quickbookActionType.GET_TAX_RATE_LIST_SUCCESS,
								payload: res,
							});
							dispatch({ type: quickbookActionType.LOAD_TAX_RATE, payload: false });
						}
					})
			},
	getAllErrorList:
		(pageNo = 1, pageSize = 10, sortData = "", filters = []) =>
			(dispatch: Dispatch, getState) => {
				dispatch({ type: quickbookActionType.LOAD_ERROR, payload: true });
				quickbookDataAccess
					.errorList({ pageNo, pageSize, sortData, filters })
					.then((res) => {
						console.log(res);
						dispatch({
							type: quickbookActionType.GET_ERROR_LIST_SUCCESS,
							payload: res,
						});
						dispatch({ type: quickbookActionType.LOAD_ERROR, payload: false });
					});
			},
	updateTabNameState: (res) => (dispatch: Dispatch, getState) => {
		dispatch({
			type: quickbookActionType.UPDATE_TAB_NAME,
			payload: res,
		});
	},
};

export type ActionType = QuickbookAction;
