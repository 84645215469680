import { useAppDispatch, useAppSelector } from "src/@redux/hook.type";
import {
    actions as quickbookAction,
    quickbookActionType,
} from "src/@redux/quickbook/quickbookAction";
import { actions as listAction, IFilter } from "src/@redux/listRedux";

import { useEffect, useRef, useState } from "react";

import Filter from "src/microComponent/CompliantTable/filterAntD";
import "./Customers.less";
import { Button, Modal, notification, Spin, Tooltip } from "antd";
import { CommonPageHeader } from "src/microComponent/PageHeader/CommonPageHeader";

import { CompliantTable } from "src/microComponent/CompliantTable/CompliantTable";
import { Link, useLocation } from "react-router-dom";
import { getCustomerFilterOptions, getCustomerTableColumns } from "./customers-column.config";


const Customers = (props) => {
    const quickbookState = useAppSelector((state) => state.quickbook);
    const listState = useAppSelector((state) => state.listState);
    const dispatch = useAppDispatch();
    const [selectedRowCount, setSelectedRowCount] = useState(0);
    const [selectedIds, setSelectedIds] = useState("");

    const routePath = useLocation();
    let pageContextFilter = useRef<any>();
    let pageName = useRef<string>("");

    useEffect(() => {
        filterArray = filterArray.map((item) => {
            item["value"] = "";
            return item;
        });
        dispatch(listAction.initializeFilter(filterArray));

        listState.sort = "";
        dispatch(listAction.updateSortString(listState.sort));
        console.log("CustomerUseEffect");
        dispatch(
            quickbookAction.getAllCompanyList(
                listState.pageNo,
                listState.pageSize,
                listState.sort,
                listState.filters
            )
        );
        return () => {
            dispatch(listAction.updateSortString(""));
        };
    }, []);

    let filterArray = getCustomerFilterOptions();

    const onPageChange = (page, pageSize) => {
        dispatch(
            quickbookAction.getAllCompanyList(
                page,
                pageSize,
                listState.sort,
                listState.filters
            )
        );
        dispatch(listAction.updatePageNo(page));
    };
    const onPageSizeChange = (current, size) => {
        dispatch(listAction.updatePageSize(size));
        dispatch(
            quickbookAction.getAllCompanyList(
                current,
                size,
                listState.sort,
                listState.filters
            )
        );
    };

    let columns = getCustomerTableColumns();
    const onSortChange = (sortValue) => {
        dispatch(
            quickbookAction.getAllCompanyList(
                listState.pageNo,
                listState.pageSize,
                sortValue,
                listState.filters
            )
        );
    };
    return (
        <>
            <CommonPageHeader

            />
            <div className="page-body-content">
                <div className="page-body-inner">
                    <Filter
                        filterOptions={filterArray}
                        onFilterChange={(pageNo, pageSize, filters: any[], sortData) => {
                            console.log(pageNo);
                            dispatch(
                                quickbookAction.getAllCompanyList(
                                    pageNo,
                                    pageSize,
                                    sortData,
                                    filters
                                )
                            );
                        }}
                        listState={listState}
                    />
                    <CompliantTable
                        rowKey={(record) => record.id}
                        // rowSelection={{
                        //     selectedRowKeys: selectedIds?.split(",").map(Number),
                        //     onChange: (selectedRowKeys) => {
                        //         setSelectedRowCount(selectedRowKeys.length);
                        //         setSelectedIds(selectedRowKeys.map((item) => item).join(","));
                        //     }
                        // }}
                        dataSource={quickbookState.companies}
                        loading={quickbookState.isLoading}
                        columns={columns}
                        size="small"
                        onSortChange={onSortChange}
                        pagination={{
                            total: quickbookState.customerDataCount,
                            defaultPageSize: 10,
                            current: listState.pageNo,
                            pageSizeOptions: ["10", "20"],
                            onChange: onPageChange,
                            onShowSizeChange: onPageSizeChange,
                        }}
                    />
                </div>
            </div>
        </>
    );
};
export default Customers;
