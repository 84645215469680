import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import auditChangeLogDataaccess from "src/dataAccess/auditChangeLog/auditChangeLog.dataaccess";
import { IListType } from "../store";

export const LoadingAuditData = createAction<boolean>(
  "[Audit Change Log] Loading Data"
);

export const GetAuditChangeLogListSuccess = createAction<any>(
  "[Audit Change Log] Get Audit change log list success"
);

export const GetAuditChangeLog = createAsyncThunk<any, IListType>(
  "[Audit Change Log] Get Audit change log",
  async (parameters, thunkAPI) => {
    thunkAPI.dispatch(LoadingAuditData(true));
    const result = await auditChangeLogDataaccess
      .getAuditChangeLogList(parameters)
      .then((res) => {
        thunkAPI.dispatch(GetAuditChangeLogListSuccess(res));
        thunkAPI.dispatch(LoadingAuditData(false));
        return res;
      });
    return result;
  }
);

export const UpdateAudtiChangeLog = createAction<any>(
  "[Audit Change Log] Update Audit change log"
);

export const DeleteAuditChangeByIdSuccess = createAction<string>(
  "[Audit Change Log] Delete Audit log by id Success"
);
