import commonDataAccess from "../commonDataAccess";
import { IFilter } from "src/@redux/listRedux";

class CheckListDataAccess {
  getCheckList = (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };
    return commonDataAccess.getData("checkList/getAll", parameters);
  };
  getCheckListDropdown = () => {
    return commonDataAccess.getData("checkList/get/dropdownList");
  };
  getChecklistInfo = (checklistId: any) => {
    return commonDataAccess.getData(
      `checkList/get/checklistinfo/${checklistId}`
    );
  };
  saveChecklistInfo = (payload: any) => {
    return commonDataAccess.postData("checkList/save/checklistinfo", payload);
  };
  getChecklistDetailList = (checklistId: any) => {
    return commonDataAccess.getData(
      `checkList/get/checklistDetailList/${checklistId}`
    );
  };
  saveChecklistDetail = (checklistId: any, payload: any) => {
    return commonDataAccess.postData(
      "checkList/save/checklistDetailList",
      payload,
      {
        checklistId: checklistId,
      }
    );
  };
  saveChecklistDetailItem = (checklistId: any, payload: any) => {
    return commonDataAccess.postData(
      "checkList/save/checklistDetailListItem",
      payload,
      {
        checklistId: checklistId,
      }
    );
  };
  saveChecklistSequence = (checklistId, itemId, oldPosition, newPosition) => {
    return commonDataAccess.getData("checkList/save/sequenceposition", {
      checklistId: checklistId,
      itemId: itemId,
      previousSeqNo: oldPosition,
      currentSequenceNo: newPosition,
    });
  };
  deleteChecklist = (checklistId: any) => {
    return commonDataAccess.deleteData(`checkList/delete/${checklistId}`);
  };
  deleteChecklists = (checklistIds: any) => {
    return commonDataAccess.deleteData(`checkList/delete`, {
      ids: checklistIds,
    });
  };
  deleteChecklistItem = (id: any) => {
    return commonDataAccess.deleteData(`checkList/delete/checklistItem/${id}`);
  };
  copy = (id: any) => {
    return commonDataAccess.getData(`checkList/copy`, {
      id: id,
    });
  };
}

export default new CheckListDataAccess();
