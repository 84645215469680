import commonDataAccess from "../commonDataAccess";

export class ConfigurableTypeDataAccess {
  saveConfigurableType = (payload: any, classType: "COUTY" | "MUNIT" | "PODRS") => {
    return commonDataAccess.postData(
      `configurableTypes/save/${classType}`,
      payload
    );
  };
  deleteConfigurableType = (id: any, productId: any) => {
    return commonDataAccess.deleteData("configurableTypes/delete", {
      id: id,
      productId: productId,
    });
  };

  getUnitConversionDropdown = (uomId) => {
    return commonDataAccess.getData(
      "configurableTypes/get/unitconversionsForDropdown",
      { uomId: uomId }
    );
  };
  getSelectListItem = (classType: "COUTY" | "MUNIT") => {
    return commonDataAccess.getData("configurableTypes/getall", {
      type: classType,
    });
  };
  getUOMListItemByProductId = (productId: any) => {
    return commonDataAccess.getData("configurableTypes/getallUOMByProduct", {
      productId: productId,
    });
  };
  getPurchaseOrderDeletionReasons = () => {
    return commonDataAccess.getData("configurableTypes/get/purchase-order-deletion-reason");
  };
  savePurchaseOrderDeletionReasons = (payload: any,) => {
    return commonDataAccess.postData("configurableTypes/save/purchase-order-deletion-reason", payload);
  };
  DeletePurchaseOrderDeletionReason = (id: any) => {
    return commonDataAccess.deleteData("configurableTypes/delete/purchase-order-deletion-reason", { id });
  };
}

export default new ConfigurableTypeDataAccess();
