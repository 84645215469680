import React, { memo } from "react";

const CompliantModelContent = (props: any) => {
  return (
    <div className="wrapper-body">
      {React.isValidElement(props?.bodyContext)
        ? React.cloneElement(props?.bodyContext, props?.componentProps)
        : props?.bodyContext}
    </div>
  );
};

export default memo(CompliantModelContent);
