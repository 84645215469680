import Table, { TablePaginationConfig, TableProps } from "antd/lib/table";
// import {  useRef } from "react";
import { useAppDispatch } from "src/@redux/hook.type";
import { actions as listAction } from "src/@redux/listRedux";

import "./CompliantTable.less";
// import { CompliantTableResizer } from "./CompliantTableResizer";

export interface CompliantTableProps extends TableProps<any> {
  onSortChange?: Function;
  sortable?: boolean;
}

const DEFAULT_PAGINATION: TablePaginationConfig | boolean = {
  pageSizeOptions: ["25", "50"],
};

export const CompliantTable = (props: CompliantTableProps) => {
  const dispatch = useAppDispatch();
  let { onSortChange, sortable, pagination, dataSource, ...tableConfig } =
    props;

  let paginationConfigProp: boolean | TablePaginationConfig =
    pagination === false
      ? false
      : {
        className: "pagination-body",
        showTotal: (total, range) => "Total Records: " + total,
        ...DEFAULT_PAGINATION,
        ...pagination,
      };

  // let tableRef = useRef<HTMLDivElement>();

  // let cellsRef = useRef<HTMLCollectionOf<HTMLTableCellElement>>();

  let table = {
    ...tableConfig,
    onChange: (pagination, filters, sorter, extra) => {
      console.log(sorter);
      if (sorter.order) {
        var sortDirection = "asc";
        if (sorter.order === "descend") {
          sortDirection = "desc";
        } else if (sorter.order === "ascend") {
          sortDirection = "asc";
        }
        const sortValue = sorter.field + "-" + sortDirection;
        dispatch(listAction.updateSortString(sortValue));
        if (onSortChange) {
          onSortChange(sortValue);
        }
      } else {
        dispatch(listAction.updateSortString(""));
      }
    },
  };

  return (
    <>
      {/* <CompliantTableResizer
        tableColumns={tableColumn ?? []}
        obserVableContainerRef={tableRef.current}
        tableDataSource={props.dataSource}
        tableContainerRef={tableRef}
      > */}

      <Table
        bordered
        rowKey={(record) => record.id}
        pagination={paginationConfigProp}
        {...table}
        dataSource={dataSource}
      // columns={tableColumn}
      />
      {/* </CompliantTableResizer> */}
    </>
  );
};
