import commonDataAccess from "../commonDataAccess";

class CaProfileDataAccess {
  getCAProfileByCaId = (id: number) => {
    return commonDataAccess.getData("cAProfile/get/bycaid", {
      caId: id,
    });
  };
  getCaProfileWithCaItemsById = (caProfileId: any, ncId: any) => {
    return commonDataAccess.getData("cAProfile/get", {
      caProfileId: caProfileId,
      caId: ncId,
    });
  };
  saveCaProfile = (payload: any) => {
    return commonDataAccess.postData("cAProfile/save", payload);
  };
  deleteCaProfileById = (caProfileId: any) => {
    return commonDataAccess.deleteData(`cAProfile/delete/${caProfileId}`);
  };
}

export default new CaProfileDataAccess();
