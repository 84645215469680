import { Alert, Tabs } from "antd";
import { useState } from "react";
import Customers from "./Customers";
import Invoices from "./Invoices";
import CreditNotes from "./CreditNotes";

const { TabPane } = Tabs;
const Sales = (props) => {
    const [transactionTabKey, setTransactionTabKey] = useState("Customers");
    const setTransactionCurrentTab = (key) => {
        setTransactionTabKey(key);
    };
    return (
        <>
            <Tabs
                defaultActiveKey="customers"
                type="line"
                onChange={setTransactionCurrentTab}
            >
                <TabPane
                    tab="Customers"
                    key="customers"
                >
                    <Customers
                        {...props}
                        formId={"customers"}
                        tab={transactionTabKey}
                    />
                </TabPane>
                <TabPane tab="Invoices" key="invoices">
                    <Invoices
                        {...props}
                        formId={"invoices"}
                        tab={transactionTabKey}
                    />
                </TabPane>
                {/* <TabPane tab="Credit Notes" key="creditNotes" >
                    <CreditNotes
                        {...props}
                        formId={"creditNotes"}
                    />
                </TabPane> */}
            </Tabs>
        </>
    );
};

export default Sales;
