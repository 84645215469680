import commonDataAccess from "../commonDataAccess";

//import { IFilter } from "../../@redux/listRedux";
// class settingDataAccess {
//   getIsRequireIimageOrSignatureToPass = () => {
//     return commonDataAccess.getData(
//       "settings/get/isrequireimageorsignaturetopass"
//     );
//   };
// }
// export default new settingDataAccess();

class SettingsDataAccess {
  getIsRequireIimageOrSignatureToPass = () => {
    return commonDataAccess.getData(
      "settings/get/isrequireimageorsignaturetopass"
    );
  }
  getTrainingSetting = () => {
    return commonDataAccess.getData("settings/get/trainingSettings");
  }
  getComplaintSetting = () => {
    return commonDataAccess.getData("settings/get/complaintSettings");
  }
  getNoOfUnitSettings = () => {
    return commonDataAccess.getData("settings/get/noOfUnitSetting");
  }
  getExceptionSettings = () => {
    return commonDataAccess.getData("settings/get/exception");
  }
  saveChecksettings(data: any) {
    return commonDataAccess.postData("settings/save/checksettings", data);
  }
  getChecksettings(settingsId: any) {
    return commonDataAccess.getData("settings/get/checksettings");
  }
  saveDispatchSettings(data: any) {
    return commonDataAccess.postData("settings/save/dispatch-settings", data);
  }
  getDispatchSettings() {
    return commonDataAccess.getData("settings/get/dispatch-settings");
  }
  saveDocumentationSettings(data: any) {
    return commonDataAccess.postData(
      "settings/save/documentation-settings",
      data
    );
  }
  getDocumentationSettings() {
    return commonDataAccess.getData("settings/get/documentation-settings");
  }
  saveEmailSettings(data: any) {
    return commonDataAccess.postData(
      "settings/save/email-settings",
      data
    );
  }
  getEmailSettings() {
    return commonDataAccess.getData("settings/get/email-settings");
  }
  saveExportPathSettings(data: any) {
    return commonDataAccess.postData(
      "settings/save/export-path-settings",
      data
    );
  }
  getExportPathSettings() {
    return commonDataAccess.getData("settings/get/export-path-settings");
  }
  saveFilterSettings(data: any) {
    return commonDataAccess.postData("settings/save/filter-settings", data);
  }
  getFilterSettings() {
    return commonDataAccess.getData("settings/get/filter-settings");
  }
  saveLabourSettings(data: any) {
    return commonDataAccess.postData("settings/save/labour-settings", data);
  }
  getLabourSettings() {
    return commonDataAccess.getData("settings/get/labour-settings");
  }
  saveLogoutSettings(data: any) {
    return commonDataAccess.postData("settings/save/logout-settings", data);
  }
  getLogoutSettings() {
    return commonDataAccess.getData("settings/get/logout-settings");
  }
  savePortableDeviceSync(data: any) {
    return commonDataAccess.postData(
      "settings/save/portable-device-sync",
      data
    );
  }
  getPortableDeviceSync() {
    return commonDataAccess.getData("settings/get/portable-device-sync");
  }
  savePrintingSettings(data: any) {
    return commonDataAccess.postData(
      "settings/save/printing-settings",
      data
    );
  }
  getPrintingSettings() {
    return commonDataAccess.getData("settings/get/printing-settings");
  }
  saveProductStockAndFilter(data: any) {
    return commonDataAccess.postData(
      "settings/save/product-stock-filter",
      data
    );
  }
  getProductStockAndFilter() {
    return commonDataAccess.getData("settings/get/product-stock-filter");
  }
  saveRecipesSettings(data: any) {
    return commonDataAccess.postData("settings/save/recipes-settings", data);
  }
  getRecipesSettings() {
    return commonDataAccess.getData("settings/get/recipes-settings");
  }
  saveStockCheckSettings(data: any) {
    return commonDataAccess.postData(
      "settings/save/stock-check-settings",
      data
    );
  }
  getStockCheckSettings() {
    return commonDataAccess.getData("settings/get/stock-check-settings");
  }
  saveTrainingSettings(data: any) {
    return commonDataAccess.postData("settings/save/training-settings", data);
  }
  getTrainingSettings() {
    return commonDataAccess.getData("settings/get/training-settings");
  }
  saveWorkRequestNumber(data: any) {
    return commonDataAccess.postData("settings/save/work-request-number", data);
  }
  getWorkRequestNumber() {
    return commonDataAccess.getData("settings/get/work-request-number");
  }
  saveGoodsInSettings(data: any) {
    return commonDataAccess.postData("settings/save/goods-in", data);
  }
  getGoodsInSettings() {
    return commonDataAccess.getData("settings/get/goods-in");
  }
  getCompanyDetailsSettings() {
    return commonDataAccess.getData("settings/get/company-details");
  }
  saveCompanyDetailsSettings(data: any) {
    return commonDataAccess.postData("settings/save/company-details", data);
  }
  deleteCompanyLogo() {
    return commonDataAccess.postData("settings/delete/company-logo");
  }
  getAuditSettings() {
    return commonDataAccess.getData("settings/get/auditsettings");
  }
  saveAuditsettings(data: any) {
    return commonDataAccess.postData("settings/save/auditsettings", data);
  }
  sendEmail(emailAddress: string) {
    return commonDataAccess.postData(`settings/email/send-test-email?toEmail=${emailAddress}`);
  }
  saveSalesOrderSettings(data: any) {
    return commonDataAccess.postData("settings/save/sales-order", data);
  }
  getSalesOrderSettings() {
    return commonDataAccess.getData("settings/get/sales-order");
  }
  saveTabletSettings(data: any) {
    return commonDataAccess.postData("settings/save/tablet-settings", data);
  }
  getTabletSettings() {
    return commonDataAccess.getData("settings/get/tablet-settings");
  }
  getVisitorSettings() {
    return commonDataAccess.getData("settings/get/visitor-settings");
  }
  saveVisitorSettings(data: any) {
    return commonDataAccess.postData("settings/save/visitor-settings", data);
  }
  getNonConformancesSettings() {
    return commonDataAccess.getData("settings/get/nc-settings");
  }
  saveNonConformancesSettings(data: any) {
    return commonDataAccess.postData("settings/save/nc-settings", data);
  }
  getReportSettings() {
    return commonDataAccess.getData("settings/get/report-settings");
  }
  saveReportSettings(data: any) {
    return commonDataAccess.postData("settings/save/report-settings", data);
  }
  getComplaintSettings() {
    return commonDataAccess.getData("settings/get/complaint-settings");
  }
  saveComplaintSettings(data: any) {
    return commonDataAccess.postData("settings/save/complaint-settings", data);
  }
  getPurchaseOrderSettings() {
    return commonDataAccess.getData("settings/get/purchase-order-settings");
  }
  savePurchaseOrderSettings(data: any) {
    return commonDataAccess.postData("settings/save/purchase-order-settings", data);
  }
  getCalendarSettings() {
    return commonDataAccess.getData("settings/get/calender-email-settings");
  }
  saveCalendarSettings(payload: any) {
    return commonDataAccess.postData("settings/save/calender-email-settings", payload);
  }
}

export default new SettingsDataAccess();
