import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { filterOptions } from "src/common/constants";
import caprofileDataaccess from "src/dataAccess/non-conformance/caprofile.dataaccess";
import checklistDataaccess from "src/dataAccess/non-conformance/checklist.dataaccess";
import nonConformanceDataaccess from "src/dataAccess/non-conformance/non-conformance.dataaccess";
import { IListType } from "../store";

export const loadingList = createAction<boolean>(
  "[Non Conformance] Loading data"
);

export const getNonConformanceDropdownSuccess = createAction<any[]>(
  "[Non Conformance] Get NonConformance dropdown Success"
);

export const getNonConformanceDropdown = createAsyncThunk<
  any,
  { isCorrectiveAction: boolean; includeTabletUserSelect: boolean }
>(
  "[Non Conformance] Get NonConformance dropdown",
  async (parameter, thunkAPI) => {
    const result = await nonConformanceDataaccess
      .getNonConformanceDropdown(
        parameter.isCorrectiveAction,
        parameter.includeTabletUserSelect
      )
      .then((res) => res?.data);
    return result;
  }
);

export const getCheckListSuccess = createAction<any>(
  "[NonConformance] Get check list success"
);
export const getCheckListAsync = createAsyncThunk<any, IListType>(
  "[NonConformance] Get check list",
  async (parameter, thunkAPI) => {
    thunkAPI.dispatch(loadingList(true));
    const result = await checklistDataaccess
      .getCheckList(parameter)
      .then((res) => {
        thunkAPI.dispatch(getCheckListSuccess(res));
        thunkAPI.dispatch(loadingList(false));
        return res;
      });
    return result;
  }
);

export const setChecklistDetails = createAction<any[]>(
  "[NonConformance] Set check list details"
);

export const getCheckListDropdownSuccess = createAction<any[]>(
  "[NonConformance] Get check list dropdown success"
);

export const getCheckListDropdown = createAsyncThunk<any>(
  "[NonConformance] Get check list dropdown",
  async (parameter, thunkAPI) => {
    const result = await checklistDataaccess
      .getCheckListDropdown()
      .then((res) => {
        thunkAPI.dispatch(getCheckListDropdownSuccess(res.data));
        return res;
      });
    return result;
  }
);

interface caListType extends IListType {
  isCA: number;
}

export const getCorrectiveActionListAsync = createAsyncThunk<any, caListType>(
  "[CorrectiveAction] Get List/CA List",
  async (parameter: caListType, thunkAPI) => {
    var filter = {
      propertyName: "IsCorrectiveAction",
      value: parameter.isCA.toString(),
      type: filterOptions.equals.value,
    };
    parameter.filters = parameter.filters.concat(filter);

    const result = await nonConformanceDataaccess
      .getNCList(parameter)
      .then((res) => {
        return res;
      });
    return result;
  }
);
export const getCAProfileByCaIdSuccess = createAction<any>(
  "Get CA Profile List Success"
);
export const getCAProfileByCaId = createAsyncThunk<any, number>(
  "Get CA Profile List",
  async (parameter: number, thunkAPI) => {
    const result = await caprofileDataaccess
      .getCAProfileByCaId(parameter)
      .then((res) => {
        thunkAPI.dispatch(getCAProfileByCaIdSuccess(res?.data));
        return res;
      });
    return result;
  }
);
// export const AddSubNc = createAction<any>("Get Sub NCs Success");
export const getSubNCs = createAsyncThunk<any, number>(
  "Get Sub NCs ",
  async (parameter: number) => {
    const result = await nonConformanceDataaccess
      .getSubNcs(parameter)
      .then((res) => res?.data);

    return result;
  }
);
export const AddSubNC = createAction<any>("Add SubNC Success");
export const RemoveSubNC = createAction<any>("Remove sub nc success");
export const SetSubNC = createAction<any[]>("[NC] set sub NC");
// export const updateSubNCState = createAction<any, any[]>(
//   "Update Sub NCs ",
//   async (parameter: any[], thunkAPI) => {
//     thunkAPI.dispatch(updateSubNCStateSuccess(parameter));
//     //return result;
//   }
// );
export const updateNCItemStateSuccess = createAction<any>(
  "pdate NC Items Success"
);
export const updateNCItemState = createAsyncThunk<any, any[]>(
  "Update NC Items ",
  async (parameter: any[], thunkAPI) => {
    thunkAPI.dispatch(updateNCItemStateSuccess(parameter));
    //return result;
  }
);

export const AddNcItem = createAction<any>("[NC] Add nc item");
export const RemoveNcItem = createAction<any>("[NC] Remove nc item");

export const getNCItemsSuccess = createAction<any>("Get NC Items Success");
export const getNCItems = createAsyncThunk<any, number>(
  "Get NC Items ",
  async (parameter: number, thunkAPI) => {
    const result = await nonConformanceDataaccess
      .getNcItems(parameter)
      .then((res) => res?.data);
    return result;
  }
);

export const getSiteItemsSuccess = createAction<any>("Get Site Items Success");
export const getSiteItems = createAsyncThunk<any, number>(
  "Get Site Items ",
  async (parameter: number, thunkAPI) => {
    const result = await nonConformanceDataaccess
      .getSiteItems(parameter)
      .then((res) => res?.data);
    return result;
  }
);

export const updateHasToSendEmail = createAction<any>(
  "Update Has To Send Email"
);
export const updateHasToShowAssignUsers = createAction<any>(
  "Update Has To Show Assign User"
);
