import { localStorageKeys } from "src/common/constants";
import { ReactNode, useEffect } from "react";
import { Redirect, useLocation } from "react-router";
import { useAppDispatch, useAppSelector } from "src/@redux/hook.type";
import { notification } from "antd";
import { logoutPage } from "src/@redux/account/accountActions";

interface AuthenticatedComponentProps {
  children?: ReactNode | any;
}

const AuthenticatedComponent = (props: AuthenticatedComponentProps) => {
  let user = useAppSelector((state) => state.user);

  let dispatch = useAppDispatch();
  let location = useLocation();

  useEffect(() => {
    if (user.isLogoutPage) {
      dispatch(logoutPage(false));
    }
  }, [user.isLogoutPage]);

  if (user.isLogoutPage) {
    notification.info({ message: "User logged out" });
    return <Redirect to={`/login?redirect=${location.pathname}`} />;
  } else {
    return <>{props.children}</>;
  }
};

export default function (
  TargetComponent,
  modules: any[],
  isOpen: boolean = false,
  isLogin: boolean = false
) {
  return (props) => {
    const authorization = localStorage.getItem(localStorageKeys.authorization)
      ? true
      : false;

    if (!authorization && !isOpen && !isLogin) {
      let pathname = window.location.pathname;
      return <Redirect to={`/login?redirect=${pathname}`} />;
    } else if (!authorization && isLogin) {
      return (
        <AuthenticatedComponent>
          <TargetComponent {...props} />
        </AuthenticatedComponent>
      );
    } else if (authorization && isLogin) {
      return <Redirect to={`/company`} />;
    }
    //if he is not permitted to this current module send him to a different page
    // let hasMatchedModule = false;
    // //check if user has a matched module
    // for (let i = 0; i < modules.length; i++) {
    //    let index = user.userModulePermissions ? user.userModulePermissions.indexOf(modules[i]) : -1;
    //    if (index !== -1) {
    //       hasMatchedModule = true;
    //       break;
    //    }
    // }
    // if (!hasMatchedModule && modules.length > 0) {
    //    return <NotAuthorize />;
    // }

    //if he is permitted then show him desired component
    return (
      <AuthenticatedComponent>
        <TargetComponent {...props} />
      </AuthenticatedComponent>
    );
  };
}
