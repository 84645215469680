import { Action } from "redux";
import { Dispatch } from "@reduxjs/toolkit";
import companyDataAccess from "src/dataAccess/company/companyDataAccess";

export enum companyActionType {
  LOAD_COMPANY = "[Company] Loading company",
  GET_COMPANY_LIST = "[Company] Get Company list",
  GET_COMPANY_LIST_SUCCESS = "[Company] Get Company list success",
  UPDATE_TAB_NAME = "Update Tab Name",
  GET_COMPANY_BASIC_INFO = "[Product] Get Company Basic Info",
  GET_COMPANY_NAME = "GET_COMPANY_Name",
}

interface CompanyAction extends Action {
  payload: any;
}

export const actions = {
  getCompanyList:
    (pageNo = 1, pageSize = 25, sortData = "", filters = []) =>
      (dispatch: Dispatch, getState) => {
        dispatch({ type: companyActionType.LOAD_COMPANY, payload: true });
        companyDataAccess
          .companyList({ pageNo, pageSize, sortData, filters })
          .then((res) => {
            console.log(res);
            dispatch({
              type: companyActionType.GET_COMPANY_LIST_SUCCESS,
              payload: res,
            });
            dispatch({ type: companyActionType.LOAD_COMPANY, payload: false });
          });
      },
  updateTabNameState: (res) => (dispatch: Dispatch, getState) => {
    dispatch({
      type: companyActionType.UPDATE_TAB_NAME,
      payload: res,
    });
  },
  updateCompanyNameState: (res) => (dispatch: Dispatch, getState) => {
    dispatch({
      type: companyActionType.GET_COMPANY_NAME,
      payload: res,
    });
  },
};

export type ActionType = CompanyAction;
