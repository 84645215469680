import commonDataAccess from "../commonDataAccess";
import { IFilter } from "../../@redux/listRedux";
import { formatFilterForQueryParam } from "src/common/service";

class AuditChangeLogDataAccess {
  getAuditChangeLogList = (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = formatFilterForQueryParam(params?.filters);

    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };

    return commonDataAccess.getData("auditChange/getList", parameters);
  };
  updateAuditLog = (id: any, payload: any) => {
    return commonDataAccess.putData("auditChange/update", payload, {
      id: id,
    });
  };
  deleteAuditLogByIds = (ids: string) => {
    return commonDataAccess.deleteData(`auditChange/delete/`, {
      ids: ids,
    });
  };
}

export default new AuditChangeLogDataAccess();
