import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { IListType } from "../store";
import incidentDataAccess from "src/dataAccess/incidents/incidentDataAccess";

export const getIncidentListSuccess = createAction<any>(
  "[Incident] Get Incident List Success"
);
export const loadingList = createAction<boolean>("[Incident] Loading list");

export const getIncidentListAsync = createAsyncThunk<any, IListType>(
  "[Incident] Get incident List",
  async (parameter, thunkAPI) => {
    thunkAPI.dispatch(loadingList(true));

    const result = await incidentDataAccess.incidentList(parameter).then((res) => {
      thunkAPI.dispatch(getIncidentListSuccess(res));
      thunkAPI.dispatch(loadingList(false));
      return res;
    });
    return result;
  }
);

export const saveIncidentListSuccess = createAction<any>(
  "[Incident] Save Incident Success"
);

export const saveIncidentListAsync = createAsyncThunk<
  any,
  { incidentId: any; data: any; incidentList: Array<any> }
>("[Incident] Save Incident", async (parameter, thunkAPI) => {
  const { incidentId, data, incidentList } = parameter;
  thunkAPI.dispatch(loadingList(true));
  var updatedIncidentList;
  if (incidentId == "edit") {
    let copyIncidentList = [...incidentList];
    updatedIncidentList = (copyIncidentList ?? []).map((item, index) => {
      let copyItem = { ...item };

      if (copyItem.id == data.id) {
        copyItem = data;
      }
      return copyItem;
    });
  } else {
    let copyIncidentList = [...incidentList];
    updatedIncidentList = [...copyIncidentList, data];
  }
  thunkAPI.dispatch(saveIncidentListSuccess(updatedIncidentList));
  thunkAPI.dispatch(loadingList(false));
  return data;
});

