import { createReducer } from "@reduxjs/toolkit";
import {
  getListActionSuccess,
  loadingProcedureJob,
} from "./procedureJobAction";

export interface IProcedureState {
  procedureJobList?: any[];
  dataCount?: number;
  isLoading?: boolean;
}

const initialState: IProcedureState = {
  procedureJobList: [],
};

export const procedureJobReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loadingProcedureJob, (state, action) => {
      state = {
        ...state,
        isLoading: action.payload,
      };
      return state;
    })
    .addCase(getListActionSuccess, (state, action) => {
      state = {
        ...state,
        procedureJobList: action.payload?.data,
        dataCount: action.payload?.dataCount,
      };
      return state;
    });
});
