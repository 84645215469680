import { ColumnsType } from "antd/lib/table";
import { filterOptions } from "src/common/constants";
import { ProductPaths } from "src/common/enums";
import { FilterOptionProps } from "src/microComponent/CompliantTable/filterAntD";
import {
    ActionConfigType,
    ActionGenerator,
} from "src/microComponent/component.service";
import { KeyValue } from "src/models/typeMappings/keyvalue.type";

export function getChartOfAccountFilterOptions() {
    const filterOption: FilterOptionProps[] = [
        {
            propertyName: "Name",
            placeholder: "Account Name",
            inputType: "text",
            typeOptions: [filterOptions.contains],
        }
    ];
    return filterOption;
}
export function getChartOfAccountTableColumns() {
    const columns: ColumnsType<any> = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: true
        },
        {
            title: "AccountCode",
            dataIndex: "accountCode",
            key: "accountCode",
            sorter: true
        },
        {
            title: "AccountType",
            dataIndex: "accountType",
            key: "accountType",
            sorter: true
        },
        {
            title: "TaxType",
            dataIndex: "taxType",
            key: "taxType",
            sorter: true
        }
    ];

    return columns;
}
