import { useAppDispatch, useAppSelector } from "src/@redux/hook.type";
import {
    actions as quickbookActions,
} from "src/@redux/quickbook/quickbookAction";
import { actions as listAction, IFilter } from "src/@redux/listRedux";

import { useEffect, useRef, useState } from "react";
import Filter from "src/microComponent/CompliantTable/filterAntD";
import { Button, Modal, notification, Spin, Tooltip } from "antd";
import { CommonPageHeader } from "src/microComponent/PageHeader/CommonPageHeader";

import {
    EditOutlined,
    DeleteOutlined,
    PlusOutlined
} from "@ant-design/icons";
import { CompliantTable } from "src/microComponent/CompliantTable/CompliantTable";
import quoteDataAccess from "src/dataAccess/quotes/quoteDataAccess";
import { Link, useLocation } from "react-router-dom";
import { getInvoiceFilterOptions, getInvoiceTableColumns } from "./invoices-column.config";
import { getErrorFilterOptions, getErrorTableColumns } from "./errors-column.config";


const Errors = (props) => {

    const quickbookState = useAppSelector((state) => state.quickbook);
    const listState = useAppSelector((state) => state.listState);
    const dispatch = useAppDispatch();
    const [selectedRowCount, setSelectedRowCount] = useState(0);
    const [selectedIds, setSelectedIds] = useState("");

    const routePath = useLocation();
    let pageContextFilter = useRef<any>();
    let pageName = useRef<string>("");

    useEffect(() => {
        filterArray = filterArray.map((item) => {
            item["value"] = "";
            return item;
        });
        dispatch(listAction.initializeFilter(filterArray));

        listState.sort = "";
        dispatch(listAction.updateSortString(listState.sort));

        dispatch(
            quickbookActions.getAllErrorList(
                listState.pageNo,
                listState.pageSize,
                listState.sort,
                listState.filters
            )
        );
        return () => {
            dispatch(listAction.updateSortString(""));
        };
    }, []);

    let filterArray = getErrorFilterOptions();

    const onPageChange = (page, pageSize) => {
        dispatch(
            quickbookActions.getAllErrorList(
                page,
                pageSize,
                listState.sort,
                listState.filters
            )
        );
        dispatch(listAction.updatePageNo(page));
    };

    const onPageSizeChange = (current, size) => {
        dispatch(listAction.updatePageSize(size));
        dispatch(
            quickbookActions.getAllErrorList(
                current,
                size,
                listState.sort,
                listState.filters
            )
        );
    };


    let columns = getErrorTableColumns(
        { ...props }
    );
    const onSortChange = (sortValue) => {
        dispatch(
            quickbookActions.getAllErrorList(
                listState.pageNo,
                listState.pageSize,
                sortValue,
                listState.filters
            )
        );
    };

    return (
        <>
            <CommonPageHeader
            />
            <div className="page-body-content">
                <div className="page-body-inner">
                    <Filter
                        filterOptions={filterArray}
                        onFilterChange={(pageNo, pageSize, filters: any[], sortData) => {
                            dispatch(
                                quickbookActions.getAllErrorList(
                                    pageNo,
                                    pageSize,
                                    sortData,
                                    filters
                                )
                            );
                        }}
                        listState={listState}
                    />
                    <CompliantTable
                        rowKey={(record) => record.id}
                        rowSelection={{
                            selectedRowKeys: selectedIds?.split(",").map(Number),
                            onChange: (selectedRowKeys) => {
                                setSelectedRowCount(selectedRowKeys.length);
                                setSelectedIds(selectedRowKeys.map((item) => item).join(","));
                            }
                        }}
                        dataSource={quickbookState.errors}
                        loading={quickbookState.isLoading}
                        columns={columns}
                        size="small"
                        onSortChange={onSortChange}
                        pagination={{
                            total: quickbookState.errorDataCount,
                            defaultPageSize: 25,
                            current: listState.pageNo,
                            pageSizeOptions: ["25", "50"],
                            onChange: onPageChange,
                            onShowSizeChange: onPageSizeChange,
                        }}
                    />
                </div>
            </div>
        </>
    );
};
export default Errors;
