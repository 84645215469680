import commonDataAccess from "../commonDataAccess";
import { IFilter } from "../../@redux/listRedux";

class quickbookDataAccess {

    companyList = async (params: {
        pageNo;
        pageSize;
        sortData;
        filters: Array<IFilter>;
    }) => {
        let _filters = {};
        console.log(params.filters);
        params.filters.forEach((item) => {
            if (item.value && item.value.length > 0) {
                _filters["filter." + item.propertyName] = item.value + "-" + item.type;
            }
        });
        let parameters = {
            pageNo: params.pageNo,
            pageSize: params.pageSize,
            sortPreference: params.sortData,
            ..._filters,
        };
        return await commonDataAccess.getData(
            "quickbook/get/getAllCustomers",
            parameters
        );
    };

    invoiceList = async (params: {
        pageNo;
        pageSize;
        sortData;
        filters: Array<IFilter>;
    }) => {
        let _filters = {};
        console.log(params.filters);
        params.filters.forEach((item) => {
            if (item.value && item.value.length > 0) {
                _filters["filter." + item.propertyName] = item.value + "-" + item.type;
            }
        });
        let parameters = {
            pageNo: params.pageNo,
            pageSize: params.pageSize,
            sortPreference: params.sortData,
            ..._filters,
        };
        return await commonDataAccess.getData(
            "quickbook/get/getAllInvoices",
            parameters
        );
    };

    errorList = async (params: {
        pageNo;
        pageSize;
        sortData;
        filters: Array<IFilter>;
    }) => {
        let _filters = {};
        console.log(params.filters);
        params.filters.forEach((item) => {
            if (item.value && item.value.length > 0) {
                _filters["filter." + item.propertyName] = item.value + "-" + item.type;
            }
        });
        let parameters = {
            pageNo: params.pageNo,
            pageSize: params.pageSize,
            sortPreference: params.sortData,
            ..._filters,
        };
        return await commonDataAccess.getData(
            "quickbook/get/getAllErrors",
            parameters
        );
    };


    chartOfAccountList = async (params: {
        pageNo;
        pageSize;
        sortData;
        filters: Array<IFilter>;
    }) => {
        let _filters = {};
        console.log(params.filters);
        params.filters.forEach((item) => {
            if (item.value && item.value.length > 0) {
                _filters["filter." + item.propertyName] = item.value + "-" + item.type;
            }
        });
        let parameters = {
            pageNo: params.pageNo,
            pageSize: params.pageSize,
            sortPreference: params.sortData,
            ..._filters,
        };
        return await commonDataAccess.getData(
            "quickbook/get/getAllChartOfAccounts",
            parameters
        );
    };

    taxRateList = async (params: {
        pageNo;
        pageSize;
        sortData;
        filters: Array<IFilter>;
    }) => {
        let _filters = {};
        console.log(params.filters);
        params.filters.forEach((item) => {
            if (item.value && item.value.length > 0) {
                _filters["filter." + item.propertyName] = item.value + "-" + item.type;
            }
        });
        let parameters = {
            pageNo: params.pageNo,
            pageSize: params.pageSize,
            sortPreference: params.sortData,
            ..._filters,
        };
        return await commonDataAccess.getData(
            "quickbook/get/getAllTaxRates",
            parameters
        );
    };

    getQuickbookAuth = async () => {
        var result = await commonDataAccess.getData(
            "quickbook/get/getQuickBookAuth"
        );
        return result;
    };

    syncQuickbookData = async () => {
        var result = await commonDataAccess.getData(
            "quickbook/syncCustomers"
        );
        return result;
    };

    uploadCustomers = async () => {
        var result = await commonDataAccess.getData(
            "quickbook/uploadCustomers"
        );
        return result;
    };

    uploadInvoices = async (selectedRows: string | any) => {
        var result = await commonDataAccess.getData(
            "quickbook/uploadInvoices",
            {
                selectedIds: selectedRows
            }
        );
        return result;
    };

    getQuickBookToken = async (code: any, realmId: any) => {
        var result = await commonDataAccess.getData(
            "quickbook/getQuickbookToken",
            {
                code: code,
                realmId: realmId
            }
        );
        return result;
    };

    getQuickbookSettings = async () => {
        var result = await commonDataAccess.getData(
            "quickbook/get/getQuickbookSettings"
        );
        return result;
    };

    getAllChartOfAccountsForDropDown = async () => {
        var result = await commonDataAccess.getData(
            "quickbook/get/getAllChartOfAccountsForDropDown"
        );
        return result;
    };

    saveSettings = async (model: any) => {
        var result = await commonDataAccess.postData("quickbook/save/saveSettings", model);
        return result;
    }

}

export default new quickbookDataAccess();
