import { createReducer } from "@reduxjs/toolkit";
import {
  GetPricebookListSuccess,
  GetPricebookList,
  LoadingData,
} from "./pricebookAction";

export interface IPricebookState {
  pricebooks?: any[];
  dataCount: number;
  isLoading?: boolean;
}
const initialState: IPricebookState = {
  pricebooks: [],
  dataCount: 0,
};

export const pricebookReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(LoadingData, (state, action) => {
      state = {
        ...state,
        isLoading: action.payload,
      };
      return state;
    })
    .addCase(GetPricebookListSuccess, (state, action) => {
      state = {
        ...state,
        pricebooks: action.payload?.data,
        dataCount: action.payload?.dataCount,
      };
      return state;
    });
});
