import axios from "axios";
import { dispatch } from "src/index";
import { logoutUser } from "src/@redux/account/accountActions";
import { notification } from "antd";

let cancelTokenSource = axios.CancelToken.source();

axios.interceptors.request.use((payload) => {
  if (payload.cancelToken) {
    cancelTokenSource = axios.CancelToken.source();
  }
  payload.cancelToken = payload.cancelToken ?? cancelTokenSource.token;
  return payload;
});

axios.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    if (error.response.status === 401) {
      notification.warn({ message: "Session expired" });
      cancelTokenSource.cancel();
      cancelTokenSource = null;
      dispatch(logoutUser());
    }
    return Promise.reject(error);
  }
);

export default axios;
