import { ColumnsType } from "antd/lib/table";
import { filterOptions } from "src/common/constants";
import { FilterOptionProps } from "src/microComponent/CompliantTable/filterAntD";

import {
    ActionConfigType,
    ActionGenerator,
    formatDate,
} from "src/microComponent/component.service";

export function getErrorFilterOptions() {
    const filterOption: FilterOptionProps[] = [
        {
            propertyName: "Name",
            placeholder: "Error Name",
            inputType: "text",
            typeOptions: [filterOptions.contains],
        }
    ];
    return filterOption;
}

export function getErrorTableColumns(props) {
    const columns: ColumnsType<any> = [
        {
            title: "Date",
            dataIndex: "lastModifiedByDate",
            key: "name",
            sorter: true,
            render: (text, record) => formatDate(record?.lastModifiedByDate),
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: true,
        },
        {
            title: "Error Message",
            dataIndex: "errorMessage",
            key: "errorMessage",
            sorter: true,
        },
        {
            title: "Error Type",
            dataIndex: "errorType",
            key: "errorType",
            sorter: true,
        },

    ];
    return columns;
}
