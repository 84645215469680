import {
	Spin,
	Form,
	Input,
	Select,
	Card,
	Space,
	Radio,
	Checkbox,
	Col,
	Table,
	Row,
	notification,
} from "antd";
import { useEffect, useState } from "react";
import { loadingList, saveRackListAsync } from "src/@redux/racks/rackActions";
import { useAppDispatch, useAppSelector } from "src/@redux/hook.type";
import { setEditPageTitle, savingData } from "src/@redux/common/commonAction";
import rackDataAccess from "src/dataAccess/lcoations/rackDataAccess";
import quickbookDataAccess from "src/dataAccess/quickbook/quickbookDataAccess";
import { LoadingData } from "src/@redux/companies/companyAction";
import { AsyncSelect } from "src/microComponent/SelectFields/AsyncSelect";
const Settings = (props) => {
	const dispatch = useAppDispatch();
	const quickbookState = useAppSelector((state) => state.quickbook);

	const formItemLayout = {
		labelCol: { span: 8 },
		wrapperCol: { span: 12 },
	};


	const [initFormData, setInitFormData] = useState<any>({});
	let [quickbookSettingForm] = Form.useForm();
	useEffect(() => {
		getQuickbookSettings();
		getAllChartOfAccountsForDropDown();
	}, []);

	const getQuickbookSettings = () => {
		dispatch(LoadingData(true));
		quickbookDataAccess.getQuickbookSettings().then((res) => {
			if (res?.success) {
				if (res?.data) {
					setInitFormData(res.data);
					quickbookSettingForm.setFieldsValue(res?.data);
				}
			}
			dispatch(LoadingData(false));
		});
	};

	async function onFormSubmit(data) {
		data = Object.fromEntries(Object.entries(data).map(([key, value]) => [key, value ? String(value) : '']));
		dispatch(savingData(true));
		console.log(data);
		await quickbookDataAccess.saveSettings(data).then((res) => {
			if (res?.success) {
				if (res?.data) {
					getQuickbookSettings();
				}
			}
			dispatch(savingData(false));
			notification.success({ message: "Settings saved successfully" });
		});
	}

	const getAllChartOfAccountsForDropDown = () => {
		return quickbookDataAccess.getAllChartOfAccountsForDropDown().then((res) => {
			if (res?.success) {
				return res?.data;
			}
		});
	};


	if (quickbookState.isLoading) {
		return (
			<div style={{ display: "flex", justifyContent: "center" }}>
				<Spin tip="Loading..."></Spin>,
			</div>
		);
	} else {
		return (
			<Form
				onFinish={onFormSubmit}
				initialValues={initFormData}
				size="middle"
				labelCol={{ span: 5 }}
				labelWrap
				form={quickbookSettingForm}
				id={props.formId}>
				<Row gutter={8} style={{ rowGap: "8px" }}>
					<Col xl={24} lg={24} md={24} sm={24} xs={24}>
						<Row style={{ rowGap: "8px" }}>
							<Col xl={24}>
								<Card>
									<Row>
										<Col xl={12} lg={12} md={12} sm={24} xs={24}>
											<Form.Item
												name="incomeAccount"
												label="Income Account"
												colon={false}
												{...formItemLayout}
												rules={[
													{ required: true, message: "Income account is required" },
												]}
											>
												<AsyncSelect
													fetchOptions={getAllChartOfAccountsForDropDown}
													showSearch
													allowClear
													placeholder="Select Income Account"
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row>
										<Col xl={12} lg={12} md={12} sm={24} xs={24}>
											<Form.Item
												name="expenseAccount"
												label="Expense Account"
												colon={false}
												{...formItemLayout}
												rules={[
													{ required: true, message: "Expense account is required" },
												]}
											>
												<AsyncSelect
													fetchOptions={getAllChartOfAccountsForDropDown}
													showSearch
													allowClear
													placeholder="Select Expense Account"
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row>
										<Col xl={12} lg={12} md={12} sm={24} xs={24}>
											<Form.Item
												name="inventoryAssetAccount"
												label="Inventory Asset Account"
												colon={false}
												{...formItemLayout}
												rules={[
													{ required: true, message: "Inventory asset account is required" },
												]}
											>
												<AsyncSelect
													fetchOptions={getAllChartOfAccountsForDropDown}
													showSearch
													allowClear
													placeholder="Select Inventory Asset Account"
												/>
											</Form.Item>
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
					</Col>
				</Row>
			</Form>
		);
	}
};
export default Settings;