import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import accountDataAccess from "src/dataAccess/account/accountDataAccess";
import Modal from "src/microComponent/ModalHandlers/GenericModal";
import { LoaderModal } from "src/microComponent/ModalHandlers/LoaderModal";

export const logoutPage = createAction<boolean>(
  "[Account] Logout success page start"
);

export const logoutUserSuccess = createAction<boolean>(
  "[Account] Logout user success"
);

export const logoutUser = createAsyncThunk(
  "[Account] Logout user",
  async (parameter, thunkAPI) => {
    let ref = Modal.openModal({
      componentNonNode: LoaderModal,
      data: "Logging out ...",
      maskClosable: false,
    });
    const result = await accountDataAccess
      .sigout()
      .then((res) => {
        // localStorage.removeItem(localStorageKeys.authorization);
        // localStorage.removeItem(localStorageKeys.user);
        localStorage.clear();
        thunkAPI.dispatch(logoutPage(true));
        thunkAPI.dispatch(logoutUserSuccess(true));
        ref.destroy();
        return res;
      })
      .catch((error) => {
        // localStorage.removeItem(localStorageKeys.authorization);
        // localStorage.removeItem(localStorageKeys.user);
        ref.destroy();
        localStorage.clear();
        thunkAPI.dispatch(logoutPage(true));
        thunkAPI.dispatch(logoutUserSuccess(true));
        return error;
      });
    return result;
  }
);

export const loginUserSuccess = createAction<any>(
  "[Account] Login user success"
);
