import { PageHeader, PageHeaderProps, Tooltip } from "antd";
import "./CommonPageHeader.less";

export const CommonPageHeader = (props: PageHeaderProps) => {
  let headerTitle = (
    <Tooltip title={props.title}>
      <div
        className="primary-color"
        style={{
          fontWeight: 600,
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {props.title}
      </div>
    </Tooltip>
  );
  let cpyProps = { ...props };
  cpyProps = {
    ...cpyProps,
    title: headerTitle,
    style: { padding: "10px 12px" },
  };
  return (
    <div className="page-layout" id="page-header-title">
      <PageHeader {...cpyProps} />
    </div>
  );
};
