import { ActionType, quoteActionType } from "./quoteActions";

export interface IQuoteState {
  quotes: any[];
  salesOrders: any[];
  salesOrderBasicInfo: any;
  quoteBasicInfo: any;
  // salesOrderList: any;
  salesOrderProductList: any[];
  salesOrderTotalAmount: any;
  salesOrderPayments: any[];
  salesOrderProductTotalWeight: bigint | any;
  filters: any[];
  dataCount: number;
  isLoading: boolean;
  companyName: any;
  companyId: bigint | any;
  activeSalesOrderId: bigint | any;
  taxRateInPercent: any;
  carrier: any;
  carrierService: any;
  allSmarttCarriers: any[];
  allCarriers: any[];
  isEmailSent: any;
  orderConfirmationEmailSentDate: any;
  isOrderSentTo3PL: any;
  orderSentTo3PLDate: any;
  triggerSubmitCount: number;
}

const initialState: IQuoteState = {
  quotes: [],
  salesOrders: [],
  salesOrderBasicInfo: {},
  quoteBasicInfo: {},
  // salesOrderList: {},
  salesOrderProductList: [],
  salesOrderTotalAmount: 0,
  salesOrderPayments: [],
  salesOrderProductTotalWeight: 0,
  filters: [],
  dataCount: 0,
  isLoading: false,
  companyName: "",
  companyId: 0,
  activeSalesOrderId: 0,
  taxRateInPercent: "",
  carrier: null,
  carrierService: null,
  allSmarttCarriers: [],
  allCarriers: [],
  isEmailSent: "EmailNotSent",
  orderConfirmationEmailSentDate: null,
  isOrderSentTo3PL: "No",
  orderSentTo3PLDate: null,
  triggerSubmitCount: 0
};

export const quoteReducer = (
  state: IQuoteState = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case quoteActionType.GET_QUOTE_LIST_SUCCESS:
      state = {
        ...state,
        quotes: action.payload?.data,
        dataCount: action.payload?.dataCount,
      };
      return state;
    case quoteActionType.GET_SALESORDER_LIST_SUCCESS:
      state = {
        ...state,
        salesOrders: action.payload?.data,
        dataCount: action.payload?.dataCount,
      };
      return state;
    case quoteActionType.GET_SALESORDER_BASICINFO:
      state = {
        ...state,
        salesOrderBasicInfo: action.payload,
        companyId: action.payload?.companyId,
        companyName: action.payload?.name,
      };
      return state;
    case quoteActionType.GET_QUOTE_BASICINFO:
      state = {
        ...state,
        quoteBasicInfo: action.payload,
        companyId: action.payload?.companyId,
        companyName: action.payload?.name,
      };
      return state;
    case quoteActionType.GET_SALESORDER_PAYMENTS:
      state = {
        ...state,
        salesOrderPayments: action.payload,
      };
      return state;
    case quoteActionType.GET_SALESORDER_PRODUCTLISTS:
      state = {
        ...state,
        salesOrderProductList: [...action?.payload],
      };
      return state;
    case quoteActionType.UPDATE_SALESORDER_TOTAL_AMOUNT:
      state = {
        ...state,
        salesOrderTotalAmount: action.payload,
      };
      return state;
    case quoteActionType.UPDATE_SALESORDER_PRODUCTTOTALWEIGHT:
      state = {
        ...state,
        salesOrderProductTotalWeight: action.payload,
      };
      return state;
    case quoteActionType.LOAD_QUOTE:
      state = {
        ...state,
        isLoading: action.payload,
      };
      return state;

    case quoteActionType.GET_COMPANY_Name:
      state = {
        ...state,
        companyName: action.payload,
      };
      return state;

    case quoteActionType.UPDATE_COMPANY_ID:
      state = {
        ...state,
        companyId: action.payload,
      };
      return state;
    case quoteActionType.UPDATE_SALESORDER_ACTIVEID:
      state = {
        ...state,
        activeSalesOrderId: action.payload,
      };
      return state;
    case quoteActionType.UPDATE_SALESORDER_TOTALTAXRATE:
      state = {
        ...state,
        taxRateInPercent: action.payload,
      };
      return state;
    case quoteActionType.UPDATE_SMARTT_CARRIER:
      state = {
        ...state,
        carrier: action.payload,
      };
      return state;
    case quoteActionType.UPDATE_ORDER_CONFIRMATION_EMAIL_STATUS:
      state = {
        ...state,
        isEmailSent: action.payload,
      };
      return state;
    case quoteActionType.UPDATE_ORDER_CONFIRMATION_EMAIL_DATE:
      state = {
        ...state,
        orderConfirmationEmailSentDate: action.payload,
      };
      return state;
    case quoteActionType.UPDATE_ORDER_SENT_TO_3PL_STATUS:
      state = {
        ...state,
        isOrderSentTo3PL: action.payload,
      };
      return state;
    case quoteActionType.UPDATE_ORDER_SENT_TO_3PL_DATE:
      state = {
        ...state,
        orderSentTo3PLDate: action.payload,
      };
      return state;
    case quoteActionType.UPDATE_SMARTT_CARRIER_SERVICE:
      state = {
        ...state,
        carrierService: action.payload,
      };
      return state;
    case quoteActionType.UPDATE_SMARTT_CARRIER_OPTIONS:
      state = {
        ...state,
        allSmarttCarriers: action.payload,
      };
      return state;
    case quoteActionType.UPDATE_ALL_CARRIERS:
      state = {
        ...state,
        allCarriers: action.payload,
      };
      return state;
    case quoteActionType.RESET_SALESORDER_PRODUCTLISTS:
      state = {
        ...state,
        salesOrderProductList: action.payload,
      };
      return state;
    case quoteActionType.Update_triggerSubmitCount:
      state = {
        ...state,
        triggerSubmitCount: action.payload,
      };
      return state;
    default:
      return state;
  }
};
