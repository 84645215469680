export enum modules {
  payroll = "Payroll",
  invoicing = "Invoicing",
  admin = "Admin",
  purchaseBill = "PurchaseBill",
}

export enum userTypes {
  admin = "Admin",
  superAdmin = "SuperAdmin",
  userGeneral = "User-General",
}
export enum accountTypes {
  income = "Income",
  expense = "Expense",
  asset = "Asset",
  liability = "Liability",
  equity = "Equity",
}
export enum ProcedureType {
  General = 0,
  StockControl = 1,
  Production = 2,
  Despatch = 3,
  Picking = 4,
  Training = 5,
  Visitor = 6,
}

export enum ProcedureStepTypeEnum {
  AcceptReject = "AcceptReject",
  AcceptCautionReject = "AcceptCautionReject",
  ScanOnly = "ScanOnly",
  Barcode = "Barcode",
  Date = "Date",
  Decimal = "Decimal",
  FreeText = "FreeText",
  List = "List",
  Numeric = "Numeric",
  PassFail = "PassFail",
  PassCautionFail = "PassCautionFail",
  Signature = "Signature",
  Temperature = "Temperature",
  WeightCheck = "WeightCheck",
  ItemWeightCheck = "ItemWeightCheck",
  YesNo = "YesNo",
  Message = "Message",
  ScanAndCheck = "ScanAndCheck",
  Grade = "Grade",
  GridView = "GridView",
  Score = "Score",
  Score0510 = "Score0510",
  Image = "Image",
  NoYes = "NoYes",
  AcceptRejectWB = "AcceptRejectWB",
  PassFailWB = "PassFailWB",
  PassFailNA = "PassFailNA",
  YesNoNA = "YesNoNA",
  YesNoWB = "YesNoWB",
  NoYesWB = "NoYesWB",
  SatisfactoryUnsatisfactory = "SatisfactoryUnsatisfactory",
  TickCross = "TickCross",
  TickNotInUse = "TickNotInUse",
  ScoreNoNumbers = "ScoreNoNumbers",
  BatchCode = "BatchCode",
  ConditionFactor = "ConditionFactor",
  QuantityWeight = "QuantityWeight",
  Products = "Products",
  Suppliers = "Suppliers",
  ReviewData = "ReviewData",
  YesCautionNo = "YesCautionNo",
  NoCautionYes = "NoCautionYes",
  HighMediumLow = "HighMediumLow",
  Audio = "Audio",
  DaysOfWeek = "DaysOfWeek",
  Score543210 = "Score543210",
  CompliantAndNotComplian = "CompliantAndNotCompliant",
  Scales = "Scales",
  BlueGreenAmberRed = "BlueGreenAmberRed",
  RedAmberGreen = "RedAmberGreen",
  Score0123 = "Score0123",
  GRN = "GRN",
  Customers = "Customers",
  ProductsGoodsIn = "ProductsGoodsIn",
  ProductsProduction = "ProductsProduction",
  ProductsFinishedProduct = "ProductsFinishedProduct",
  ProductsCheck = "ProductsCheck",
  ProductsDespatch = "ProductsDespatch",
  RAG = "RAG",
  InvoiceNo = "InvoiceNo",
  Button = "Button",
  Video = "Video",
  Score210 = "Score210",
  Score420 = "Score420",
  Visitor = "Visitor",
  VisitorCompany = "VisitorCompany",
  PersonVisiting = "PersonVisiting",
  VisitorPassPrint = "VisitorPassPrint",
  VisitorLeavingSite = "VisitorLeavingSite",
  PassRequiresTrainingFail = "PassRequiresTrainingFail",
  TrueFalse = "TrueFalse",
  TrueFalseWB = "TrueFalseWB",
}

export enum ProductPaths {
  allproducts = "allproducts",
  supplierproducts = "supplierproducts",
  finishedproducts = "finishedproducts",
  customerproducts = "customerproducts",
}
export enum PricebookPaths {
  supplierpricebook = "supplierpricebook",
  customerpricebook = "customerpricebook",
}

export enum CompanyService {
  Supplier = 1,
  Customer = 2,
  ServiceCompany = 3,
  PrimaryCompany = 4,
}

export type CompanyServiceType = Record<CompanyService, number>;

export enum ReportTypes {
  PROCEDURE = 1,
  CORRECTIVE_ACTION = 2,
  Fridge = 3,
  Freezer = 4,
  Scale = 5,
  General_Object = 6,
  GlassPlastic = 7,
  MetalDetector = 8,
  Corrective_Action_Resolved = 9,
  Corrective_Action_UnResolved = 10,
  Production1 = 11,
  StepValueBarChart = 12,
  CABarChart = 13,
  SchedulerReport = 14,
  GlobalReport = 15,
  StockCheck = 16,
  Product = 17,
  CommonCAByStep = 18,
  CommonCAByProcedure = 19,
  Item = 20,
  StepScore = 21,
  ProcedureScore = 22,
  ObjectItem = 23,
  OverdueCAs = 24,
  TrainingRecord = 25,
  TastePanel = 26,
  Complaint = 27,
  Percentage = 28,
  BatchCode = 29,
  Global = 30,
  RootCause = 31,
  Return = 32,
  DownTime = 33,
  ProductionReport = 34,
  NcBySupplierReport = 35,
  IntakeQualityReport = 36,
  CommonNc = 37,
  NcByProductReport = 38,
  OrderPlannerSupplierReport = 39,
  OrderPlannerCustomerReport = 40,
  GoodsInDataReport = 41,
  ProductsByCheckReport = 42,
  ByListItemReport = 43,
  BestBefore = 44,
  MassBalance = 45,
  GoodsIn = 46,
  FinishedProduct = 47,
  SupplierPerformance = 48,
  UnprocessedData = 49,
  Responsibility = 50,
  DespatchReport = 51,
  SupplierProductPerformance = 52,
  ProductSalesReport = 53,
  CostToMakeReport = 54,
  StockBalanceReport = 55,
  MostCommonNCReport = 56,
  MostCommonSubNCReport = 57,
  MostCommonCAReport = 58,
  NCByDateReport = 59,
  ExportJobReport = 60,
  ForwardTraceabilityReport = 61,
  BackwardTraceabilityReport = 62,
  CautionReport = 63,
  ComplaintByOutput = 64,
  RecipeReport = 65,
  MostCommonCaution = 66,
  CautionByProductReport = 67,
  ComplaintsAnalysisReport = 68,
  StockCheckReport = 69,
  ProcedureReportByListItem = 70,
  WasteReport = 71,
  ProductCheckReport = 72,
  ProductMAAReport = 73,
  OverdueCheckReport = 74,
  TrainingCourseByNameReport = 75,
  TrainingCourseByPersonReport = 75,

  ProcedureScheduledReport = 1001,
  ComplaintScheduledReport = 1002,
  ExportJobScheduledReport = 1003,
}
export enum AccessLevelEnum {
  NoAccess = 0,
  ReadOnly = 1,
  ReadModify = 2,
  CreateReadModify = 3,
  CreateReadModifyDeleteVerify = 4,
  CreateReadModifyDelete = 5,
  CreateReadModifyDeleteEditStock = 6,
  CreateReadModifyDeleteCompletePicking = 7,
}
