import { IFilter } from "src/@redux/listRedux";
import commonDataAccess from "../commonDataAccess";

class CompanyDataAccess {
  getCompaniesForDropdownByType = (serviceType: "supplier" | "customer") => {
    let type = serviceType === "supplier" ? 1 : 2;
    return commonDataAccess.getData("company/get/DropdownListByType", {
      type: type,
    });
  };

  getCodeDropdownListByType = (serviceType: "supplier" | "customer") => {
    let type = serviceType === "supplier" ? 1 : 2;
    return commonDataAccess.getData("company/get/codeDropdownListByType", {
      type: type,
    });
  };

  getServiceCompanyDropdown = () => {
    return commonDataAccess.getData("company/get/serviceCompanyDropdown");
  };

  supplierList = async (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    console.log(params.filters);
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };
    return await commonDataAccess.getData(
      "company/get/getAll",
      parameters
    );
  };

  getSupplierBasicInfo = async (params: { id: number | bigint }) => {
    var result = await commonDataAccess.getData(
      "company/get/supplierBasicInfo/" + params,
      {}
    );

    return result;
  };
  getSupplierExpiryDates = async (params: { id: number | bigint }) => {
    var result = await commonDataAccess.getData(
      "company/get/supplierExpiryDates/" + params,
      {}
    );
    return result;
  };
  getCompanyTelephone = async (params: { companyId: number | bigint }) => {
    var result = await commonDataAccess.getData(
      "company/get/companyTelephone/" + params,
      {}
    );
    return result;
  };
  saveSupplierBasicInfo = async (data: any) => {
    var result = await commonDataAccess.postData(
      "company/save/supplierBasicInfo",
      data
    );
    return result;
  };
  saveSupplierExpiryDates = async (data: any) => {
    var result = await commonDataAccess.postData(
      "company/save/supplierExpiryDates",
      data
    );
    return result;
  };
  getSupplierAdditionalNotes = async (params: { id: number | bigint }) => {
    var result = await commonDataAccess.getData(
      "company/get/additional-notes/" + params,
      {}
    );
    return result;
  };
  saveSupplierAdditionalNotes = async (data: any) => {
    var result = await commonDataAccess.postData(
      "company/save/additional-notes",
      data
    );
    return result;
  };
  getSupplierAttachmentInfo = async (id: number) => {
    var result = await commonDataAccess.getData(
      `company/get/attachment-info/${id}`,
      {}
    );
    return result;
  };
  saveSupplierAttachment = async (data: any) => {
    var result = await commonDataAccess.postData(
      "company/save/attachment",
      data
    );
    return result;
  };
  getSupplierSites = async (params: { id: number | bigint }) => {
    var result = await commonDataAccess.getData(
      "company/get/supplierSites/" + params,
      {}
    );
    return result;
  };
  saveSupplierSites = (data: any, supplierId: number) => {
    return commonDataAccess.postData("company/save/supplierSites", data, {
      supplierId: supplierId,
    });
  };
  getSupplierProducts = (id: number) => {
    return commonDataAccess.getData(`company/get/supplierProducts/${id}`);
  };
  deleteSupplierSites = async (params: { id: number | bigint }) => {
    return commonDataAccess.deleteData(
      "company/delete/supplierSites/" + params
    );
  };
  saveSupplierProducts = (payload: any) => {
    return commonDataAccess.postData(`company/save/supplierProducts`, payload);
  };
  deleteSupplierProduct = (id: number) => {
    return commonDataAccess.deleteData(`company/delete/supplierProducts/${id}`)
  };
  getSuppliersWithPriceByProduct = (id: number) => {
    return commonDataAccess.getData(`company/get/suppliersWithPriceByProduct/${id}`);
  };
  getSupplierDocuments = async (params: { id: number | bigint }) => {
    var result = await commonDataAccess.getData(
      "company/get/supplierDocuments/" + params,
      {}
    );
    return result;
  };
  saveSupplierDocuments = (data: any, supplierId: number) => {
    return commonDataAccess.postData("company/save/supplierDocuments", data, {
      supplierId: supplierId,
    });
  };
  deleteSupplierDocuments = async (params: { id: number | bigint }) => {
    return commonDataAccess.deleteData(
      "company/delete/supplierDocuments/" + params
    );
  };
  deleteSupplier = async (params: { ids: string }) => {
    var result = await commonDataAccess.deleteData("company/delete/" + params);
    return result;
  };
  getSupplierGlobalGAPExpiryDates = (id: number) => {
    return commonDataAccess.getData(`company/get/gobalGAPExpiryDates/${id}`);
  };
  saveSupplierGlobalGAPExpiryDates = (modal: any[]) => {
    return commonDataAccess.postData(`company/save/gobalGAPExpiryDates`, modal);
  };
  getOverdueEmailRecipientsEmployeeDepartments = (entityId: number, isUser: boolean) => {
    return commonDataAccess.getData("company/get/overdueEmailRecipientsUserorDepartments", {
      id: entityId,
      isUser: isUser,
    });
  };
  saveOverdueEmailRecipientsEmployeeDepartments = (
    entityId: number | bigint,
    selectedUsers: string,
    selectedDepts: string
  ) => {
    return commonDataAccess.postData("company/save/overdueEmailRecipientsUserorDepartments", null, {
      entityId: entityId,
      selectedDepartmentIds: selectedDepts,
      selectedUserIds: selectedUsers,
    });
  };
}
export default new CompanyDataAccess();
