import { ColumnsType } from "antd/lib/table";
import { filterOptions } from "src/common/constants";
import { ProductPaths } from "src/common/enums";
import { FilterOptionProps } from "src/microComponent/CompliantTable/filterAntD";
import {
    ActionConfigType,
    ActionGenerator,
} from "src/microComponent/component.service";
import { KeyValue } from "src/models/typeMappings/keyvalue.type";

export function getTaxRateFilterOptions() {
    const filterOption: FilterOptionProps[] = [
        {
            propertyName: "Name",
            placeholder: "Tax Name",
            inputType: "text",
            typeOptions: [filterOptions.contains],
        }
    ];
    return filterOption;
}
export function getTaxRateTableColumns() {
    const columns: ColumnsType<any> = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: true
        },
        {
            title: "Code",
            dataIndex: "taxCode",
            key: "taxCode",
            sorter: true
        },
        {
            title: "Rate",
            dataIndex: "taxRate",
            key: "taxRate",
            sorter: true
        },
        {
            title: "Applies To",
            dataIndex: "appliesTo",
            key: "appliesTo",
            sorter: true
        }
    ];

    return columns;
}
