import Select, { SelectProps } from "antd/lib/select";
import { useMemo, useState } from "react";

// Will be used to fetch smaller data

export const CustomSelect = (props: SelectProps<any>) => {
  let [selectedItem, setSelectedItem] = useState(
    props.mode === "multiple" ? [] : null
  );

  useMemo(() => {
    if (props.mode === "multiple") {
      let values: any[] = [];
      let propsValueString = props?.value?.map((item) => item?.toString());
      values = props?.options?.filter((item) => {
        if (propsValueString?.includes(item?.value?.toString())) {
          return item;
        }
      });
      setSelectedItem(values);
    } else {
      let value: any;
      value = props?.options?.find(
        (item) => item?.value?.toString() === props.value?.toString()
      );
      setSelectedItem(value);
    }
  }, [props?.options, props?.value]);

  const changeValue = (value) => {
    setSelectedItem(value);
    if (props.mode === "multiple") {
      let rawValues: any[] = value?.map((item) => {
        console.log(isNaN(item?.value));
        return isNaN(item?.value) ? item?.value : Number(item?.value);
      });
      props.onChange(rawValues, null);
    } else {
      let raw = isNaN(value?.value) ? value?.value : Number(value?.value);
      props.onChange(raw, null);
    }
  };

  return (
    <Select
      getPopupContainer={() =>
        document.getElementById("root-scrollable-element")
      }
      {...props}
      value={selectedItem}
      filterOption={false}
      labelInValue={true}
      onChange={changeValue}
    />
  );
};
