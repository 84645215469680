import commonDataAccess from "../commonDataAccess";

class PackSizeDataAccess {
  savePackSize = (payload: any) => {
    return commonDataAccess.postData("packSize/save", payload);
  };
  deleteProductPackSize = (packId: any, productId: any) => {
    return commonDataAccess.deleteData("packSize/delete/productPackSize", {
      packId: packId,
      productId: productId,
    });
  };
  getAllPackSizesDropdown = () => {
    return commonDataAccess.getData("packSize/getall/packsizeForDropdown");
  };
}

export default new PackSizeDataAccess();
