import { IFilter } from "src/@redux/listRedux";
import commonDataAccess from "../commonDataAccess";

class PurchaseOrderDataAccess {

    purchaseOrderList = async (params: {
        pageNo;
        pageSize;
        sortData;
        filters: Array<IFilter>;
    }) => {
        let _filters = {};
        console.log(params.filters);
        params.filters.forEach((item) => {
            if (item.value && item.value.length > 0) {
                _filters["filter." + item.propertyName] = item.value + "-" + item.type;
            }
        });
        let parameters = {
            pageNo: params.pageNo,
            pageSize: params.pageSize,
            sortPreference: params.sortData,
            ..._filters,
        };
        return await commonDataAccess.getData(
            "purchaseOrder/getAllpurchaseOrders",
            parameters
        );
    };

    getPurchaseOrderInfo = async (id: number | bigint, companyId: number | bigint | any) => {
        var result = await commonDataAccess.getData(
            "purchaseOrder/get/purchaseOrderInfo",
            {
                id: id,
                companyId: companyId
            }
        );
        return result;
    }
    savePurchaseOrderInfo = (model: any) => {
        return commonDataAccess.postData("purchaseOrder/save/purchaseOrderInfo", model);
    };

    savePurchaseOrderDetail = (model: any) => {
        return commonDataAccess.postData("purchaseOrder/save/purchaseOrderDetail", model);
    };

    getPurchaseOrderDetail = async (purchaseOrderId: number | bigint | any) => {
        var result = await commonDataAccess.getData(
            "purchaseOrder/get/purchaseOrderDetail",
            {
                purchaseOrderId: purchaseOrderId
            }
        );
        return result;
    };


    deletePurchaseOrder = async (params: { ids: string }) => {
        var result = await commonDataAccess.deleteData("purchaseOrder/delete/" + params);
        return result;
    };



    deletePurchaseOrderDetail = async (params: { id: number | bigint | any }) => {
        return commonDataAccess.deleteData(
            "purchaseOrder/delete/purchaseOrderDetail/" + params
        );
    };

    onPurchaseOrderConfirmation = async (model: any, organizationId: any, user: any,) => {
        return await commonDataAccess.postData("purchaseOrderReport/purchaseOrderConfirmationEmail", model, {
            OrganizationId: organizationId,
            User: user
        }, "Legacy");
    };

    getPurchaseOrderConfirmationEmailTemplate = async (purchaseOrderId: any) => {
        return await commonDataAccess.getData("purchaseOrder/getPurchaseOrderConfirmationEmailTemplate", { id: purchaseOrderId });
    };

    printPurchaseOrderConfirmationReport = (
        purchaseOrderId: any,
        organizationId: any,
        user: any,
        downloadProgress: (progress: any) => void
    ) => {
        return commonDataAccess.downloadPost({
            url: "purchaseOrderReport/printPurchaseOrderConfirmationReport",
            payload: null,
            params: {
                id: purchaseOrderId,
                OrganizationId: organizationId,
                User: user
            },
            onDowloadProgress: downloadProgress,
            type: "Legacy"
        });
    };

    onPurchaseOrderReceivedConfirmation = async (purchaseOrderId: any) => {
        var result = await commonDataAccess.getData("purchaseOrder/purchaseOrderReceivedConfirmation",
            {
                id: purchaseOrderId
            }
        );
        return result;
    };

}
export default new PurchaseOrderDataAccess();
