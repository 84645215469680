import { Action, combineReducers, configureStore } from "@reduxjs/toolkit";
import { accountReducer } from "./account/acountRedux";
import { productReducer } from "./products/productReducer";
import { procedureReducer } from "./procedures/procedureReducer";
import { companyReducer } from "./companies/companyReducer";
import { CompanyReducer } from "./company/companyReducer";
import { quoteReducer } from "./quotes/quoteReducer";
import { listReducer } from "./listRedux";
import { Dispatch } from "react";
import { peopleReducer } from "./peoples/peoplesReducer";
import { departmentReducer } from "./departments/departmentReducer";
import { trainingReducer } from "./trainings/trainingReducer";
import { locationReducer } from "./locations/locationReducer";
import { incidentReducer } from "./incidents/incidentReducer";
import { procedureJobReducer } from "./procedureJob/procedureReducer";
import { commonReducer } from "./common/commonReducer";
import { nonConformanceReducer } from "./nonConformance/nonConformanceReducer";
import { tabReducer } from "./tabs/tabReducer";
import { documentHistoryReducer } from "./documents/documentHistory.reducer";
import { exceptionReducer } from "./exceptions/exception.reducer";
import { trainingMatrixReducer } from "./trainingMatrix/training-matrix.reducer";
import { auditLogReducer } from "./auditChangeLog/auditChangeLog.reducer";
import { complaintReducer } from "./complaints/complaint.reducer";
import { pricebookReducer } from "./pricebook/pricebookReducer";
import { settingsReducer } from "./Settings/settingsReducer";
import { rackReducer } from "./racks/rackReducer";
import { QuickbookReducer } from "./quickbook/quickbookReducer";
import { purchaseOrderReducer } from "./purchaseOrders/purchaseOrderReducer";

export interface RootAction extends Action {
  payload: any;
}

export interface IListType {
  pageNo: number;
  pageSize: number;
  sortData: string;
  filters: any[];
}

const combineReducer = combineReducers({
  user: accountReducer,
  product: productReducer,
  Company: CompanyReducer,
  quote: quoteReducer,
  purchaseOrder: purchaseOrderReducer,
  quickbook: QuickbookReducer,
  procedure: procedureReducer,
  people: peopleReducer,
  department: departmentReducer,
  company: companyReducer,
  training: trainingReducer,
  location: locationReducer,
  procedureJob: procedureJobReducer,
  nonConformance: nonConformanceReducer,
  incident: incidentReducer,
  tab: tabReducer,
  documentHistory: documentHistoryReducer,
  exceptions: exceptionReducer,
  trainingMatrix: trainingMatrixReducer,
  auditChangeLog: auditLogReducer,
  complaint: complaintReducer,
  pricebook: pricebookReducer,
  settings: settingsReducer,
  rack: rackReducer,
  listState: listReducer,
  common: commonReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "[Account] Logout user success") {
    state = undefined;
  }
  return combineReducer(state, action);
};

// const root = createReducer()

export const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch | Dispatch<any>;
export type RootState = ReturnType<typeof combineReducer>;
