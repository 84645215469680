import { GenericModalProps } from "../GenericModal/GenericModal";
import Modal from "src/microComponent/ModalHandlers/GenericModal";
import "./CompliantModalWrapper.less";
import { ModalComponentProps } from "../GenericModal/ModalContainer";
import { Button, ButtonProps, Space } from "antd";
import { ReactNode, useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Provider } from "react-redux";
import { store } from "src/@redux/store";
import CompliantModalContent from "./CompliantModalContent";

const DEFAULT_CONFIG: GenericModalProps = {
  wrapClassName: "zero-padding-wrapper-modal",
  centered: true,
};

export interface WrapperComponentProps extends ModalComponentProps {
  bodyContext?: ReactNode;
  loading?: (isLoading: boolean) => void;
  visibleControlFooter?: boolean;
  closeOnOk?: boolean;
  hideClose?: boolean;
  onOk?: () => void;
  getValueAfterClose?: (value: any) => void;
  onCloseModal?: () => void;
  afterCloseData?: (data: any) => void;
  onChangeSubmitButtonProps?: (props: ButtonProps) => void;
  formId?: string;
}

export interface WrapperConfig extends GenericModalProps {
  bodyComponent?: ReactNode;
  visibleControlFooter?: boolean;
  hideClose?: boolean;
  closeOnOk: boolean;
  onOk?: () => void;
  getValueAfterClose?: (value: any) => void;
  afterCloseData?: (data: any) => void;
}

const DEFAULT_WRAPPER_CONFIG: WrapperComponentProps = {
  visibleControlFooter: true,
  closeOnOk: false,
  hideClose: false,
};

export const CompliantModalWrapper = (props: WrapperComponentProps) => {
  const cancel = props.onCancel ? props.onCancel : props.close;
  const config = { ...DEFAULT_WRAPPER_CONFIG, ...props };
  let {
    bodyContext,
    visibleControlFooter,
    closeOnOk,
    hideClose,
    onOk,
    className,
    ...restProps
  } = config;

  const [isLoading, setLoading] = useState<boolean>(false);
  const [submitProps, setSubmit] = useState<ButtonProps>();

  restProps.loading = function loading(value: boolean) {
    setLoading(value);
  };

  useEffect(() => {
    console.log(props);
    setSubmit(config?.okButtonProps);
  }, []);

  restProps.onChangeSubmitButtonProps = (btnProps: ButtonProps) => {
    setSubmit((props) => {
      return {
        ...props,
        ...btnProps,
      };
    });
  };
  const combinedClassName =
    "wrapper-content" + (className !== "" ? " " + className : "");
  return (
    <Provider store={config.reduxContext ?? store}>
      <div className={combinedClassName}>
        <div className="wrapper-header primary-background white-color">
          <span className="title-text title-fontsize">{config.title}</span>
          <div className="close-button">
            <CloseOutlined onClick={config.close} />
          </div>
        </div>
        {/* <div className="wrapper-body">
          {React.isValidElement(bodyContext)
            ? React.cloneElement(bodyContext, restProps)
            : bodyContext}
        </div> */}
        <CompliantModalContent
          bodyContext={bodyContext}
          componentProps={restProps}
          formId={submitProps?.form}
        />
        {visibleControlFooter && (
          <div className="wrapper-footer">
            <Space style={{ width: "100%", justifyContent: "end" }}>
              {!config.hideClose && (
                <Button onClick={cancel}>
                  {config.cancelText ? config.cancelText : "Cancel"}
                </Button>
              )}
              <Button
                type="primary"
                {...submitProps}
                loading={isLoading}
                onClick={() => {
                  if (closeOnOk) {
                    config.close();
                  } else if (onOk) {
                    config.onOk();
                  }
                }}
              >
                {config.okText ? config.okText : "Ok"}
              </Button>
            </Space>
          </div>
        )}
      </div>
    </Provider>
  );
};

export function openWithCustomWrapper(props: Partial<WrapperConfig> = {}) {
  const currentConfig = {
    ...DEFAULT_CONFIG,
    ...props,
  };
  const { bodyComponent, ...restConfig } = currentConfig;
  restConfig.component = props.component ? (
    props.component
  ) : (
    <CompliantModalWrapper bodyContext={bodyComponent} />
  );
  return Modal.openModal(restConfig);
}
