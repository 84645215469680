import { createReducer } from "@reduxjs/toolkit";
import {
  GetCustomerTypeSuccess,
  getCustomerDropdownSuccess,
  getSupplierDropdownSuccess,
  getSupplierListSuccess,
  LoadingData,
  getSupplierAttachmentInfoSuccess,
  saveSupplierAttachmentInfoSuccess
} from "./companyAction";


export interface ICompanyState {
  isLoading: boolean;
  supplierDropdownData: Array<any>;
  customerDropdownData: Array<any>;
  customerTypeList: Array<any>;
  supplierList: Array<any>;
  dataCount: number;
  attachmentInfo: any;
}

const initialState: ICompanyState = {
  dataCount: 0,
  isLoading: false,
  supplierDropdownData: [],
  customerDropdownData: [],
  customerTypeList: [],
  supplierList: [],
  attachmentInfo: {}, 
};

export const companyReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(LoadingData, (state, action) => {
      state = {
        ...state,
        isLoading: action.payload,
      };
      return state;
    })
    .addCase(getCustomerDropdownSuccess, (state, action) => {
      state = {
        ...state,
        customerDropdownData: action.payload?.data,
      };
      return state;
    })
    .addCase(getSupplierDropdownSuccess, (state, action) => {
      state = {
        ...state,
        supplierDropdownData: action.payload?.data,
      };
      return state;
    })
    .addCase(GetCustomerTypeSuccess, (state, action) => {
      state = {
        ...state,
        customerTypeList: action.payload?.data,
      };
      return state;
    })
    .addCase(getSupplierListSuccess, (state, action) => {
      state = {
        ...state,
        supplierList: action.payload?.data,
        dataCount: action.payload?.dataCount,
      };
      return state;
    })
    .addCase(getSupplierAttachmentInfoSuccess, (state, action) => {
      state = {
        ...state,
        attachmentInfo: action.payload,
      };
      return state;
    })
    .addCase(saveSupplierAttachmentInfoSuccess, (state, action) => {
      state = {
        ...state,
        attachmentInfo: action.payload,
      };
      return state;
    })
});
