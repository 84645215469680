import { IListType, RootState } from "../store";
import procedureDataAccess from "src/dataAccess/procedures/procedureDataAccess";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { filterOptions } from "src/common/constants";
import tabDataaccess from "src/dataAccess/procedures/tab.dataaccess";
import procedureStepDataAccess from "src/dataAccess/procedures/procedureStepDataAccess";
import { loadingData } from "../common/commonAction";
import { ProcedureStepConfigModel } from "src/pages/Procedure/Edit/StepEditModal/shared-components/step-edit.config";
import notification from "antd/lib/notification";

interface procedureDropdownByTypeParam {
  typeProcedure: number;
  limit?: number;
  searchValue?: string;
}
interface procedureListType extends IListType {
  type: number;
}
export const updateStepSequencePosition = createAction<number>(
  "[Step config] Set step sequence position"
);

export const 
getProcedureListSuccess = createAction<any>(
  "[Procedure] Get Procedure List Success"
);

export const loadingList = createAction<boolean>("[Procedure] Loading list");

export const getProcedureListAsync = createAsyncThunk<any, procedureListType>(
  "[Procedure] Get Procedure List",
  async (parameter: procedureListType, thunkAPI) => {
    thunkAPI.dispatch(loadingList(true));

    var filter = {
      propertyName: "TypeProcedure",
      value: parameter.type.toString(),
      type: filterOptions.equals.value,
    };
    parameter.filters = parameter.filters.concat(filter);

    const result = await procedureDataAccess
      .getProcedureList(parameter)
      .then((res) => {
        thunkAPI.dispatch(getProcedureListSuccess(res));
        thunkAPI.dispatch(loadingList(false));
        return res;
      });
    return result;
  }
);

export const getAllForDropdownByTypeProcedureSuccess = createAction<any>(
  "[Procedure] Get Procedure Dropdown Data By Type Success"
);

export const getAllForDropdownByTypeProcedureAsync = createAsyncThunk<
  void,
  procedureDropdownByTypeParam
>(
  "[Procedure] Get Procedure Dropdown Data By Type",
  async (parameter: procedureDropdownByTypeParam, thunkAPI) => {
    const result = await procedureDataAccess
      .getAllForDropdownByTypeProcedure(
        parameter.typeProcedure,
        parameter.limit,
        parameter.searchValue
      )
      .then((res) => {
        thunkAPI.dispatch(getAllForDropdownByTypeProcedureSuccess(res));
        return res;
      });
    return result;
  }
);

export const getTabDropdownSuccess = createAction<any[]>(
  "[Procedure] Get Tab dropdown success"
);

export const getTabDropdown = createAsyncThunk<any>(
  "[Procedure] Get tab dropdown",
  async (parameter, thunkAPI) => {
    const result = await tabDataaccess.getTabDropdown().then((res) => {
      thunkAPI.dispatch(getTabDropdownSuccess(res.data));
      return res;
    });
    return result;
  }
);

export const getTabDropdownListByProcedureSuccess = createAction<any[]>(
  "[Procedure tab] get tab dropdown by procedure success"
);

export const getTabDropdownByProcedure = createAsyncThunk<
  any,
  { procedureId: number }
>(
  "[Procedure tab] get tab dropdown by procedure",
  async (parameter, thunkAPI) => {
    const result = await tabDataaccess
      .getTabDropdownListByProcedure(parameter.procedureId)
      .then((res) => {
        thunkAPI.dispatch(getTabDropdownListByProcedureSuccess(res.data));
        return res;
      });
    return result;
  }
);

export const getProcedureStepListSuccess = createAction<any[]>(
  "[Procedure] Get Procedure step list success"
);

export const getProcedureStepListAsync = createAsyncThunk<any, number>(
  "[Procedure] Get Procedure step list",
  async (id, thunkAPI) => {
    thunkAPI.dispatch(loadingData(true));
    const result = await procedureStepDataAccess
      .getProcedureStepList(id)
      .then((res) => {
        thunkAPI.dispatch(getProcedureStepListSuccess(res?.data));
        thunkAPI.dispatch(loadingData(false));
        return res;
      });
    return result;
  }
);
export const getTrainingCourseEmployeesSuccess = createAction<any[]>(
  "[Procedure] Get Training Course Employees success"
);
export const getTrainingCourseEmployees = createAsyncThunk<any, number>(
  "[Procedure] Get Training Course Employees",
  async (id, thunkAPI) => {
    thunkAPI.dispatch(loadingData(true));
    const result = await procedureDataAccess
      .getTrainingCourseEmployees(id)
      .then((res) => {
        thunkAPI.dispatch(getTrainingCourseEmployeesSuccess(res?.data));
        thunkAPI.dispatch(loadingData(false));
        return res;
      });
    return result;
  }
);
export const updateProcedureIdSuccess = createAction<{
  Id: number;
}>("[Procedure] Update procedure Id success");

export const updateProcedureStepSuccess = createAction<{
  stepId: number;
  stepData: any;
}>("[Procedure] Update procedure step success");

export const setProcedureStepConfig = createAction<
  Partial<ProcedureStepConfigModel>
>("[Procedure config] Set procedure step config");

export const setCaNotApplicable = createAction<boolean>(
  "[Procedure config] Set CA not applicable success"
);

export const getSelectedProcedureBasicInfoSuccess = createAction<any>(
  "[Procedure] Get Selected procedure basic info success"
);

export const verifyScoreProcedure = createAction<boolean>(
  "[Procedure] Verify score procedure success"
);

export const verifyTabProcedure = createAction<boolean>(
  "[Procedure] Verify tab procedure success"
);

export const deleteProcedureStepSuccess = createAction<any[]>(
  "[Procedure Step] Delete Procedure step success"
);
export const setSubmitButtonType = createAction<any>(
  "[Procedure Step] Set Submit Button Type"
);

export const deleteProcedureStep = createAsyncThunk<any, string>(
  "[Procedure Step] Delete Procedure step",
  async (parameter, thunkAPI) => {
    const result = await procedureStepDataAccess
      .deleteStep(parameter)
      .then((res) => {
        if (res?.data === 1) {
          let ids = parameter.toString().split(",");
          const rootState: Partial<RootState> = thunkAPI.getState();
          let cpy = rootState.procedure.procedureStepList.slice();

          let newData = cpy.filter((item) => !ids.includes(item.id.toString()));

          let manageSequence = newData.map((item, index) => {
            let cpyObject = Object.assign({}, item);
            cpyObject.sequencePosition = index + 1;
            return cpyObject;
          });
          //console.log(newData);
          thunkAPI.dispatch(deleteProcedureStepSuccess(manageSequence));
          thunkAPI.dispatch(loadingData(false));
          notification.success({ message: "Step(s) deleted successfuly" });
        }
        return res;
      });
    return result;
  }
);

export const getDocHistorySuccess = createAction<any>(
  "[Procedure] Get Document History Success"
);

export const getDocHistoryAsync = createAsyncThunk<any, IListType>(
  "[Procedure] Get Document History List",
  async (parameter: IListType, thunkAPI) => {
    thunkAPI.dispatch(loadingList(true));

    const result = await procedureDataAccess
      .getDocumentHistory(parameter)
      .then((res) => {
        thunkAPI.dispatch(getDocHistorySuccess(res));
        thunkAPI.dispatch(loadingList(false));
        return res;
      });
    return result;
  }
);
