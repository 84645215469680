import { createReducer } from "@reduxjs/toolkit";
import { getRackListSuccess, updateRackListSuccess, loadingList, saveRackListSuccess } from "./rackActions";

export interface IRackState {
  rackList: Array<any>;
  dataCount: number;
  isLoading: boolean;
}

const initialState: IRackState = {
  rackList: [],
  dataCount: 0,
  isLoading: false,
};

export const rackReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getRackListSuccess, (state, action) => {
      state = {
        ...state,
        rackList: action.payload?.data,
        dataCount: action.payload?.dataCount,
      };
      return state;
    })

    .addCase(updateRackListSuccess, (state, action) => {
      state = {
        ...state,
        rackList: action.payload,
      };
      return state;
    })

    .addCase(saveRackListSuccess, (state, action) => {
      state = {
        ...state,
        rackList: action.payload,
      };
      return state;
    })


    .addCase(loadingList, (state, action) => {
      state = {
        ...state,
        isLoading: action.payload,
      };
      return state;
    });
});
