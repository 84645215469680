import { createReducer } from "@reduxjs/toolkit";
import {
  getDocumentHistoryListSuccess,
  loadingHistoryList,
} from "./documentHistory.action";

export interface documentHistoryState {
  docHistoryList: any[];
  dataCount: number;
  isLoading: boolean;
}

const initialState: Partial<documentHistoryState> = {
  docHistoryList: [],
};

export const documentHistoryReducer = createReducer(initialState, (builder) => {
  builder.addCase(getDocumentHistoryListSuccess, (state, action) => {
    state = {
      ...state,
      docHistoryList: action.payload?.data,
      dataCount: action.payload.dataCount,
    };
    return state;
  });
  builder.addCase(loadingHistoryList, (state, action) => {
    state = {
      ...state,
      isLoading: action.payload,
    };
    return state;
  });
});
