import commonDataAccess from "../commonDataAccess";

class locationDataAccess {
  getLocationDropdown = () => {
    return commonDataAccess.getData(
      "location/get/LocationAndSublocationDropdownList"
    );
  };
  getAllLocationDropdown = () => {
    return commonDataAccess.getData("location/get/allSelectList");
  };

  getLocationTree = () => {
    return commonDataAccess.getData("location/get/getLocationTree");
  };
  getAssociatedProcedures = (locationId: number) => {
    return commonDataAccess.getData("location/get/getAssociatedProcedures", {
      locationId,
    });
  };
  getLocationBasicInfo = (locationId: number) => {
    console.log(locationId, "as");
    return commonDataAccess.getData("location/get/locationBasicInfo", {
      locationId,
    });
  };
  saveLocationBasicInfo = (payload: any) => {
    console.log(payload);
    return commonDataAccess.postData(
      "location/save/locationBasicInfo",
      payload
    );
  };
  deleteLocations = (ids: any) => {
    return commonDataAccess.deleteData("location/delete", { ids: ids });
  };
  getLocationDates = (locationId: number) => {
    return commonDataAccess.getData("location/get/locationDates", {
      locationId,
    });
  };
  saveLocationDates = (payload: any) => {
    return commonDataAccess.postData("location/save/locationDates", payload);
  };
  getLocationSupplier = (locationId: number) => {
    return commonDataAccess.getData("location/get/locationSupplier", {
      locationId,
    });
  };
  saveLocationSupplier = (payload: any) => {
    return commonDataAccess.postData("location/save/locationSupplier", payload);
  };
  getLocationComments = (locationId: number) => {
    return commonDataAccess.getData("location/get/locationComments", {
      locationId,
    });
  };
  saveLocationComments = (payload: any) => {
    return commonDataAccess.postData("location/save/locationComments", payload);
  };
  saveLocationDocuments = (payload: any) => {
    return commonDataAccess.postData("location/save/locationDocuments", payload);
  };
  copyLocation = (id: number) => {
    return commonDataAccess.getData(`location/copy`, {
      id: id,
    });
  };
  printLocationReport = (organizationId: number, reportType: "pdf" | "excel") => {
    return commonDataAccess.downloadFile(
      `LocationReport/printLocationReport/${organizationId}/${reportType}`, {}, "Legacy"
    );
  };
}

export default new locationDataAccess();
