import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { filterOptions } from "src/common/constants";
import procedureJobDataAccess from "src/dataAccess/procedures/procedureJobDataAccess";

interface IListType {
  pageNo: number;
  pageSize: number;
  sortData: string;
  filters: any[];
}
interface jobListType extends IListType {
  typeProcedure: number;
}

export const loadingProcedureJob = createAction<boolean>(
  "[Procedure Job] Loading procedure Job list"
);

export const getListActionSuccess = createAction<any>(
  "[Procedure Job] Get Procdure Job list Success"
);

export const getListActionAsync = createAsyncThunk<string, jobListType>(
  "[Procedure Job] Get Procedure Job list",
  async (parameter: jobListType, thunkAPI) => {
    thunkAPI.dispatch(loadingProcedureJob(true));

    var filter = {
      propertyName: "TypeProcedure",
      value: parameter.typeProcedure.toString(),
      type: filterOptions.equals.value,
    };
    parameter.filters = parameter.filters.concat(filter);

    const result = await procedureJobDataAccess
      .getList(parameter)
      .then((res) => {
        thunkAPI.dispatch(loadingProcedureJob(false));
        thunkAPI.dispatch(getListActionSuccess(res));
        return res;
      });
    return result;
  }
);
