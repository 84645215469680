import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import pricebookDataAccess from "src/dataAccess/pricebook/pricebookDataAccess";
import { IListType } from "../store";

export const LoadingData = createAction<boolean>("[Pricebook] Loading data");
export const GetPricebookListSuccess = createAction<any>(
  "[Pricebook] Get all pricebook list success"
);

interface pricebookListType extends IListType {
  pricebooktype: string;
}
export const GetPricebookList = createAsyncThunk<any, pricebookListType>(
  "[Pricebook] Get Pricebook List",
  async (parameter, thunkAPI) => {
    thunkAPI.dispatch(LoadingData(true));
    const result = await pricebookDataAccess.getAll(parameter).then((res) => {
      thunkAPI.dispatch(GetPricebookListSuccess(res));
      thunkAPI.dispatch(LoadingData(false));

      return res;
    });
    return result;
  }
);
