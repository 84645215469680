import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { ModalComponentProps } from "./GenericModal/ModalContainer";

export const LoaderModal = (props: ModalComponentProps) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <div className="wrapper-content">
      <div className="wrapper-body">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin indicator={antIcon} />
          <span style={{ fontWeight: 500, marginLeft: "5px" }}>
            <h3 style={{ marginBottom: "0px" }}>
              {props?.data ?? "Loading ..."}
            </h3>
          </span>
        </div>
      </div>
    </div>
  );
};
