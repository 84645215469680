import { GenericModalProps } from "./GenericModal";
import classNames from "classnames";
import { ConfigProvider, ModalProps } from "antd";
import { getTransitionName } from "antd/lib/_util/motion";
import Dialog from "antd/lib/modal";
import React from "react";

interface ModalContainerProps extends GenericModalProps {
  afterClose?: () => void;
  close: (...args: any[]) => void;
  autoFocusButton?: null | "ok" | "cancel";
  rootPrefixCls: string;
  iconPrefixCls?: string;
}

export interface ModalComponentProps extends ModalProps {
  close?: (...args: any[]) => void;
  data?: any;
  reduxContext?: any;
}

export const ModalContainer = (props: ModalContainerProps) => {
  const {
    close,
    zIndex,
    afterClose,
    visible,
    keyboard,
    centered,
    getContainer,
    maskStyle,
    direction,
    prefixCls,
    wrapClassName,
    rootPrefixCls,
    iconPrefixCls,
    bodyStyle,
    closable = false,
    closeIcon,
    modalRender,
    focusTriggerAfterClose,
    component,
    title,
    footer,
    ...restProps
  } = props;

  let componentProps: ModalComponentProps = {
    ...restProps,
    close: close,
    closeIcon: closeIcon,
    okText: props.okText,
    cancelText: props.cancelText,
    title: title,
    footer: footer,
    okButtonProps: props.okButtonProps,
    onCancel: props.onCancel,
    data: props.data,
    reduxContext: props.reduxContext,
  };

  const contentPrefixCls = `${prefixCls}-modal-generic`;
  const width = props.width || 416;
  const style = props.style || {};
  const mask = props.mask === undefined ? true : props.mask;

  const maskClosable =
    props.maskClosable === undefined ? false : props.maskClosable;

  const classString = classNames(
    contentPrefixCls,
    `${contentPrefixCls}-${props.type}`,
    { [`${contentPrefixCls}-rtl`]: direction === "rtl" },
    props.className
  );

  const renderWithProps = (context, props) => {
    return React.isValidElement(context)
      ? React.cloneElement(context, props)
      : null;
  };

  return (
    <ConfigProvider
      prefixCls={rootPrefixCls}
      iconPrefixCls={iconPrefixCls}
      direction={direction}
    >
      <Dialog
        prefixCls={prefixCls}
        className={classString}
        wrapClassName={classNames(
          { [`${contentPrefixCls}-centered`]: !!props.centered },
          wrapClassName
        )}
        onCancel={() => close({ triggerCancel: true })}
        visible={visible}
        title={null}
        footer={null}
        transitionName={getTransitionName(
          rootPrefixCls,
          "zoom",
          props.transitionName
        )}
        maskTransitionName={getTransitionName(
          rootPrefixCls,
          "fade",
          props.maskTransitionName
        )}
        mask={mask}
        maskClosable={maskClosable}
        maskStyle={maskStyle}
        style={style}
        bodyStyle={bodyStyle}
        width={width}
        zIndex={zIndex}
        afterClose={afterClose}
        keyboard={keyboard}
        centered={centered}
        getContainer={getContainer}
        closable={closable}
        closeIcon={closeIcon}
        modalRender={modalRender}
        focusTriggerAfterClose={focusTriggerAfterClose}
      >
        <div className={`${contentPrefixCls}-body-wrapper`}>
          <div className={`${contentPrefixCls}-body`}>
            {React.isValidElement(component)
              ? React.cloneElement(component, props)
              : null}
          </div>
        </div>
      </Dialog>
    </ConfigProvider>
  );
};
