import { IFilter } from "src/@redux/listRedux";
// import { filters } from "src/pages/Exceptions/List/table.config";
import commonDataAccess from "../commonDataAccess";
import { formatFilterForQueryParam } from "src/common/service";
// import { filters } from "src/pages/Exceptions/List/table.config";

class ExceptionDataAccess {
  saveCautionTabletCollectedData(payload: any) {
    return commonDataAccess.postData(
      `exception/save/cautiontabletcollected`,
      payload
    );
  }
  getCautionGeneralInfo(cautionId: any) {
    return commonDataAccess.getData(`exception/get/cautiongeneralinfo`, {
      id: cautionId,
    });
  }
  getCautionTabletCollectedDataInfo(cautionId: any) {
    return commonDataAccess.getData(
      `exception/get/cautiontabletcollecteddatainfo`,
      {
        id: cautionId,
      }
    );
  }
  getCautionWebCommentsInfo(cautionId: any) {
    return commonDataAccess.getData(`exception/get/cautionwebcommentsinfo`, {
      id: cautionId,
    });
  }
  saveCautionGeneral = (payload: any) => {
    return commonDataAccess.postData(`exception/save/cautiongeneral`, payload);
  };
  saveCautionWebComments = (payload: any) => {
    return commonDataAccess.postData(
      `exception/save/cautionwebcomments`,
      payload
    );
  };
  getNCList = (params: {
    pageNo;
    pageSize;
    sortData;
    status: string;
    filters: Array<IFilter>;
  }) => {
    let _filters = formatFilterForQueryParam(params.filters);
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      status: params.status,
      ..._filters,
    };

    return commonDataAccess.getData("exception/getNCAll", parameters);
  };
  getCautionList = (params: {
    pageNo;
    pageSize;
    sortData;
    status: string;
    filters: Array<IFilter>;
  }) => {
    let _filters = formatFilterForQueryParam(params.filters);
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      status: params.status,
      ..._filters,
    };

    return commonDataAccess.getData("exception/getCautionAll", parameters);
  };
  getExceptionGeneral = (id: any) => {
    return commonDataAccess.getData(`exception/get/exceptiongeneral/${id}`);
  };

  saveExceptionGeneral = (
    payload: any,
    exceptionType: any = "CorrectiveActions"
  ) => {
    return commonDataAccess.postData(
      `exception/save/exceptiongeneral`,
      payload,
      {
        exceptionType: exceptionType,
      }
    );
  };
  getTabletCollectedData = (exceptionId) => {
    return commonDataAccess.getData(
      `exception/get/tabletcollecteddata/${exceptionId}`
    );
  };
  getLibraryAttachments = (exceptionId) => {
    return commonDataAccess.getData(
      `exception/get/exceptionAttachmentLibrary/${exceptionId}`
    );
  };
  saveExceptionAttachments = (payload) => {
    return commonDataAccess.postData(
      "exception/save/exceptionAttachment",
      payload
    );
  };
  getExceptionConfig = (id: any) => {
    return commonDataAccess.getData(`exception/get/config/${id}`);
  };
  saveTabletCollectedData = (payload: any, createdFromTablet: boolean) => {
    return commonDataAccess.putData(
      `exception/save/tabletCollectedData`,
      payload,
      {
        isCreatedFromTablet: createdFromTablet,
      }
    );
  };
  getExceptionChecklistDetail = (exceptionId: any) => {
    return commonDataAccess.getData(
      `exception/get/exceptionCheckListDetail/${exceptionId}`
    );
  };
  getExceptionAuthorizedBy = (exceptionId: any) => {
    return commonDataAccess.getData(
      `exception/get/exceptionAuthroizedBy/${exceptionId}`
    );
  };
  saveExceptionAuthorizedBy = (payload: any, exceptionId: any) => {
    return commonDataAccess.putData(
      "exception/save/exceptionAuthroizedBy",
      payload,
      {
        exceptionId: exceptionId,
      }
    );
  };
  getExceptionHistory = (exceptionId: any) => {
    return commonDataAccess.getData(
      `exception/get/exceptionHistory/${exceptionId}`
    );
  };
  closeDownNc = (exceptionId: any) => {
    return commonDataAccess.putData(`exception/closeDownException`, null, {
      exceptionId: exceptionId,
    });
  };
  approve = (id: any) => {
    return commonDataAccess.putData(`exception/approve-caution`, null, {
      id: id,
    });
  };
  getNextIdToApprove = (id: any) => {
    return commonDataAccess.getData(`exception/get/nextoutstandingcaution`, {
      id: id,
    });
  };
  markAsPending = (id: any) => {
    return commonDataAccess.putData(`exception/mark-as-pending`, null, {
      id: id,
    });
  };
  moveToNC = (id: any, ncId: any, subNcId: any) => {
    return commonDataAccess.putData(`exception/move-to-nc`, null, {
      id: id,
      ncId: ncId,
      subNcId: subNcId,
    });
  };
  backToOutstanding = (exceptionId: any) => {
    return commonDataAccess.putData(`exception/backToOutstanding`, null, {
      exceptionId: exceptionId,
    });
  };
  sendErrorNcToResolve = (exceptionId: any) => {
    return commonDataAccess.putData("exception/sendErrorNcToResolve", null, {
      exceptionId: exceptionId,
    });
  };
  emailNc = (exceptionId: string, userIds: string) => {
    return commonDataAccess.getData("exception/emailNc", {
      exceptionIds: exceptionId,
      userIds: userIds,
    });
  };
  printNcReport = (exceptionId: any) => {
    return commonDataAccess.downloadFile(
      `exception/printNcEmailReport/${exceptionId}`
    );
  };
  sendProcessEmail = (
    exceptionId: any,
    assignUserId: any,
    resolveByDate: any
  ) => {
    return commonDataAccess.getData("exception/sendProcessEmail", {
      exceptionId: exceptionId,
      assignUserid: assignUserId,
      resovedByDate: resolveByDate,
    });
  };
  getExceptionCount = () => {
    return commonDataAccess.getData("exception/get/exceptionCount");
  };
}

export default new ExceptionDataAccess();
