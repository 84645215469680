import { createReducer } from "@reduxjs/toolkit";
import {
  CreateDepartmentSuccess,
  DeleteDepartmentSuccess,
  GetDepartmentDropDown,
  GetDepartmentList,
  GetDepartmentTrainingList,
  GetSubDepartmentByDepartmentId,
  GetSubDepartmentByDepartmentIdForDropdown,
  RemoveTrainingFromDepartmentSuccess,
  SetDepartmentTrainingList,
  UpdateDepartmentSuccess,
} from "./departmentActions";

export interface IDepartmentState {
  departmentList?: any[];
  dataCount: number;
  departmentDropdown?: any[];
  isLoading?: boolean;
  loadDepartmentTraining?: boolean;
  trainingList: any[];
  loadSubDepartment?: boolean;
  subDepartmentList: any[];
  loadSubDepartmentDropdown?: boolean;
  subDepartmentDropdownList?: any[];
}
const initialState: IDepartmentState = {
  departmentList: [],
  dataCount: 0,
  trainingList: [],
  subDepartmentList: [],
};

export const departmentReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(GetDepartmentList.pending, (state, action) => {
      state = {
        ...state,
        isLoading: true,
      };
      return state;
    })
    .addCase(GetDepartmentList.fulfilled, (state, action) => {
      state = {
        ...state,
        departmentList: action.payload?.data,
        dataCount: action.payload?.dataCount,
        isLoading: false,
      };
      return state;
    })
    .addCase(GetDepartmentDropDown.fulfilled, (state, action) => {
      state = {
        ...state,
        departmentDropdown: [
          { label: "---Select Department---", value: "" },
        ].concat(action.payload),
      };
      return state;
    })
    .addCase(CreateDepartmentSuccess, (state, action) => {
      if (state.departmentList?.length === 0) return state;
      let count = state.dataCount + 1;
      state = {
        ...state,
        departmentList: (state.departmentList ?? []).concat(action.payload),
        dataCount: count,
      };
      return state;
    })
    .addCase(UpdateDepartmentSuccess, (state, action) => {
      if (state.departmentList?.length === 0) return state;
      let copyList = state?.departmentList?.map((item) => {
        if (item?.id === action.payload?.id) {
          item = {
            ...item,
            ...action.payload,
          };
        }
        return item;
      });
      state = {
        ...state,
        departmentList: copyList,
      };
      return state;
    })
    .addCase(DeleteDepartmentSuccess, (state, action) => {
      let filteredData = state.departmentList.filter(
        (item) => !action.payload.includes(item.id)
      );
      state = {
        ...state,
        departmentList: filteredData,
      };
      return state;
    })
    .addCase(GetDepartmentTrainingList.pending, (state, action) => {
      state = {
        ...state,
        loadDepartmentTraining: true,
      };
      return state;
    })
    .addCase(GetDepartmentTrainingList.fulfilled, (state, action) => {
      state = {
        ...state,
        trainingList: action.payload,
        loadDepartmentTraining: false,
      };
      return state;
    })
    .addCase(SetDepartmentTrainingList, (state, action) => {
      state = {
        ...state,
        trainingList: action.payload,
      };
      return state;
    })
    .addCase(RemoveTrainingFromDepartmentSuccess, (state, action) => {
      let trainingIds = action.payload.split(",");
      console.log(trainingIds);
      let filteredData = state.trainingList.filter(
        (item) => !trainingIds.includes(item.id?.toString())
      );
      console.log(filteredData);
      state = {
        ...state,
        trainingList: filteredData,
      };
      return state;
    })

    .addCase(GetSubDepartmentByDepartmentId.pending, (state, action) => {
      state = {
        ...state,
        loadSubDepartment: true,
      };
      return state;
    })
    .addCase(GetSubDepartmentByDepartmentId.fulfilled, (state, action) => {
      state = {
        ...state,
        subDepartmentList: action.payload,
        loadSubDepartment: false,
      };
      return state;
    })
    .addCase(
      GetSubDepartmentByDepartmentIdForDropdown.pending,
      (state, action) => {
        state = {
          ...state,
          loadSubDepartmentDropdown: true,
        };
        return state;
      }
    )
    .addCase(
      GetSubDepartmentByDepartmentIdForDropdown.fulfilled,
      (state, action) => {
        console.log(action);
        state = {
          ...state,
          subDepartmentDropdownList: action.payload,
          loadSubDepartmentDropdown: false,
        };
        return state;
      }
    );
});
