import { ActionType, purchaseOrderActionType } from "./purchaseOrderAction";

export interface IPurchaseOrderState {
    purchaseOrders: any[];
    purchaseOrderBasicInfo: any;
    purchaseOrderProductList: any[];
    purchaseOrderTotalAmount: any;
    purchaseOrderProductTotalWeight: bigint | any;
    isPoReceied: any;
    filters: any[];
    dataCount: number;
    isLoading: boolean;
    companyName: any;
    companyId: bigint | any;
    activePurchaseOrderId: bigint | any;
    taxRateInPercent: any;
    isEmailSent: any;
    purchaseOrderConfirmationEmailSentDate: any;
    triggerSubmitCount: number;
}

const initialState: IPurchaseOrderState = {
    purchaseOrders: [],
    purchaseOrderBasicInfo: {},
    purchaseOrderProductList: [],
    purchaseOrderTotalAmount: 0,
    purchaseOrderProductTotalWeight: 0,
    isPoReceied: false,
    filters: [],
    dataCount: 0,
    isLoading: false,
    companyName: "",
    companyId: 0,
    activePurchaseOrderId: 0,
    taxRateInPercent: "",
    isEmailSent: false,
    purchaseOrderConfirmationEmailSentDate: null,
    triggerSubmitCount: 0
};

export const purchaseOrderReducer = (
    state: IPurchaseOrderState = initialState,
    action: ActionType
) => {
    switch (action.type) {
        case purchaseOrderActionType.GET_PURCHASEORDER_LIST_SUCCESS:
            state = {
                ...state,
                purchaseOrders: action.payload?.data,
                dataCount: action.payload?.dataCount,
            };
            return state;
        case purchaseOrderActionType.GET_PURCHASEORDER_BASICINFO:
            state = {
                ...state,
                purchaseOrderBasicInfo: action.payload,
                companyId: action.payload?.companyId,
                companyName: action.payload?.name,
            };
            return state;
        case purchaseOrderActionType.GET_PURCHASEORDER_PRODUCTLISTS:
            state = {
                ...state,
                purchaseOrderProductList: [...action?.payload],
            };
            return state;
        case purchaseOrderActionType.UPDATE_PURCHASEORDER_TOTAL_AMOUNT:
            state = {
                ...state,
                purchaseOrderTotalAmount: action.payload,
            };
            return state;
        case purchaseOrderActionType.UPDATE_PURCHASEORDER_PRODUCTTOTALWEIGHT:
            state = {
                ...state,
                purchaseOrderProductTotalWeight: action.payload,
            };
            return state;
        case purchaseOrderActionType.LOAD_PURCHASEORDER:
            state = {
                ...state,
                isLoading: action.payload,
            };
            return state;
        case purchaseOrderActionType.MARK_AS_RECEIVED:
            state = {
                ...state,
                isPoReceied: action.payload,
            };
            return state;

        case purchaseOrderActionType.GET_COMPANY_Name:
            state = {
                ...state,
                companyName: action.payload,
            };
            return state;

        case purchaseOrderActionType.UPDATE_COMPANY_ID:
            state = {
                ...state,
                companyId: action.payload,
            };
            return state;
        case purchaseOrderActionType.UPDATE_PURCHASEORDER_ACTIVEID:
            state = {
                ...state,
                activePurchaseOrderId: action.payload,
            };
            return state;
        case purchaseOrderActionType.UPDATE_PURCHASEORDER_TOTALTAXRATE:
            state = {
                ...state,
                taxRateInPercent: action.payload,
            };
            return state;

        case purchaseOrderActionType.UPDATE_ORDER_CONFIRMATION_EMAIL_STATUS:
            state = {
                ...state,
                isEmailSent: action.payload,
            };
            return state;
        case purchaseOrderActionType.UPDATE_ORDER_CONFIRMATION_EMAIL_DATE:
            state = {
                ...state,
                purchaseOrderConfirmationEmailSentDate: action.payload,
            };
            return state;
        case purchaseOrderActionType.RESET_SALESORDER_PRODUCTLISTS:
            state = {
                ...state,
                purchaseOrderProductList: action.payload,
            };
            return state;
        case purchaseOrderActionType.Update_triggerSubmitCount:
            state = {
                ...state,
                triggerSubmitCount: action.payload,
            };
            return state;
        default:
            return state;
    }
};
