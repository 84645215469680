import { createReducer } from "@reduxjs/toolkit";
import {
  DeleteAuditChangeByIdSuccess,
  GetAuditChangeLogListSuccess,
  LoadingAuditData,
  UpdateAudtiChangeLog,
} from "./auditChangeLog.action";

export interface IAuditChangeLogState {
  auditLogList: any[];
  dataCount: number;
  loading: boolean;
}

const initialState: Partial<IAuditChangeLogState> = {
  auditLogList: [],
  dataCount: 0,
};

export const auditLogReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(LoadingAuditData, (state, action) => {
      state = {
        ...state,
        loading: action.payload,
      };
      return state;
    })
    .addCase(GetAuditChangeLogListSuccess, (state, action) => {
      state = {
        ...state,
        auditLogList: action.payload?.data,
        dataCount: action.payload?.dataCount,
      };
      return state;
    })
    .addCase(UpdateAudtiChangeLog, (state, action) => {
      let updateList = state.auditLogList?.map((item) => {
        if (item?.id === action.payload?.id) {
          item = {
            ...item,
            comment: action.payload.comment,
            createdByDate: action.payload.createdByDate,
          };
        }
        return item;
      });
      state = {
        ...state,
        auditLogList: updateList,
      };
      return state;
    })
    .addCase(DeleteAuditChangeByIdSuccess, (state, action) => {
      let idList = action.payload?.split(",")?.map((item) => Number(item));
      if (idList?.length === 0) {
        return state;
      }
      let newList = state.auditLogList?.filter(
        (item) => !idList.includes(Number(item?.id))
      );
      state = {
        ...state,
        auditLogList: newList,
      };
      return state;
    });
});
