import { ColumnsType } from "antd/lib/table";
import { filterOptions } from "src/common/constants";
import { ProductPaths } from "src/common/enums";
import { FilterOptionProps } from "src/microComponent/CompliantTable/filterAntD";
import {
	ActionConfigType,
	ActionGenerator,
} from "src/microComponent/component.service";
import { KeyValue } from "src/models/typeMappings/keyvalue.type";

export function getCustomerFilterOptions() {
	const filterOption: FilterOptionProps[] = [
		{
			propertyName: "Name",
			placeholder: "Name",
			inputType: "text",
			typeOptions: [filterOptions.contains],
		},
		{
			propertyName: "Company",
			placeholder: "Company",
			inputType: "text",
			typeOptions: [filterOptions.contains],
		},
	];
	return filterOption;
}
export function getCustomerTableColumns() {
	const columns: ColumnsType<any> = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			sorter: true
		},
		{
			title: "Company",
			dataIndex: "company",
			key: "company",
			sorter: true
		}
	];

	return columns;
}
