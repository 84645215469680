import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import trainingDataAccess from "src/dataAccess/trainings/trainingDataAccess";

interface IPagination {
  pageNumber: number;
  pageSize: number;
}

export const loadTrainingMatrix = createAction<boolean>(
  "[Trainig Matrix] Load training matrix"
);

export const loadMoreData = createAction<boolean>(
  "[Trainig Matrix] Load more data"
);

export const updatePageNumber = createAction<number>(
  "[Trainig Matrix] Update page number"
);

export const getTrainingMatrixDataSuccess = createAction<any>(
  "[Trainig Matrix] Get training matrix data success"
);

export const getTrainingMatrixData = createAsyncThunk<any, IPagination>(
  "[Trainig Matrix] Get training matrix data",
  async (parameter, thunkAPI) => {
    thunkAPI.dispatch(loadTrainingMatrix(true));
    const result = await trainingDataAccess
      .getTrainingMatrix(parameter.pageNumber, parameter.pageSize)
      .then((res) => {
        thunkAPI.dispatch(updatePageNumber(parameter.pageNumber));
        thunkAPI.dispatch(getTrainingMatrixDataSuccess(res?.data));
        thunkAPI.dispatch(loadTrainingMatrix(false));
        return res?.data;
      });
    return result;
  }
);
