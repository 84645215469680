import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import documentHistoryDataaccess from "src/dataAccess/documents/documentHistory.dataaccess";
import { IListType } from "../store";

interface DocHistoryListParams extends IListType {
  entityId: number;
  entityType: string;
}

export const loadingHistoryList = createAction<boolean>(
  "[Document History] Loading History list"
);

export const getDocumentHistoryListSuccess = createAction<any>(
  "[Document History] Get document history list success"
);

export const getDocumentHistoryList = createAsyncThunk<
  any,
  DocHistoryListParams
>(
  "[Document History] Get document history list",
  async (parameters, thunkAPI) => {
    thunkAPI.dispatch(loadingHistoryList(true));
    const result = await documentHistoryDataaccess
      .getList(parameters)
      .then((res) => {
        thunkAPI.dispatch(getDocumentHistoryListSuccess(res));
        thunkAPI.dispatch(loadingHistoryList(false));
        return res;
      });
    return result;
  }
);
