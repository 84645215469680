import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import complaintDataaccess from "src/dataAccess/complaints/complaint.dataaccess";
import { IListType } from "../store";
import { ComplaintCount } from "./complaint.reducer";
import settingsDataAccess from "src/dataAccess/settings/settingsDataAccess";

interface ComplaintListType extends IListType {
  complaintStatus: "Outstanding" | "Overdue" | "Resolved" | null;
}

export const GetComplaintList = createAsyncThunk<any, ComplaintListType>(
  "[Complaints] Get complaint list paginated",
  async (parameter) => {
    const result = await complaintDataaccess
      .getList(parameter)
      .then((res) => res);
    return result;
  }
);

export const DeleteComplaintSuccess = createAction<string[]>(
  "[Complaint] Delete complaint success"
);
export const SaveAndEditContinue = createAction<any>(
  "[Complaint] Save And Edit Continue"
);
export const GetComplaintSettings = createAsyncThunk(
  "[Complaint settings] get complaint settings",
  async (parameter) => {
    const result = await settingsDataAccess
      .getComplaintSetting()
      .then((res) => res?.data);
    return result;
  }
);

export const GetComplaintSettingSuccess = createAction<any>(
  "[Complaint Setting] get complaint setting success"
);

export const SetComplaintGeneralSuccess = createAction<any>(
  "[Complaint] Set complaint general success"
);

export const UpdateComplaintGeneral = createAction<any>(
  "[Complaint] Update complaint general success"
);

export const GetComplaintsCount = createAsyncThunk(
  "[Complaints] Get complaint count by type",
  async () => {
    const result = await complaintDataaccess
      .getComplaintsCount()
      .then((res) => res?.data);
    return result;
  }
);

export const ChangeComplaintCount = createAction<Partial<ComplaintCount>>(
  "[Complaint] Change complaint count"
);
