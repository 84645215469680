import { createReducer } from "@reduxjs/toolkit";
import { LoadingData, getTabListSuccess, loadingList } from "./tabActions";

export interface ITabState {
  tabList: Array<any>;
  dataCount: number;
  isLoading: boolean;
}

const initialState: ITabState = {
  tabList: [],
  dataCount: 0,
  isLoading: false,
};

export const tabReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getTabListSuccess, (state, action) => {
      state = {
        ...state,
        tabList: action.payload?.data,
        dataCount: action.payload?.dataCount,
      };
      return state;
    })
    .addCase(LoadingData, (state, action) => {
      state = {
        ...state,
        isLoading: action.payload,
      };
      return state;
    })
    .addCase(loadingList, (state, action) => {
      state = {
        ...state,
        isLoading: action.payload,
      };
      return state;
    });
});
