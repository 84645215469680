import Axios, { AxiosError, AxiosResponse } from "axios";
import { User } from "../../models/user";
import commonDataAccess from "../commonDataAccess";
import axios from "axios";

const { apiPrefix, apiUrl, legacyUrl } = window as any;

class accountDataAccess {
  /**
   * Checks if the current token is valid
   * @returns the response sent by api
   */
  isAuthenticated = () => {
    return commonDataAccess.getData("Account/isauthenticated");
  };
  login = (userLoginInfo: User) => {
    userLoginInfo.rememberMe = true;
    return Axios.post(apiUrl + apiPrefix + "account/login", userLoginInfo, {
      cancelToken: axios.CancelToken.source().token,
    })
      .then((response: AxiosResponse) => {
        if (response.data.success) {
          return response.data;
        } else {
          throw new Error(response.data.message);
        }
      })
      .catch((error: AxiosError) => {
        throw new Error(error.response.data.message);
      });
  };
  reportLogin = (userLoginInfo: any) => {
    const params = new URLSearchParams();
    params.append('grant_type', 'password');
    params.append('username', userLoginInfo.name);
    params.append('password', userLoginInfo.password);
    return Axios.post(legacyUrl + "token", params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then((response: AxiosResponse) => {
        if (response.data.success) {
          return response.data;
        } else {
          throw new Error(response.data.message);
        }
      })
      .catch((error: AxiosError) => {
        throw new Error(error.response.data.message);
      });
  };
  // GetList =
  //   (params: { pageNo; pageSize; sortData; filters: Array<IFilter> }) =>
  //    {
  //     let _filters = {};
  //     params.filters.forEach((item) => {
  //       if (item.value && item.value.length > 0) {
  //         _filters["filter." + item.propertyName] =
  //           item.value + "-" + item.type;
  //       }
  //     });
  //     let parameters = {
  //       pageNo: params.pageNo,
  //       pageSize: params.pageSize,
  //       sortPreference: params.sortData,
  //       ..._filters,
  //     };

  //     return commonDataAccess.getData("user/getall", parameters)
  //   };
  //   alterOrganization = (tenantId) =>  {
  //     return commonDataAccess.postData("Account/altertenant", null, {
  //       tenantId,
  //     })
  //   };

  sigout = () => {
    return commonDataAccess.postDataNoResponse("Account/logout");
  };
}
export default new accountDataAccess();
