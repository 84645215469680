import commonDataAccess from "../commonDataAccess";
import { IFilter } from "../../@redux/listRedux";

// export type ProductActiveStatusType = typeof(ProductStatusEnum);

export enum ProductStatusEnum {
  all = 1,
  active = 2,
  inactive = 3,
}

class ProductDataAccess {
  getProductSpecification(productId: any) {
    return commonDataAccess.downloadFile("product/get/productSpecification", {
      id: productId,
    });
  }
  exportDataToExcel() {
    return commonDataAccess.downloadFile("product/export/productDataToExcel");
  }
  saveMeasurments = (model: any) => {
    return commonDataAccess.postFormData("product/save/measurments", model);
  };
  saveBasicInfo = (model: any) => {
    return commonDataAccess.postData("product/save/basicInfo", model);
  };
  productList = (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    console.log(params.filters);
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };

    return commonDataAccess.getData("product/getall", parameters);
  };
  productBestBeforeList = (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    console.log(params.filters);
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };

    return commonDataAccess.getData(
      "product/getallbybestbeforeuseby",
      parameters
    );
  };
  getProductBasicInfo = (productId) => {
    return commonDataAccess.getData("product/get/basicinfo", { id: productId });
  };


  getProductDimension = (productId) => {
    return commonDataAccess.getData("product/get/dimension", { id: productId });
  };

  saveProductDimension = (model: any, token: string | any) => {
    return commonDataAccess.postData("product/save/dimension", model, {
      token: token
    });
  };

  getProductOtherInfo = (productId) => {
    return commonDataAccess.getData("product/get-otherinfo", { id: productId });
  };

  saveProductOtherInfo = (payload) => {
    return commonDataAccess.postData("product/save-otherinfo", payload);
  };
  getProductAllergens = (productId) => {
    return commonDataAccess.getData("product/get/productallergens", {
      productId: productId,
    });
  };
  saveProductAllergens = (model: any) => {
    return commonDataAccess.postData("product/save/allergens", model);
  };
  getMeasurments = (productId) => {
    return commonDataAccess.getData("product/get/measurments", {
      id: productId,
    });
  };
  getProductComments = (productId) => {
    return commonDataAccess.getData("product/get-comments", { id: productId });
  };
  saveProductComments = (payload) => {
    return commonDataAccess.postData("product/save-comments", payload);
  };

  getAllConfigurableTypeDropdown = (type: "COUTY" | "MUNIT") => {
    return commonDataAccess.getData("ConfigurableTypes/getall", {
      type: type,
    });
  };

  getAllSourceProductForDropdown = (productId) => {
    return commonDataAccess.getData("product/get/sourceproduct", {
      id: productId,
    });
  };
  getAllConvertableProductFromUOM = (uomId) => {
    return commonDataAccess.getData("product/get/convertableProductFromUOM", {
      uomId: uomId,
    });
  };
  getAllCategoryForDropdown = () => {
    return commonDataAccess.getData("Category/getall");
  };
  saveProductCategory = (payload) => {
    return commonDataAccess.postData("category/save", payload);
  };
  deleteProductCategory = (categoryId: number, productId: number) => {
    return commonDataAccess.deleteData(`category/delete/`, {
      id: categoryId,
      productId: productId,
    });
  };
  getFinishedProductDropdownDataBySearchTerm = (inputValue) => {
    return commonDataAccess.getData("product/get/finishedproduct", {
      term: inputValue,
    });
  };
  getAllAreaForDropdown = () => {
    return commonDataAccess.getData("area/get/datafordropdownList");
  };

  getProductDropDown = (
    type: "customer" | "supplier" | "" = "",
    term: string = "",
    limit: number = 10,
    includeProductCode: boolean = false,
    selectedIds: any[] = null,
    activeStatus: ProductStatusEnum = ProductStatusEnum.all
  ) => {
    let ids = selectedIds ? selectedIds.toString() : "";
    return commonDataAccess.getData("product/get/dropdownlist", {
      type: type,
      term: term,
      limit: limit,
      includeProductCode: includeProductCode,
      selectedIds: ids,
      activeStatus: activeStatus,
    });
  };
  getAllBatchCodeByProduct = (
    productId: number,
    inStock: Boolean,
    filters: IFilter[] = []
  ) => {
    let _filters = {};

    filters?.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      productId: productId,
      inStock: inStock,
      ..._filters,
    };

    return commonDataAccess.getData(
      "product/get/batchcodebyproduct",
      parameters
    );
  };
  getAllRecipeTransactionDataByProduct = (productId: any) => {
    return commonDataAccess.getData("product/get/recipetransactiondata", {
      productId: productId,
    });
  };
  getAllDispatchTransactionDataByProduct = (productId: any) => {
    return commonDataAccess.getData("product/get/dispatchtransactiondata", {
      productId: productId,
    });
  };
  updateBatchCodeBestBefore = (rackBatchId: number, bestBefore: any) => {
    return commonDataAccess.postData("product/update/bestbeforedate", null, {
      rackBatchId: rackBatchId,
      bestBefore: bestBefore,
    });
  };
  updateBatchCodQuantity = (
    productId: number,
    rackBatchId: number,
    quantity: any
  ) => {
    return commonDataAccess.postData("product/update/batchquantity", null, {
      productId: productId,
      rackBatchId: rackBatchId,
      quantity: quantity,
    });
  };
  convertToFinishedProduct = (data: any) => {
    return commonDataAccess.postFormData(
      "product/ConvertProductToFinishedProduct",
      data
    );
  };
  getProductDateInfo = (productId: any) => {
    return commonDataAccess.getData("product/get/dates", {
      productId: productId,
    });
  };
  saveProductDateInfo = (data: any) => {
    return commonDataAccess.postFormData("product/save/dates", data);
  };
  getProductPricingInfo(productId) {
    return commonDataAccess.getData("product/get/pricing", { id: productId });
  }

  getProductUnitPrices(productIds: string | any, priceBandId: bigint | any) {
    return commonDataAccess.getData("product/get/productUnitPricesForSalesOrders", { productIds: productIds, priceBandId: priceBandId });
  }

  getProductCategoryForCheckBox = () => {
    return commonDataAccess.getData("product/get/productsList");
  };

  getSelectedCategoriesAndSubCategories = (productId: any | bigint) => {
    return commonDataAccess.getData("product/get/selectedCategoriesAndSubCategories", { productId: productId });
  };

  saveProductPricingInfo = async (data: any) => {
    var result = await commonDataAccess.postData(
      "product/save/pricing",
      data
    );
    return result;
  };

  getProductCategories = (productId: any) => {
    return commonDataAccess.getData("product/get/productCategories", {
      productId: productId,
    });
  };

  saveProductCategories = async (data: any) => {
    var result = await commonDataAccess.postData(
      "product/save/productCategories",
      data
    );
    return result;
  };

  getProductWeightInfo(productId: string) {
    return commonDataAccess.getData("product/get/weightinfo", {
      productId: productId,
    });
  }
  saveProductWeightInfo = (data: any) => {
    return commonDataAccess.postFormData("product/save/weightinfo", data);
  };
  getRackProducts(productId: string) {
    return commonDataAccess.getData("product/get/rackproducts", {
      productId: productId,
    });
  }
  saveRackProducts = (payload, productId) => {
    return commonDataAccess.postData("product/save/rackproducts", payload, {
      //rackIds: selectedIds,
      productId: productId,
    });
  };
  getRackLocations(productId: string) {
    return commonDataAccess.getData("product/get/racklocations", {
      productId: productId,
    });
  }
  saveRackLocations(productId: string, selectedIds: any) {
    // console.log(productId);
    // console.log(selectedIds);
    return commonDataAccess.postData("product/save/racklocations", null, {
      productId: productId,
      rackIds: selectedIds,
    });
  }
  saveBatchCodes = (payload, productId) => {
    return commonDataAccess.postData("product/save/batchCodes", payload, {
      productId: productId,
    });
  };
  getProductAnalysis(productId: string) {
    return commonDataAccess.getData("product/get/analysis", {
      productId: productId,
    });
  }
  saveProductAnalysis(payload: any) {
    return commonDataAccess.postFormData("product/save/analysis", payload);
  }
  getProductAnalysisLabs() {
    return commonDataAccess.getData("product/get/analysislabs");
  }
  getProductInformation(productId: string) {
    return commonDataAccess.getData("product/get/information", {
      productId: productId,
    });
  }
  saveProductInformation(payload: any) {
    return commonDataAccess.postFormData("product/save/information", payload);
  }
  saveAnalysisLab(payload: any) {
    return commonDataAccess.postData("product/save/analysislab", payload);
  }

  deleteAnalysisLab(analysisId: number, labId: number) {
    return commonDataAccess.deleteData("product/delete/analysislab", {
      analysisId: analysisId,
      labId: labId,
    });
  }
  isLabAssociatedWithOtherAnalysis(analysisId: number, labId: number) {
    return commonDataAccess.getData(
      "product/get/isLabAssociatedWithOtherAnalysis",
      {
        analysisId: analysisId,
        labId: labId,
      }
    );
  }
  getProductDocumentTab = (productId: number) => {
    return commonDataAccess.getData("product/get/documenttab", {
      id: productId,
    });
  };
  saveProductDocuments = (payload: any) => {
    return commonDataAccess.postData("product/save/documents", payload);
  };
  getProductListMinified = () => {
    return commonDataAccess.getData("product/get/minified");
  };
  getSupplierProducts = (productId: any) => {
    return commonDataAccess.getData("product/get/supplierproducts", {
      productId: productId,
    });
  };
  saveSupplierProduct = (payload: any) => {
    return commonDataAccess.postData("product/save/maa", payload);
  };
  copyProduct = (productId: any) => {
    return commonDataAccess.getData("product/copy", {
      productId: productId,
    });
  };
  deleteProduct = async (params: { ids: string }) => {
    var result = await commonDataAccess.deleteData("product/delete/" + params);
    return result;
  };

  getBatchDeleteReason = () => {
    return commonDataAccess.getData(
      "BatchDeleteReasons/get/batchdeletereasons"
    );
  };

  saveBatchDeleteReason = (model: any) => {
    return commonDataAccess.postData(
      "BatchDeleteReasons/save/batchdeletereasons", model
    );
  };

  deleteBatchDeleteReason = (batchDeleteId: any) => {
    return commonDataAccess.deleteData(
      "BatchDeleteReasons/delete/batchdeletereasons", {
      id: batchDeleteId
    }
    );
  };

  getStockChangeLogsByRackBatchId = (rackbatchId: any) => {
    return commonDataAccess.getData("product/getStockChangeLogsByRackBatchId", {
      rackbatchId: rackbatchId,
    });
  };


  getProductsBySupplierType = () => {
    return commonDataAccess.getData("product/get/productsBySupplierType");
  };

  getSubCategoriesByCategoryIds = (categoryIds: string, productId: number) => {
    return commonDataAccess.getData(
      "product/get/subCategoriesByCategoryIds", {
      ids: categoryIds,
      productId: productId
    }
    );
  };
  getCategoriesForDropdown = () => {
    return commonDataAccess.getData("product/get/categoryList");
  };

  getProductNameForDropdown = () => {
    return commonDataAccess.getData("product/get/productNameForDropdown");
  };

  getProductCodeForDropdown = () => {
    return commonDataAccess.getData("product/get/productCodeForDropdown");
  };

  getProductForSalesOrder = (productId: number | bigint | any, salesOrderId: number | bigint | any, companyId: number | bigint | any) => {
    var result = commonDataAccess.getData(
      "product/get/productForSalesOrder",
      {
        productId: productId,
        salesOrderId: salesOrderId,
        companyId: companyId
      }
    );
    return result;
  }
  getProductForPurchaseOrder = (productId: number | bigint | any, companyId: number | bigint | any) => {
    var result = commonDataAccess.getData(
      "product/get/productForPurchaseOrder",
      {
        productId: productId,
        companyId: companyId
      }
    );
    return result;
  }
  getProductStockStatusById = (id: bigint | any) => {
    var result = commonDataAccess.getData("salesOrder/get/productStockStatusById", { id: id });
    return result;
  }
}

export default new ProductDataAccess();
