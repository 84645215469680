import OriginModal, { ModalFuncProps } from "antd/lib/modal";
import {
  modalGlobalConfig,
  ModalStaticFunctions,
} from "antd/lib/modal/confirm";
import useModal from "antd/lib/modal/useModal";
import GenericModal, {
  GenericModalProps,
  onOpenModal,
} from "../GenericModal/GenericModal";
import { openWithCustomWrapper, WrapperConfig } from "./CompliantModalWrapper";

type ConfigUpdate =
  | ModalFuncProps
  | ((prevConfig: ModalFuncProps) => ModalFuncProps);

type ExtendedModalFunc = (props?: Partial<GenericModalProps>) => {
  destroy: () => void;
  update: (configUpdate: ConfigUpdate) => void;
};

type ExtendedWrapperFunc = (props?: Partial<WrapperConfig>) => {
  destroy: () => void;
  update: (configUpdate: ConfigUpdate) => void;
};

type ExtendedModalStaticFunction = ModalStaticFunctions & {
  openModal: ExtendedModalFunc;
  openWithCustomWrapper: ExtendedWrapperFunc;
};

type ModalType = typeof OriginModal &
  ExtendedModalStaticFunction & {
    useModal: typeof useModal;
    destroyAll: () => void;
    config: typeof modalGlobalConfig;
  };

export const Modal = OriginModal as ModalType;

Modal.useModal = useModal;

Modal.openModal = function openModalFn(props: GenericModalProps) {
  return GenericModal(onOpenModal(props));
};

Modal.openWithCustomWrapper = function openWithWrapperFn(
  props: Partial<Omit<WrapperConfig, "component" | "componentNonNode">> = {}
) {
  return openWithCustomWrapper(props);
};

Modal.config = modalGlobalConfig;

export default Modal;
