import { ActionType, companyActionType } from "./companyAction";

export interface ICompanyState {
    companies: any[];
    companyBasicInfo: any;
    filters: any[];
    dataCount: number;
    isLoading: boolean;
    currentTabName: any;
    companyName: any;
}

const initialState: ICompanyState = {
    companyBasicInfo: {},
    companies: [],
    filters: [],
    dataCount: 0,
    isLoading: false,
    currentTabName: "",
    companyName: ""
};

export const CompanyReducer = (
    state: ICompanyState = initialState,
    action: ActionType
) => {
    switch (action.type) {
        case companyActionType.GET_COMPANY_LIST_SUCCESS:
            state = {
                ...state,
                companies: action.payload?.data,
                dataCount: action.payload?.dataCount,
            };
            return state;
        case companyActionType.GET_COMPANY_BASIC_INFO:
            state = {
                ...state,
                companyBasicInfo: action.payload,
            };
            return state;

        case companyActionType.LOAD_COMPANY:
            state = {
                ...state,
                isLoading: action.payload,
            };
            return state
        case companyActionType.UPDATE_TAB_NAME:
            state = {
                ...state,
                currentTabName: action.payload,
            };
            return state;
        case companyActionType.GET_COMPANY_NAME:
            state = {
                ...state,
                companyName: action.payload,
            };
            return state;
        default:
            return state;
    }
};
